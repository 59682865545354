import { Spinner } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { format } from "date-fns";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { D1Modal } from "@/components/D1Modal";
import { ButtonItem, Item, ItemGroup } from "@/components/ItemGroup";
import { PremiumBadge } from "@/components/PremiumUpgrade/icons/PremiumBadge";
import {
  SubscriptionInfo,
  SubscriptionSource,
} from "@/data/repositories/V2API";

interface Props {
  onClose: () => void;
  subscriptions: SubscriptionInfo["subscriptionsDetail"];
}

const getPurchaseSource = (source: SubscriptionSource) => {
  if (source === "Google") {
    return "Play Store";
  }

  if (source === "iOS" || source === "MacOS") {
    return "Apple Store";
  }

  return "Day One Website";
};

const getRenewDate = (date: string) => {
  return format(new Date(date), "MMMM dd yyyy");
};

export const ManageSubscriptionModal = ({ onClose, subscriptions }: Props) => {
  const { __, _x } = useI18n();
  const [loading, setLoading] = useState(false);

  // We make a GET to the server with the user's auth token header in place, and the
  // server gives us a custom Stripe URL to redirect the user to. That will give them
  // premium after checking out.
  async function goToStripeUrl() {
    setLoading(true);
    const url = await d1Classes.fetchWrapper.fetchRedirectLocation(
      `/api/subscription/create_customer_portal_session`,
    );
    if (!url) {
      setLoading(false);
      Sentry.captureException(
        new Error("No URL returned from server for stripe checkout"),
      );
      alert("Something went wrong. Please try again later.");
      return;
    }
    window.location = url as unknown as string & Location;
  }

  const onCancelClick = (source: SubscriptionSource) => {
    if (source === "Stripe") {
      goToStripeUrl().catch((err) => Sentry.captureException(err));
    } else {
      window.open(
        "https://help.dayoneapp.com/en/articles/1027334-view-change-or-cancel-your-premium-subscription",
        "_blank",
        "noreferrer,noopener",
      );
    }
  };

  const getSubscriptionContents = () => {
    // to handle premium granted from admin tool or other unknown edge cases
    if (!subscriptions.length) {
      return <p>{__("You do not have any subscriptions.")}</p>;
    }

    return subscriptions.map((subscription) => {
      const isMonthly = subscription.subscription_period === "monthly";
      const isExpiring = !subscription.auto_renew;
      const isStripe = subscription.source === "Stripe";

      return (
        <>
          <ItemGroup
            key={`${subscription.source}-${subscription.auto_renew}-${subscription.current_period_end_date}`}
            sx={{ mb: 3 }}
          >
            <Item sx={{ justifyContent: "flex-start", gap: 2, fontSize: 2 }}>
              <PremiumBadge />
              {__("Day One Premium")}
            </Item>
            {subscription.current_period_end_date && (
              <Item>
                {sprintf(
                  _x(
                    "%1$s %2$s",
                    "The renews state and end date of the current subscription",
                  ),
                  isExpiring ? "Expires" : "Renews",
                  getRenewDate(subscription.current_period_end_date),
                )}
              </Item>
            )}
            <Item>
              {sprintf(
                _x(
                  "$%1$f %2$s per %3$s",
                  "The price, currency and period of the subscription",
                ),
                subscription.price,
                subscription.currency,
                isMonthly ? "month" : "year",
              )}
            </Item>
            <Item>
              {sprintf(
                _x(
                  "Purchased from %s",
                  "The purchase source of the subscription",
                ),
                getPurchaseSource(subscription.source),
              )}
            </Item>
            {loading && isStripe && (
              <Item
                sx={{
                  justifyContent: "flex-start",
                }}
              >
                <Spinner
                  sx={{
                    maxWidth: "12px",
                    margin: 0,
                    marginRight: "6px",
                  }}
                ></Spinner>
                {__("Loading")}
              </Item>
            )}
            {(!loading || (loading && !isStripe)) && (
              <ButtonItem
                isDestructive
                sx={{
                  "&& button": {
                    lineHeight: 4,
                    fontWeight: "medium",
                    px: 2.5,
                    color: "primaryButtonText",
                    "&:hover": {
                      color: "primaryButtonText",
                    },
                  },
                }}
                onClick={() => onCancelClick(subscription.source)}
                disabled={loading && !isStripe}
              >
                {!isExpiring && !isStripe
                  ? __("Cancel Subscription")
                  : isExpiring
                    ? __("Restart Subscription")
                    : __("Manage Subscription")}
              </ButtonItem>
            )}
          </ItemGroup>
          {!isExpiring && subscription.current_period_end_date && (
            <p
              sx={{
                color: "textTertiary",
                fontSize: 0,
                mb: 3,
                paddingLeft: 3,
              }}
            >
              {sprintf(
                _x(
                  "If you cancel now, you can still access your subscription until %s.",
                  "Fine print to explain user could continue to have access to their subscription until the expiration date",
                ),
                getRenewDate(subscription.current_period_end_date),
              )}
            </p>
          )}
        </>
      );
    });
  };

  return (
    <D1Modal
      title={__("Manage Subscription")}
      onRequestClose={onClose}
      sx={{ maxWidth: "500px", fontWeight: "medium", fontSize: 1 }}
    >
      {getSubscriptionContents()}
    </D1Modal>
  );
};
