import {
  store as blockEditorStore,
  useBlockProps,
  useInnerBlocksProps,
} from "@wordpress/block-editor";
import {
  BlockInstance,
  registerBlockType,
  unregisterBlockType,
} from "@wordpress/blocks";
import { useDispatch, useSelect } from "@wordpress/data";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  GALLERY_BLOCK_ID,
  GalleryBlockEditProps,
  IMAGE_BLOCK_ID,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { FullScreenLoadingMessage } from "@/components/FullScreenLoadingMessage";
import { useEditorPanelContext } from "@/data/hooks/EditorPanelProvider";
import { useGalleryMedia } from "@/hooks/useGalleryMedia";
import { dayOneBlue } from "@/styles/theme";
import { getGalleryMoments, getMediaWidth } from "@/utils/gallery";

export const ALLOWED_BLOCKS_FOR_GALLERY = [IMAGE_BLOCK_ID, VIDEO_BLOCK_ID];

const GalleryBlock: React.FC<GalleryBlockEditProps> = observer(
  ({ clientId, isSelected }) => {
    const blockProps = useBlockProps();
    const innerBlocksProps = useInnerBlocksProps(blockProps, {
      allowedBlocks: ALLOWED_BLOCKS_FOR_GALLERY,
    });
    const blocks: BlockInstance[] = useSelect(
      // @ts-ignore Types are not correct
      (select) => select(blockEditorStore).getBlocks(clientId),
      [clientId],
    );
    const [galleryBlocks, setGalleryBlocks] = useState<BlockInstance[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { editorWidth } = useEditorPanelContext();
    const { calculateMediaSizes } = useGalleryMedia();
    const { removeBlock } = useDispatch("core/block-editor");

    useEffect(() => {
      // Calculate the media sizes for the gallery
      const getResizedBlocks = async () => {
        const galleryMoments = await getGalleryMoments(blocks);
        calculateMediaSizes(galleryMoments);
      };

      if (blocks.length === 0) {
        removeBlock(clientId);
      }

      // This should run on the first render and when the number of blocks changes.
      // This is mainly handle media being removed manually or added/removed via undo/redo.
      if (!isLoaded || galleryBlocks.length !== blocks.length) {
        getResizedBlocks().then(() => {
          setIsLoaded(true);
        });
        setGalleryBlocks(blocks);
      }
    }, [blocks]);

    useEffect(() => {
      const galleryBlock = document.getElementById(`block-${clientId}`);
      getGalleryMoments(blocks, editorWidth).then((rows) => {
        rows.map(({ aspectRatio, clientId, height }) => {
          const width = getMediaWidth(aspectRatio, height);
          if (!galleryBlock) {
            return;
          }
          const mediaBlock = galleryBlock.querySelector(
            `[data-momentid="${clientId}"]`,
          ) as HTMLElement;
          if (mediaBlock) {
            mediaBlock.style.width = `${width}px`;
            mediaBlock.style.height = `${height}px`;
            const child = mediaBlock.childNodes[0] as HTMLElement;
            child.style.width = `${width}px`;
            child.style.height = `${height}px`;
          }
        });
      });
    }, [editorWidth]);

    if (!isLoaded) {
      return <FullScreenLoadingMessage />;
    }
    return (
      <div
        {...innerBlocksProps}
        sx={{
          "&&": {
            border: "1px solid",
            borderColor: isSelected ? dayOneBlue : "transparent",
            display: "flex",
            position: "relative",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1px",
          },
        }}
      />
    );
  },
);

export const register = () => {
  if (!blockIsRegistered(GALLERY_BLOCK_ID)) {
    registerBlockType(GALLERY_BLOCK_ID, {
      edit: GalleryBlock,
      title: "Media Gallery",
      category: "media",
      textdomain: "default",
      description: "Day One Media Gallery Block",
      apiVersion: 2,
      attributes: {
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
      },
      supports: {
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(GALLERY_BLOCK_ID)) {
    return unregisterBlockType(GALLERY_BLOCK_ID);
  }
  return false;
};
