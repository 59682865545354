import JSZip from "jszip";
import { MutableRefObject } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { markdownToRTJ } from "@/components/Editor/gb2rtj/md2gb";
import { handlePdf } from "@/components/Editor/hooks/mediaUpload";
import { md5 } from "@/crypto/utils/md5";
import { EntryDBRow, Weather } from "@/data/db/migrations/entry";
import { ImportDetails } from "@/data/db/migrations/import_export";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { ClientMeta } from "@/data/repositories/V2API";
import { getClientMeta } from "@/data/utils/clientMeta";
import { calculateFeatureFlags } from "@/data/utils/entryFeatureFlags";
import { Audios, EntryExportJSON, PDFs, Photos, Videos } from "@/utils/export";
import { decodeRichTextJson } from "@/utils/rtj";
import { mkEntryID } from "@/utils/uuid";

export type ImportJSONErrorType =
  | "INVALID_ZIP_FILE"
  | "JSON_FILE_NOT_FOUND"
  | "INVALID_JSON"
  | "JOURNAL_SAVE_FAILED"
  | "ENTRIES_FAILED";

export type ImportJSONError = {
  type: ImportJSONErrorType;
  details?: string[];
};

export type ImportJSONResult = {
  status: "success" | "error" | "warning" | "cancelled";
  error?: ImportJSONError;
  journalId?: string;
};

export type ImportMedia = {
  photos?: Photos[];
  videos?: Videos[];
  audios?: Audios[];
  pdfs?: PDFs[];
};

type EntryResult = {
  uuid: string;
  result: "success" | "error";
  id: string | null;
};

const cancelExport = (importID: string) => {
  d1Classes.importExportRepository.deleteImport(importID);
  const cancelledStatus: ImportJSONResult = { status: "cancelled" };
  return cancelledStatus;
};

export const importJSONZip = async (
  file: File,
  canEncrypt: boolean,
  existingJournals: JournalDBRow[],
  shouldCancelExport: MutableRefObject<boolean>,
): Promise<ImportJSONResult> => {
  const zip = new JSZip();
  let zipFile: JSZip | null = null;
  try {
    zipFile = await zip.loadAsync(file);
  } catch (e: any) {
    return {
      status: "error",
      error: {
        type: "INVALID_ZIP_FILE",
        details: [e.message || e.toString()],
      },
    };
  }

  const files = Object.keys(zipFile.files).map((key) => zipFile.files[key]);
  const jsonFile = files.find((file) => file.name.endsWith(".json"));
  if (!jsonFile) {
    return {
      status: "error",
      error: {
        type: "JSON_FILE_NOT_FOUND",
      },
    };
  }
  const contents = await jsonFile.async("text");
  let jsonContent = null;
  try {
    jsonContent = JSON.parse(contents);
  } catch (e: any) {
    Sentry.captureException(e);
    return {
      status: "error",
      error: {
        type: "INVALID_JSON",
        details: [e.message || e.toString()],
      },
    };
  }

  const journalName = getJournalName(jsonFile.name, existingJournals);
  const journal = {
    ...d1Classes.journalStore.blankJournal,
    name: journalName,
    e2e: canEncrypt ? 1 : 0,
    is_shared: false,
    comments_disabled: 1,
  };
  const journalAfterSave = await d1Classes.journalStore.saveJournal(journal);
  if (!journalAfterSave) {
    return {
      status: "error",
      error: {
        type: "JOURNAL_SAVE_FAILED",
      },
    };
  }
  analytics.tracks.recordEvent(EVENT.journalCreate, {
    shared_journal: journalAfterSave.is_shared,
    conceal: !!journalAfterSave.conceal,
    hide_on_this_day: !!journalAfterSave.hide_on_this_day,
    hide_today_view: !!journalAfterSave.hide_today_view,
    hide_streaks: !!journalAfterSave.hide_streaks,
    add_location_to_entries: journalAfterSave.add_location_to_new_entries,
  });

  const exportEntries = jsonContent.entries as EntryExportJSON[];
  const user = await d1Classes.userStore.getActiveUser();
  const defaultClientMeta = await getClientMeta();

  const importID = file.name.replace(".zip", "");
  if (shouldCancelExport.current) {
    return cancelExport(importID);
  }

  const filesCount = files.reduce((acc, file) => {
    if (file.dir) {
      return acc;
    }
    if (
      file.name.startsWith("photos/") ||
      file.name.startsWith("videos/") ||
      file.name.startsWith("audios/") ||
      file.name.startsWith("pdfs/")
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const importDetails: ImportDetails = {
    format: "json",
    totalMedia: filesCount,
    totalEntries: exportEntries.length,
    mediaProcessed: 0,
    entriesProcessed: 0,
  };

  await d1Classes.importExportRepository.setImport(importID, importDetails);

  let processedCount = 0;
  for (const f of files) {
    if (shouldCancelExport.current) {
      return cancelExport(importID);
    }
    if (f.dir) {
      continue;
    }
    if (
      f.name.startsWith("photos/") ||
      f.name.startsWith("videos/") ||
      f.name.startsWith("audios/") ||
      f.name.startsWith("pdfs/")
    ) {
      processedCount++;
      const data = await f.async("uint8array");
      const dataMD5 = await md5(data);
      await d1Classes.momentRepository.saveMedia(dataMD5, data);

      const existing =
        await d1Classes.importExportRepository.getImport(importID);
      const details = existing?.details || importDetails;
      details.mediaProcessed = processedCount;
      await d1Classes.importExportRepository.setImport(importID, details);
    }
  }

  const entryResults: EntryResult[] = [];

  for (const entry of exportEntries) {
    if (shouldCancelExport.current) {
      return cancelExport(importID);
    }
    const entryId = mkEntryID(entry.uuid);
    const rtj = decodeRichTextJson(entry.richText);
    const contents =
      rtj.contents.length > 0
        ? rtj.contents
        : markdownToRTJ(entry.text, journalAfterSave.id, entryId);

    const clientMeta: ClientMeta = {
      ...defaultClientMeta,
      creationDevice: entry.creationDevice,
      creationOSName: entry.creationOSName,
      creationOSVersion: entry.creationOSVersion,
      creationDeviceModel: entry.creationDeviceModel,
      creationDeviceType: entry.creationDeviceType,
    };

    const weather: Weather | null = entry.weather
      ? {
          description: entry.weather.conditionsDescription,
          code: entry.weather.weatherCode,
          tempCelsius: entry.weather.temperatureCelsius,
          moonPhase: entry.weather.moonPhase,
          moonPhaseCode: entry.weather.moonPhaseCode,
          service: entry.weather.weatherServiceName,
          windBearing: entry.weather.windBearing,
          sunriseDate: entry.weather.sunriseDate
            ? new Date(entry.weather.sunriseDate).getTime()
            : undefined,
          pressureMb: entry.weather.pressureMB,
          visibilityKm: entry.weather.visibilityKM,
          relativeHumidity: entry.weather.relativeHumidity,
          windSpeedKph: entry.weather.windSpeedKPH,
          sunsetDate: entry.weather.sunsetDate
            ? new Date(entry.weather.sunsetDate).getTime()
            : undefined,
        }
      : null;

    const entryDBRow: EntryDBRow = {
      id: entryId,
      journal_id: journalAfterSave.id,
      owner_user_id: null,
      creator_user_id: user?.id ?? null,
      editor_user_id: null,
      body: entry.text,
      activity: entry.userActivity?.activityName || "",
      steps: entry.userActivity?.stepCount
        ? {
            stepCount: entry.userActivity.stepCount || 0,
            ignore: !!entry.userActivity?.ignoreStepCount,
          }
        : undefined,
      client_meta: JSON.stringify(clientMeta),
      date: new Date(entry.creationDate).getTime(),
      duration: entry.duration,
      edit_date: new Date(entry.modifiedDate).getTime(),
      editing_time: entry.editingTime,
      feature_flags: "10",
      is_all_day: entry.isAllDay ? 1 : 0,
      is_deleted: 0,
      is_pinned: entry.isPinned ? 1 : 0,
      is_starred: entry.starred ? 1 : 0,
      location: entry.location || null,
      timezone: entry.timeZone,
      user_edit_date: new Date(entry.modifiedDate).getTime(),
      last_editing_device_name: clientMeta.deviceName,
      last_editing_device_id: clientMeta.deviceId,
      rich_text_json: entry.richText,
      templateID: entry.template?.uuid ?? null,
      promptID: entry.promptID ?? null,
      weather: weather,
      reactions: [],
      unread_marker_id: null,
      is_shared: journalAfterSave.is_shared ? 1 : 0,
      hide_all_entries: journalAfterSave.hide_all_entries ? 1 : 0,
      music: entry.music,
    };

    const media: ImportMedia = {
      photos: entry.photos,
      videos: entry.videos,
      audios: entry.audios,
      pdfs: entry.pdfAttachments,
    };

    /* TODO: Update media feature flag checks once handling media */
    const featureFlags = calculateFeatureFlags(
      entryDBRow,
      {
        nonJpeg: false,
        audio: false,
        video: false,
        pdf: false,
        sketch: false,
        multipleAttachments: false,
      },
      rtj,
    );
    entryDBRow.feature_flags = featureFlags;
    // Generating the thumbnail for PDFs requires some special handling
    // Code for it can't be imported into repositories as it can't be
    // run in the worker. So we import it here and pass the function in
    const newEntry = await d1Classes.entryStore.importEntry(
      entryDBRow,
      contents,
      entry.tags || [],
      media,
      handlePdf,
    );
    const existing = await d1Classes.importExportRepository.getImport(importID);
    const details = existing?.details || importDetails;
    details.entriesProcessed = details.entriesProcessed + 1;
    await d1Classes.importExportRepository.setImport(importID, details);
    entryResults.push({
      uuid: entry.uuid,
      result: newEntry ? "success" : "error",
      id: newEntry?.id ?? null,
    });
  }

  const failedEntries = entryResults.filter(
    (entry) => entry.result === "error",
  );
  d1Classes.importExportRepository.deleteImport(importID);
  if (failedEntries.length > 0) {
    return {
      status: "warning",
      error: {
        type: "ENTRIES_FAILED",
        details: failedEntries.map((entry) => entry.uuid),
      },
    };
  }
  return {
    status: "success",
    journalId: journalAfterSave.id,
  };
};

const getJournalName = (
  journalName: string,
  existingJournals: JournalDBRow[],
) => {
  let newJournalName = journalName.replace(".json", "");

  // Check if the name ends with a number and extract it
  const match = newJournalName.match(/^(.+?)\s*(\d+)$/);
  let baseName = newJournalName;
  let i = 2;

  if (match) {
    baseName = match[1].trim();
    i = parseInt(match[2], 10) + 1;
  }

  while (existingJournals.some((journal) => journal.name === newJournalName)) {
    newJournalName = `${baseName} ${i}`;
    i++;
  }
  return newJournalName;
};
