import { makeAutoObservable } from "mobx";

import { d1Classes } from "@/D1Classes";
import { JournalDBRow, PendingApproval } from "@/data/db/migrations/journal";
import { PendingApprovalViewState } from "@/view_state/PendingApprovalViewState";

export type ApprovalStatus =
  | "NOT_STARTED"
  | "APPROVING"
  | "APPROVED"
  | "DECLINING"
  | "DECLINED"
  | "ERROR";

/**
 * This class is responsible for managing the state of a single
 * pending approval. It is used by the PendingApprovalViewState
 * to manage the state of all pending approvals.
 *
 * It is also used by the PendingApprovalView to render the UI
 */
export class ApprovalItemViewState {
  errorMessage?: string;

  constructor(
    public approval: PendingApproval,
    private pendingApprovals: PendingApprovalViewState,
    private journalStore = d1Classes.journalStore,
    private notificationStore = d1Classes.notificationStore,
    public status: ApprovalStatus = "NOT_STARTED",
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *decline() {
    this.status = "DECLINING";
    try {
      yield this.journalStore.declineJournalRequest(this.approval);
      this.status = "DECLINED";

      setTimeout(async () => {
        await this.notificationStore.sync();
        await this.journalStore.sync();
        this.pendingApprovals.remove(this);
      }, 200);
    } catch (e: any) {
      this.status = "ERROR";
      this.errorMessage = e.message;
    }
  }

  *approve(journal: JournalDBRow) {
    this.status = "APPROVING";
    try {
      yield this.journalStore.approveJournalRequest(journal, this.approval);
      this.status = "APPROVED";
      setTimeout(async () => {
        await this.notificationStore.sync();
        await this.journalStore.sync();
      }, 200);
    } catch (e: any) {
      this.status = "ERROR";
      this.errorMessage = e.message;
    }
  }

  get isPending() {
    return this.status !== "APPROVED" && this.status !== "DECLINED";
  }

  get id() {
    return (
      this.approval.user_requesting_shared_journal_access.id ||
      this.approval.user_requesting_shared_journal_access.name
    );
  }

  get actionTaken() {
    return this.status !== "NOT_STARTED" && this.status !== "ERROR";
  }
}
