import { observer } from "mobx-react-lite";

import { SuggestionMenu } from "@/components/Editor/blocks/suggestions/SuggestionMenu";
import { Icon, IconFont } from "@/components/Icon";
import { dayOneBlue } from "@/styles/theme";
import {
  activeEntryViewState,
  primaryViewState,
} from "@/view_state/ViewStates";

type Props = {
  title: string;
  body?: string;
  imageURL?: string | null;
  fallBackIconName: IconFont;
  clientId: string;
  isSelected: boolean;
};

export const SuggestionWrapper: React.FC<Props> = observer(
  ({ title, body, imageURL, clientId, isSelected, fallBackIconName }) => {
    const creatorId = activeEntryViewState.entryModel?.creatorUserId;
    const userId = primaryViewState.user?.id;
    const canRemove = !!creatorId && !!userId && creatorId === userId;

    return (
      <div
        sx={{
          border: "3px solid",
          borderColor: isSelected ? dayOneBlue : "transparent",
          bg: "surface_light3_dark3",
          color: "textPrimary",
          borderRadius: "xl",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
          fontSize: 0,
          p: 2,
          overflow: "hidden",
          "& p": {
            lineHeight: 2,
          },
        }}
      >
        <div sx={{ display: "flex", alignItems: "center" }}>
          {imageURL ? (
            <img
              src={imageURL}
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "xl",
                mr: 3,
              }}
            />
          ) : (
            <div
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "xl",
                mr: 3,
                border: "1px solid",
                borderColor: "borderPrimary",
                backgroundColor: "surface_light1_dark1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 0,
              }}
            >
              <Icon
                icon={fallBackIconName}
                sx={{ height: "40px" }}
                iconStyles={{
                  fontSize: "40px",
                }}
              />
            </div>
          )}
          <div>
            <p
              sx={{
                fontWeight: "bold",
                fontSize: 2,
              }}
            >
              {title}
            </p>
            {body && <p sx={{ fontSize: 1 }}>{body}</p>}
          </div>
        </div>
        {canRemove && <SuggestionMenu clientId={clientId} />}
      </div>
    );
  },
);
