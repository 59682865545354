import { useI18n } from "@wordpress/react-i18n";
import React from "react";

import { ItemButton, ItemGroup } from "@/components/ItemGroup";
import { modalRouterViewState } from "@/view_state/ViewStates";

type Props = {
  journalId: string;
};

export const ExportButton: React.FC<Props> = ({ journalId }: Props) => {
  const { __ } = useI18n();

  return (
    <ItemGroup addMargin={true} key="export">
      <ItemButton
        sx={{
          minHeight: "48px",
        }}
        onClick={() => {
          modalRouterViewState.showExport(journalId);
        }}
      >
        {__("Export Journal")}
      </ItemButton>
    </ItemGroup>
  );
};
