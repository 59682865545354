export const PremiumBadge: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "& rect": {
          stroke: "surface_light2_dark2",
        },
      }}
    >
      <rect
        x="1.16667"
        y="1.16667"
        width="21.6667"
        height="21.6667"
        rx="10.8333"
        fill="#CCCCFC"
        strokeWidth="1.66667"
      />
      <path
        d="M11.3571 6.49292C11.6 5.92875 12.4 5.92875 12.6429 6.49292L13.7283 9.01321C13.8296 9.24853 14.0514 9.40969 14.3065 9.43335L17.0389 9.68677C17.6505 9.74349 17.8977 10.5043 17.4362 10.9097L15.3747 12.7207C15.1822 12.8898 15.0975 13.1506 15.1538 13.4005L15.7571 16.0774C15.8922 16.6767 15.245 17.1468 14.7168 16.8332L12.3574 15.4322C12.1371 15.3014 11.8629 15.3014 11.6426 15.4322L9.28315 16.8332C8.75498 17.1468 8.10783 16.6767 8.24289 16.0774L8.84621 13.4005C8.90254 13.1506 8.81782 12.8898 8.62533 12.7207L6.56378 10.9097C6.1023 10.5043 6.34949 9.74349 6.96113 9.68677L9.69346 9.43335C9.94858 9.40969 10.1704 9.24853 10.2717 9.01321L11.3571 6.49292Z"
        fill="#4C4C9E"
      />
    </svg>
  );
};
