import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { fromBase64 } from "@/crypto/utils";
import { verifyHmac } from "@/crypto/utils/verifyHmac";
import { JournalPresetController } from "@/data/controllers/JournalPresetController";
import { JournalDBRow, PendingApproval } from "@/data/db/migrations/journal";
import { dropEncryptedJournals } from "@/data/models/JournalFns";
import { Outbox } from "@/data/models/Outbox";
import { UserModel } from "@/data/models/UserModel";
import { EntryRepository } from "@/data/repositories/EntryRepository";
import { JournalCoverRepository } from "@/data/repositories/JournalCoverRepository";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { JournalStatsRepository } from "@/data/repositories/JournalStatsRepository";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import { ReactionRepository } from "@/data/repositories/ReactionRepository";
import { SharedJournalRepository } from "@/data/repositories/SharedJournalsRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { TagRepository } from "@/data/repositories/TagRepository";
import { TemplateStore } from "@/data/stores/TemplateStore";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { UserStore } from "@/data/stores/UserStore";
import { InviteInfo } from "@/pages/acceptJournalInvite";
import { i18n } from "@/utils/i18n";

const CANNOT_ADD_ENTRY_REASONS = [
  "PICK_A_JOURNAL",
  "NO_DECRYPTED_JOURNALS",
  "ZERO_JOURNALS",
  "CAN_CREATE",
  "CANNOT_CREATE",
] as const;

type ValidNoEntryReasons = (typeof CANNOT_ADD_ENTRY_REASONS)[number];

export class JournalStore {
  constructor(
    private journalRepository: JournalRepository,
    private journalStatsRepository: JournalStatsRepository,
    private journalCoverRepository: JournalCoverRepository,
    private sharedJournalRepository: SharedJournalRepository,
    private entryRepository: EntryRepository,
    private momentRepository: MomentRepository,
    private userStore: UserStore,
    private userKeysStore: UserKeysStore,
    private templateStore: TemplateStore,
    private journalPresetController: JournalPresetController,
    private syncStateRepository: SyncStateRepository,
    private outbox: Outbox,
    private tagRepository: TagRepository,
    private reactionRepository: ReactionRepository,
  ) {}

  async resetJournalPullState() {
    // This is so that after the user enters their key we re-sync all journals and decrypt.
    // TODO: It'd be better to just store encrypted journals and decrypt them after the user
    // enters their key.
    // Whatever calls this function should trigger a new sync immediately after.
    await this.syncStateRepository.setJournalCursor(0);
  }

  async sync() {
    const user = await this.userStore.getActiveUser();
    if (!user) {
      return;
    }

    const userPrivateKeys = await this.userKeysStore.getAllUserPrivateKeys();

    const { deletedJournals, isNewUser, hasPresets } =
      await this.journalRepository.synchronize(user, userPrivateKeys);
    this.templateStore.sync();

    if (deletedJournals.length > 0) {
      const journalIds = deletedJournals.map((j) => j.id);
      await this.removeLocalJournalsAndRelatedState(journalIds);
    }

    if (isNewUser) {
      const newJournal = this.blankJournal;
      newJournal.name = i18n.__("Journal");
      newJournal.owner_id = user.id;
      const journal = await this.saveJournal(newJournal);
      if (journal) {
        this.syncStateRepository.enableSyncForJournal(journal.id);
      }
    }
    const sharedJournals = await this.getSharedJournals();
    if (sharedJournals.length > 0) {
      await this.checkForPendingOwnershipTransfers(sharedJournals, user);
    }
    if (hasPresets) {
      await this.journalPresetController.sync();
    }
  }

  async getSharedJournals() {
    const journals = await this.journals;
    return journals.filter((journal) => journal.is_shared);
  }

  async checkForPendingOwnershipTransfers(
    journals: JournalDBRow[],
    user: UserModel,
  ) {
    await this.sharedJournalRepository.acceptOwnershipTransfers(journals, user);
  }

  async participantIsPremium(journal: JournalDBRow, participantId: string) {
    return await this.sharedJournalRepository.participantIsPremium(
      journal,
      participantId,
    );
  }

  async verifyJournalJoinRequest(invite: PendingApproval) {
    const userPrivateKey = await this.userKeysStore.getMainUserPrivateKey();
    if (!userPrivateKey) {
      return false;
    }
    const encrypted_invite_key = fromBase64(invite.encrypted_invitation_key);
    const decrypted_invite_key = await Asymmetric.Private.decrypt(
      userPrivateKey,
      encrypted_invite_key.buffer,
      "decrypting invitation key before verifying acceptance",
    );

    const isVerified = await verifyHmac(
      decrypted_invite_key,
      invite.user_requesting_shared_journal_access.public_key_hmac,
      invite.user_requesting_shared_journal_access.public_key,
    );

    return isVerified;
  }

  async approveJournalRequest(journal: JournalDBRow, invite: PendingApproval) {
    const isVerified = await this.verifyJournalJoinRequest(invite);
    if (!isVerified) {
      throw new Error("Invalid invite link");
    }
    await this.sharedJournalRepository.approveRequest(invite, journal);
  }

  async declineJournalRequest(invite: PendingApproval) {
    await this.sharedJournalRepository.declineRequest({
      userId: invite.user_requesting_shared_journal_access.id,
      journalId: invite.journal_id,
    });
  }

  async saveJournal(journal: JournalDBRow) {
    const user = await this.userStore.getActiveUser();
    const userPrivateKey = await this.userKeysStore.getMainUserPrivateKey();
    const userPublicKey = await this.userKeysStore.getMainUserPublicKey();

    if (journal.id) {
      return await this.updateJournal(journal);
    } else {
      if (!user) throw new Error("No active user to create a journal for");

      const newJournal = await this.journalRepository.newJournal(
        journal,
        user,
        userPublicKey,
        userPrivateKey,
      );

      this.addNewJournalIdToOrder(newJournal.id);
      await this.sync();
      return newJournal;
    }
  }

  subscribeToJournal(
    journalId: string,
    callback: (journal: JournalDBRow | undefined) => void,
  ) {
    return this.journalRepository.subscribeToJournal(journalId, callback);
  }

  subscribeToAll(callback: (journals: JournalDBRow[]) => void) {
    return this.journalRepository.subscribeToAll(callback);
  }

  async validateJournalOrder(journalOrder: string[]) {
    const activeJournalIds = (await this.journalRepository.readAll()).map(
      (j) => j.id,
    );
    return journalOrder.filter((id) => activeJournalIds.includes(id));
  }

  async addNewJournalIdToOrder(journalId: string) {
    const journalOrder = await this.userStore.getJournalOrder();
    journalOrder.push(journalId);
    this.updateJournalOrder(journalOrder);
  }

  async updateJournalOrder(journalOrder: string[]) {
    const validatedJournalOrder = await this.validateJournalOrder(journalOrder);

    if (validatedJournalOrder.length > 0) {
      const [personalJournals, sharedJournals] =
        this.separateJournalsBySharedAndPersonal(await this.journals);
      const [orderedPersonal, orderedShared] = validatedJournalOrder.reduce(
        ([personal, shared], j) => {
          if (sharedJournals.find((s) => s.id === j)) {
            return [personal, [...shared, j]];
          } else if (personalJournals.find((p) => p.id === j)) {
            return [[...personal, j], shared];
          } else {
            return [personal, shared];
          }
        },
        [[], []] as [string[], string[]],
      );
      this.userStore.updateJournalOrder(orderedPersonal, orderedShared);
    }
  }

  async updateUnifiedJournalOrder(journalOrder: string[]) {
    const validatedJournalOrder = await this.validateJournalOrder(journalOrder);
    this.userStore.updateUnifiedJournalOrder(validatedJournalOrder);
  }

  async updateCommentsDisabled(journalId: string, disabled: number) {
    const result = await this.journalRepository.updateCommentsDisabled(
      journalId,
      disabled,
    );
    return result;
  }

  async deleteJournal(journal: JournalDBRow) {
    if (!journal) {
      return null;
    }

    const result = await this.journalRepository.deleteJournal(journal.id);
    if (result) {
      await this.removeStateRelatedToJournals([journal.id]);
    }
    return result;
  }

  async updateJournal(journal: JournalDBRow) {
    return this.journalRepository.updateJournal(journal);
  }

  async isJournalE2EE(journalId: string) {
    return this.journalRepository.isJournalE2EE(journalId);
  }

  async isSharedJournal(journalId: string) {
    return this.journalRepository.isSharedJournal(journalId);
  }

  get decryptedJournals() {
    return this.journalRepository.getActiveJournals().then((journals) => {
      return dropEncryptedJournals(journals);
    });
  }

  get journals() {
    return this.journalRepository.getActiveJournals();
  }

  public getJournalById(id: string) {
    return this.journalRepository.getById(id);
  }

  public async getHiddenWithIndex() {
    const hiddenJournalsWithIndex: { index: number; journalId: string }[] = [];
    const encrypted = (await this.journals).filter(
      (journal) => !journal.is_decrypted,
    );
    const journalOrder = await this.userStore.getJournalOrder();

    journalOrder.forEach((journalId, index) => {
      const journal = encrypted.find((journal) => journal.id === journalId);
      if (journal) {
        hiddenJournalsWithIndex.push({ index, journalId });
      }
    });
    return hiddenJournalsWithIndex;
  }

  get encryptedJournalCount() {
    return this.journals.then((journals) => {
      return journals.filter((j) => !j.is_decrypted).length;
    });
  }

  get lockedJournalLabel() {
    return this.encryptedJournalCount.then((count) => {
      return count === 1
        ? `${count} Locked Journal`
        : `${count} Locked Journals`;
    });
  }

  async getAllStats() {
    const allStats = await this.journalStatsRepository.getAllStats();
    return allStats.reduce(
      (acc, value) => {
        acc.count += value.count;
        acc.photos += value.photos;
        acc.videos += value.videos;
        acc.audio += value.audio;
        return acc;
      },
      {
        count: 0,
        photos: 0,
        videos: 0,
        audio: 0,
      },
    );
  }

  async getStatsByID(id: string) {
    return await this.journalStatsRepository.getStatsByJounalId(id);
  }

  get blankJournal(): JournalDBRow {
    return { ...blankJournalObject };
  }

  async generateSharedJournalLink(journal: JournalDBRow) {
    const link = await this.sharedJournalRepository.generateInviteLink(journal);
    this.sync();
    return link;
  }
  async joinSharedJournal(params: {
    inviteCode: string;
    inviteEncryptionKey: string;
    ownerPublicKeyPEM: string;
    inviteInfo: InviteInfo;
  }) {
    const { error } = await this.sharedJournalRepository.requestAccess(params);
    if (error) {
      return { error };
    } else {
      this.sync();
      return { error: null };
    }
  }

  async removeLocalJournalsAndRelatedState(journalIds: string[]) {
    if (journalIds.length > 0) {
      await Promise.all([
        this.journalRepository.removeLocalJournals(journalIds),
        this.removeStateRelatedToJournals(journalIds),
      ]);
    }
  }

  async revokeAccessToSharedJournal(journalId: string, userId: string) {
    const result = await this.sharedJournalRepository.removeFromJournal(
      journalId,
      userId,
    );
    if (result) {
      this.sync();
    }
    return result;
  }

  async cancelSharedJournalTransfer(journal: JournalDBRow) {
    const result =
      await this.sharedJournalRepository.cancelSharedJournalTransfer(journal);
    result && this.sync();

    return result;
  }

  async initiateSharedJournalTransfer(journal: JournalDBRow, userId: string) {
    const result =
      await this.sharedJournalRepository.initiateSharedJournalTransfer(
        journal,
        userId,
      );
    result && this.sync();

    return result;
  }

  async leaveSharedJournal(journal: JournalDBRow, userId: string) {
    if (!journal) {
      return null;
    }

    const result = await this.sharedJournalRepository.removeFromJournal(
      journal.id,
      userId,
    );

    if (result) {
      await this.removeLocalJournalsAndRelatedState([journal.id]);
    }
    return result;
  }

  async removeStateRelatedToJournals(journalIds: string[]) {
    if (journalIds.length > 0) {
      await Promise.all([
        this.syncStateRepository.disableSyncForJournals(journalIds),
        //Remove items from outbox before removing entries to avoid orphaned outbox items
        this.outbox.removeItemsForJournals(journalIds),
        this.entryRepository.removeLocalEntriesForJournals(journalIds),
        this.momentRepository.removeLocalMomentsForJournals(journalIds),
        this.tagRepository.removeLocalTagsForJournals(journalIds),
        this.reactionRepository.removeLocalReactionsForJournals(journalIds),
        this.journalStatsRepository.removeCountCacheForJournals(journalIds),
        this.journalCoverRepository.removeCoverForJournals(journalIds),
      ]);
    }
  }

  async makeUnsyncable(journalId: string) {
    if (await this.outbox.hasItemsForJournal(journalId)) {
      throw new Error(
        "Cannot disable journal for sync because there are items in the outbox for this journal",
      );
    }
    await this.journalRepository.makeUnsyncable(journalId);
    await this.removeStateRelatedToJournals([journalId]);
  }

  async makeSyncable(journal_id: string) {
    await this.journalRepository.makeSyncable(journal_id);
  }

  separateJournalsBySharedAndPersonal = (journals: JournalDBRow[]) => {
    const [personalJournals, sharedJournals] = journals.reduce(
      ([personal, shared], j) => {
        if (j.is_shared) {
          return [personal, [...shared, j]];
        } else {
          return [[...personal, j], shared];
        }
      },
      [[], []] as [JournalDBRow[], JournalDBRow[]],
    );

    return [personalJournals, sharedJournals];
  };

  async userCanAddEntryToJournal(
    journal?: JournalDBRow | null,
  ): Promise<ValidNoEntryReasons> {
    const isShared = journal?.is_shared;
    const isReadOnly = journal?.is_read_only;

    if (isShared) {
      return isReadOnly ? "CANNOT_CREATE" : "CAN_CREATE";
    }
    const user = await this.userStore.getActiveUser();

    const journalLimit = user?.journalLimit;
    const journals = await this.journals;
    const decryptedJournals = journals.filter((j) => j.is_decrypted);
    const userIsSharedJournalParticipant = journals.some(
      (j) => j.is_shared && user?.id !== j.owner_id,
    );

    // Basic users can only create entries on their default journal or a shared journal they are a participant in
    // If they are in All Entries view and not in a shared journal, no need to show journal picker
    if (
      !journal &&
      journalLimit?.limit === 1 && // Basic user
      !userIsSharedJournalParticipant
    ) {
      return "CAN_CREATE";
    }

    const zeroJournals = journals.length === 0;

    if (!journal && zeroJournals) {
      return "ZERO_JOURNALS";
    }

    if (!journal && decryptedJournals.length === 1) {
      return "CAN_CREATE";
    }

    if (!journal && decryptedJournals.length === 0) {
      return "NO_DECRYPTED_JOURNALS";
    }

    if (!journal) {
      return "PICK_A_JOURNAL";
    }
    return "CAN_CREATE";
  }

  async getById(journalId: string) {
    return await this.journalRepository.getById(journalId);
  }

  async userCanAddEntryToJournalId(journalId: string) {
    const journal = await this.getById(journalId);
    return this.userCanAddEntryToJournal(journal);
  }

  async downloadAllJournalMedia(
    journalId: string,
    cancelRequested?: () => boolean,
    updateProgress?: (downloaded: number) => void,
  ) {
    const allMoments = await this.getAllMomentsForJournal(journalId);
    return this.momentRepository.bulkDownloadMediaForMoments(allMoments, {
      cancelRequested,
      updateProgress,
    });
  }

  private async getAllMomentsForJournal(journalId: string) {
    const entryIds =
      await this.entryRepository.getAllEntryIdsByJournal(journalId);
    const allMoments = await Promise.all(
      entryIds.map(async (entryId) => {
        return await this.momentRepository.getForEntry(
          entryId.journal_id,
          entryId.id,
        );
      }),
    );

    return allMoments.flat();
  }

  async getMediaSizeInfoByJournal(journalId: string) {
    const allMoments = await this.getAllMomentsForJournal(journalId);
    let totalMediaSize = 0;
    let downloadedMediaSize = 0;

    for (const moment of allMoments) {
      const size = moment.metadata?.fileSize || 0;
      const haveMedia = await this.momentRepository.isMediaDownloaded(
        moment.md5_body,
      );
      if (haveMedia) {
        downloadedMediaSize += size;
      }
      totalMediaSize += size;
    }

    return { totalMediaSize, downloadedMediaSize };
  }

  async journalHasMedia(journalId: string) {
    const allMoments = await this.getAllMomentsForJournal(journalId);
    return allMoments.length > 0;
  }

  async isAllJournalMediaDownloaded(journalId: string) {
    const entryIds =
      await this.entryRepository.getAllEntryIdsByJournal(journalId);
    const results = await Promise.all(
      entryIds.map(async (entryId) => {
        const result = await this.momentRepository.isAllEntryMediaDownloaded(
          entryId.journal_id,
          entryId.id,
        );
        return result;
      }),
    );
    return results.every((result) => result);
  }

  async getAllJournalMomentsAndMediaDownloadedCount(journalId: string) {
    const entryIds =
      await this.entryRepository.getAllEntryIdsByJournal(journalId);
    let mediaCount = 0;
    let missingMediaCount = 0;
    await Promise.all(
      entryIds.map(async (entryId) => {
        const count = await this.momentRepository.allEntryMediaMissing(
          entryId.journal_id,
          entryId.id,
        );
        missingMediaCount += count;

        const mediaDownloadedCounts =
          await this.momentRepository.entryMediaDownloadedCount(
            entryId.journal_id,
            entryId.id,
          );
        mediaCount += mediaDownloadedCounts;
      }),
    );
    return { missingMediaCount, mediaCount };
  }
}

export const blankJournalObject: JournalDBRow = {
  id: "",
  owner_id: "",
  created_at: 0,
  name: "",
  description: "",
  kind: "standard",
  sort_method: "entryDate",
  conceal: 0,
  hide_all_entries: 0,
  hide_on_this_day: 0,
  hide_today_view: 0,
  hide_streaks: 0,
  add_location_to_new_entries: true,
  color: "#44C0FF",
  is_decrypted: 1,
  state: "active",
  deletion_requested: 0,
  e2e: 0,
  template_id: "",
  comments_disabled: 1,

  // Add Shared Journal Default Values as found in: src/data/repositories/V6API.d.ts
  invite_list: {
    active_invites: [],
    pending_approvals: [],
  },
  participants: [],
  should_rotate_keys: false,
  is_read_only: false,
  is_being_transferred: false,
  revoked_at: null,
  is_shared: false,
  ownership_transfers: [],
  max_participants: 30,
  cover_photo: null,
  preset_id: null,
  connected_services: null,
};

// You know, for tests.
export const mockBlankJournal = {
  ...blankJournalObject,
  copy: () => {
    return { ...blankJournalObject };
  },
  reset: (thing: any) => {
    return { ...blankJournalObject, ...thing };
  },
};
