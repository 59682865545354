import {
  BlockList,
  ObserveTyping,
  // @ts-ignore
  BlockTools,
  // @ts-ignore
  Typewriter,
  WritingFlow,
} from "@wordpress/block-editor";
import { createBlock } from "@wordpress/blocks";
import { DropZone } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { PARAGRAPH_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { FileUploadMessagesModal } from "@/components/Editor/components/FileUploadMessagesModal";
import { useMediaUpload } from "@/components/Editor/hooks/mediaUpload";
import { useEditorBlocks } from "@/components/Editor/hooks/useEditorBlocks";
import {
  ValidEditorID,
  DEFAULT_EDITOR_ID,
} from "@/components/Editor/utils/editor-constants";
import {
  MediaBlocks,
  areMediaBlocksRegistered,
} from "@/components/Editor/utils/register-blocks";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { Z_INDEX_EDITOR_MEDIA_DROP_ZONE } from "@/styles/theme";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  globalEntryID?: null | GlobalEntryID;
  isEntryShared?: boolean;
  editorId?: ValidEditorID;
};

export const EditorWritingFlow: React.FC<Props> = observer(
  ({ globalEntryID, isEntryShared, editorId = DEFAULT_EDITOR_ID }) => {
    const { insertBlock, firstBlock } = useEditorBlocks();
    const allowMediaUpload = areMediaBlocksRegistered();

    const { handleDroppedFiles, filesMessage, resetFilesMessage } =
      useMediaUpload(globalEntryID ?? null, viewStates, isEntryShared);

    // Ensures that when you click on the editor you end up in a spot where you can immediately start typing
    const setSelectionOnClick = () => {
      const elements = document.querySelectorAll(
        `#${editorId} [role=document]`,
      );
      if (elements.length) {
        const last = elements[elements.length - 1] as HTMLElement;
        const lastBlockIsMedia = last.dataset.type
          ? MediaBlocks.includes(last.dataset.type)
          : false;
        if (last.textContent?.trim() === "" && !lastBlockIsMedia) {
          last.focus();
        } else {
          insertBlock(createBlock(PARAGRAPH_BLOCK_ID, {}));
        }
      } else {
        insertBlock(createBlock(PARAGRAPH_BLOCK_ID, {}));
      }
    };

    return (
      <div
        onClick={(e) => {
          const bounds = e.currentTarget.getBoundingClientRect();
          const y = e.clientY - bounds.top;
          if (y <= 60 && firstBlock && MediaBlocks.includes(firstBlock.name)) {
            insertBlock(createBlock(PARAGRAPH_BLOCK_ID, {}), 0);
          }
        }}
        id={editorId}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 6,
          "& > div": {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          },
          "& .block-editor-writing-flow": {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            "& > div": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            },
          },
        }}
      >
        <BlockTools
          className="blockTools"
          sx={{ display: "flex", flexGrow: 1, position: "relative" }}
        >
          <WritingFlow>
            <ObserveTyping>
              {allowMediaUpload && (
                <DropZone
                  sx={{ zIndex: Z_INDEX_EDITOR_MEDIA_DROP_ZONE }}
                  onFilesDrop={handleDroppedFiles}
                />
              )}
              <Typewriter>
                <BlockList renderAppender={() => <></>} />
              </Typewriter>
              <div
                id={`${editorId}-clickzone`}
                sx={{
                  cursor: "text",
                  marginBottom: "-2.5rem",
                  flexGrow: 1,
                  width: "100%",
                  minHeight: "3em",
                }}
                onClick={setSelectionOnClick}
              ></div>
            </ObserveTyping>
          </WritingFlow>
        </BlockTools>
        {filesMessage?.length > 0 && (
          <FileUploadMessagesModal
            message={filesMessage}
            onClose={resetFilesMessage}
          />
        )}
      </div>
    );
  },
);

EditorWritingFlow.displayName = "EditorWritingFlow";
