import { makeAutoObservable } from "mobx";

import {
  UserSettings,
  UserSettingsRepository,
} from "@/data/repositories/UserSettingsRepository";
import { getDefaultTemparatureUnit } from "@/utils/temperature-helper";

export class UserSettingsViewState {
  private _settings: UserSettings | null = null;

  get settings() {
    return this._settings;
  }

  constructor(private userSettingsRepo: UserSettingsRepository) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.init();
  }

  init() {
    this.userSettingsRepo.subscribe((settings) => {
      this.setSettings(settings ?? null);
    });
  }

  async saveSettings(settings: Partial<UserSettings>) {
    this.setSettings(await this.userSettingsRepo.saveSettings(settings));
  }

  async setFeatureFlag(key: string, value: boolean) {
    this.setSettings(await this.userSettingsRepo.setFeatureFlag(key, value));
  }

  async enableDeveloperMode() {
    this.setSettings(
      await this.userSettingsRepo.saveSettings({ developer_mode: true }),
    );
  }

  private setSettings(settings: UserSettings | null) {
    this._settings = settings;
  }

  get createTagsFromHashtags() {
    return this._settings?.create_tags_from_hashtags ?? true;
  }

  get temperatureUnit() {
    return this._settings?.temperature_unit ?? getDefaultTemparatureUnit();
  }

  get developerModeEnabled() {
    return this._settings?.developer_mode ?? false;
  }

  get shownSharedJournalsInfo() {
    return this._settings?.shown_shared_journals_info ?? false;
  }

  get showAppDownloadBanner() {
    // If the setting has never been saved we show the banner
    if (this._settings?.show_app_download_banner == null) {
      return true;
    }
    return this._settings.show_app_download_banner;
  }
}
