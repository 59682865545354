import {
  BlockInstance,
  createBlock,
  getBlockAttributes,
  getBlockTypes,
} from "@wordpress/blocks";

import { QuoteNode, RTJNode, ListNode } from "@/../types/rtj-format";
import { Sentry } from "@/Sentry";
import {
  AUDIO_BLOCK_ID,
  CHECKLIST_ITEM_BLOCK_ID,
  CODE_BLOCK_ID,
  GALLERY_BLOCK_ID,
  HEADING_BLOCK_ID,
  IMAGE_BLOCK_ID,
  LIST_BLOCK_ID,
  LIST_ITEM_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
  PDF_BLOCK_ID,
  PREVIEW_BLOCK_ID,
  QUOTE_BLOCK_ID,
  SEPARATOR_BLOCK_ID,
  CONTACT_BLOCK_ID,
  VIDEO_BLOCK_ID,
  LOCATION_BLOCK_ID,
  PODCAST_BLOCK_ID,
  ACTIVITY_BLOCK_ID,
  SONG_BLOCK_ID,
  WORKOUT_BLOCK_ID,
  GENERIC_MEDIA_BLOCK_ID,
  STATE_OF_MIND_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { convertNodesToQuoteBlockAttributes } from "@/components/Editor/gb2rtj/format-quote";
import { convertHeadingNodesToBlockAttributes } from "@/components/Editor/rtj2gb/format-headings";
import {
  convertChecklistNodesToBlockAttributes,
  convertNodesToListBlockAttributes,
} from "@/components/Editor/rtj2gb/format-list";
import { UNICODE_LINE_SEPARATOR } from "@/components/Editor/rtj2gb/format-node";
import {
  getIsNodeSiblingCheck,
  isCheckListNode,
  isCodeBlockNode,
  isHeaderNode,
  isListNode,
  isEmbeddedPhotoNode,
  isPlainTextNode,
  isQuoteNode,
  isEmbeddableContentNode,
  isEmbeddedHorizontalLineRuleNode,
  isEmbeddedVideoNode,
  isEmbeddedVisualMediaNode,
  isEmbeddedAudioNode,
  isEmbeddedPdfNode,
  isEmbeddedPreviewNode,
  isEmbeddedMarkdownNode,
  isEmbeddedContactNode,
  isEmbeddedLocationNode,
  isEmbeddedMotionActivityNode,
  isEmbeddedSongNode,
  isEmbeddedWorkoutNode,
  isEmbeddedPodcastNode,
  isEmbeddedGenericMediaNode,
  isEmbeddedStateOfMindNode,
} from "@/components/Editor/rtj2gb/rtj-type-checks";
import { plainTextNodesToParagraphBlocks } from "@/components/Editor/rtj2gb/text2paragraph";
import { registerBlocks } from "@/components/Editor/utils/register-blocks";
import { i18n } from "@/utils/i18n";
import { isDeepEqual } from "@/utils/is-equal";
import { takeWhile } from "@/utils/take-while";
import { viewStates } from "@/view_state/ViewStates";

type SiblingAccumulator = {
  items: RTJNode[][];
  itemsSkipped: number;
};

export type ListItemBlockAttributes = {
  ordered: boolean;
  indentLevel: number;
  block: BlockInstance;
};

// This method groups nodes into nested arrays if they are of the same node type.
export const groupSiblingNodes = (arr: RTJNode[]) => {
  const grouped = arr.reduce<SiblingAccumulator>(
    (acc, _, index, arr) => {
      const currentIndex = index + acc.itemsSkipped;
      // we won't use this when the index runs past the length but we avoid warnings by not reading over the end of the array.
      const currentVal =
        currentIndex > arr.length - 1 ? arr[arr.length - 1] : arr[currentIndex];

      if (currentIndex > arr.length - 1) {
        return acc;
      } else if (currentVal) {
        // don't group embeddable content nodes, they are essentially their own grouping of nodes
        if (!isEmbeddableContentNode(currentVal)) {
          const isSibling = getIsNodeSiblingCheck(currentVal);
          const takenSiblings = takeWhile(arr.slice(currentIndex), isSibling);

          if (takenSiblings.length > 1) {
            return {
              items: [...acc.items, takenSiblings],
              itemsSkipped: acc.itemsSkipped + (takenSiblings.length - 1),
            };
          }
        }

        return {
          items: [...acc.items, [currentVal]],
          itemsSkipped: acc.itemsSkipped,
        };
      } else {
        return {
          items: [...acc.items, [currentVal]],
          itemsSkipped: acc.itemsSkipped,
        };
      }
    },
    { items: [], itemsSkipped: 0 },
  );
  return grouped.items;
};

export const convertRTJToBlocks = (
  rtjNodes: RTJNode[],
  entryId: string,
  journalId: string,
): BlockInstance[] => {
  const nodes = removeEmptyLinesAroundCodeNodes(rtjNodes);
  const groupedNodes = groupSiblingNodes(nodes);
  return groupedNodes.flatMap<BlockInstance>(
    (nodes) => getGBBlock(nodes, entryId, journalId) || [], // Ensure no null is returned
  );
};

// The Mac App adds empty nodes before and after a code node this causes issues if editing
// back and forth between the web client and the Mac app as multiple empty lines can get added.
// This fixes it by removing the empty lines before and after code nodes
const removeEmptyLinesAroundCodeNodes = (nodes: RTJNode[]) => {
  return nodes.reduce<RTJNode[]>((acc, node, index, arr) => {
    if ("text" in node && (node.text === "\n" || node.text.endsWith("\n\n"))) {
      if (node.attributes?.line?.codeBlock) {
        return [...acc, node];
      }
      const nextNode = index < arr.length ? arr[index + 1] : null;
      if (
        nextNode &&
        "attributes" in nextNode &&
        nextNode.attributes?.line?.codeBlock
      ) {
        if (node.text.endsWith("\n\n")) {
          const modifiedNode = { ...node, text: node.text.slice(0, -1) };
          return [...acc, modifiedNode];
        }
        return [...acc];
      }
      const prevNode = index > 0 ? arr[index - 1] : null;
      if (
        prevNode &&
        "attributes" in prevNode &&
        prevNode.attributes?.line?.codeBlock
      ) {
        if (node.text.endsWith("\n\n")) {
          const modifiedNode = { ...node, text: node.text.slice(0, -1) };
          return [...acc, modifiedNode];
        }
        return [...acc];
      }
    }
    return [...acc, node];
  }, []);
};

export const createParagraphBlock = (content: string) =>
  createBlock(PARAGRAPH_BLOCK_ID, { content });

export const createQuoteBlock = (innerBlocks: BlockInstance[]) => {
  const quoteBlock = createBlock(QUOTE_BLOCK_ID, {}, innerBlocks);
  return quoteBlock;
};

export const createListItemBlock = (content: string) =>
  createBlock(LIST_ITEM_BLOCK_ID, { content });

export const createListBlock = (
  ordered: boolean,
  innerBlocks: BlockInstance[],
) => {
  const listBlock = createBlock(LIST_BLOCK_ID, { ordered }, innerBlocks);
  return listBlock;
};

const convertNodesToQuoteBlock = (nodes: QuoteNode[]): BlockInstance => {
  const quoteInnerBlocks = convertNodesToQuoteBlockAttributes(nodes);
  return createQuoteBlock(quoteInnerBlocks);
};

const convertNodesToListBlock = (
  nodes: ListNode[],
): BlockInstance | undefined => {
  const NEST = "nest";
  const UNNEST = "un-nest";
  const ADD = "add";
  const CLOSE = "close";

  // This method converts a list of rtjNodes into a list of list item blocks.
  const listItemBlocks = convertNodesToListBlockAttributes(nodes);

  // This method generates block actions from indent levels.
  //   same indent level: add, ie accumulate inner blocks
  //   higher indent level: nest, ie accumulate inner blocks into a new list block
  //   lower indent level: un-nest, ie create the list block, with the accumulated inner blocks
  function* getListItemBlockActions(listItemBlocks: ListItemBlockAttributes[]) {
    let lastIndentLevel = 0;
    for (const listItemBlock of listItemBlocks) {
      const indentLevelDelta = listItemBlock.indentLevel - lastIndentLevel;
      lastIndentLevel = listItemBlock.indentLevel;
      const blockAction =
        indentLevelDelta === 0 ? ADD : indentLevelDelta > 0 ? NEST : UNNEST;

      switch (blockAction) {
        case NEST:
        case ADD:
          yield {
            listItemBlock,
            blockAction,
          };
          break;
        case UNNEST:
          {
            const unNestCount = Math.abs(indentLevelDelta);
            yield* repeatBlockAction(unNestCount, UNNEST);
            yield { listItemBlock, blockAction: ADD };
          }
          break;
        default:
          break;
      }
    }
    const unNestCount = Math.abs(lastIndentLevel);
    yield* repeatBlockAction(unNestCount, UNNEST);

    function* repeatBlockAction(
      times: number,
      blockAction: string,
      listItemBlock: ListItemBlockAttributes | null = null,
    ) {
      for (let i = 0; i < times; i++) {
        yield {
          listItemBlock,
          blockAction,
        };
      }
    }
  }

  const blockGenerator = getListItemBlockActions(listItemBlocks);

  type BlockGenerator = typeof blockGenerator;

  // create a list block from a list of listItemBlockAttributes
  //  this method is recursive because a list block can contain another list block
  const nestBlock = (
    listItemBlockAttribute: ListItemBlockAttributes,
    generator: BlockGenerator,
    depth = 0,
  ): BlockInstance => {
    let cont = true;
    const innerBlocks = [listItemBlockAttribute.block];
    let lastBlock: BlockInstance = listItemBlockAttribute.block;
    const ordered = listItemBlockAttribute.ordered;
    while (cont) {
      const { value: blockActionAttribute } = generator.next();
      const { listItemBlock: blockAttribute, blockAction } =
        blockActionAttribute!;

      switch (blockAction) {
        case ADD:
          innerBlocks.push(blockAttribute!.block);
          break;
        case NEST:
          {
            if (lastBlock) {
              lastBlock.innerBlocks.push(
                nestBlock(blockAttribute!, generator, depth + 1),
              );
            } else {
              innerBlocks.push(
                nestBlock(blockAttribute!, generator, depth + 1),
              );
            }
          }
          break;
        case UNNEST:
        case CLOSE:
          return createListBlock(ordered, innerBlocks);
        default:
          break;
      }
      if (blockAttribute?.block) {
        lastBlock = blockAttribute.block;
      }
      cont = blockAction !== CLOSE;
    }
    // this should never happen
    return createListBlock(false, [
      createListItemBlock("Error: list block not created"),
    ]);
  };

  const { value: blockActionAttribute } = blockGenerator.next();
  const { listItemBlock: listItemBlockAttribute, blockAction } =
    blockActionAttribute!;
  if (blockAction === NEST) {
    const listBlock = nestBlock(listItemBlockAttribute!, blockGenerator);
    return listBlock;
  }
  // we should never get here
  return createListBlock(false, [
    createListItemBlock(
      `Error: Unexpected list block, first block Action was not ${NEST}`,
    ),
  ]);
};

export const getGBBlock = (
  nodes: RTJNode[],
  entryId: string,
  journalId: string,
) => {
  if (getBlockTypes().length === 0) {
    registerBlocks();
  }
  const isNewEntry = isDeepEqual([{ text: "" }], nodes) || nodes.length === 0;
  if (isNewEntry) {
    return viewStates.userSettings.settings?.auto_title_first_line
      ? [
          createBlock(HEADING_BLOCK_ID, {
            content: "",
            level: 1,
          }),
        ]
      : [createParagraphBlock("")];
  }

  if (nodes.every(isPlainTextNode)) {
    return plainTextNodesToParagraphBlocks(nodes);
  } else if (nodes.every(isListNode)) {
    return convertNodesToListBlock(nodes)!;
  } else if (nodes.every(isHeaderNode)) {
    return convertHeadingNodesToBlockAttributes(nodes).map((blockAttrs) =>
      createBlock(HEADING_BLOCK_ID, {
        level: blockAttrs.level,
        content: blockAttrs.text,
      }),
    );
  } else if (nodes.every(isCheckListNode)) {
    return convertChecklistNodesToBlockAttributes(nodes).map((blockAttrs) =>
      createBlock(CHECKLIST_ITEM_BLOCK_ID, {
        ...blockAttrs,
      }),
    );
  } else if (
    nodes.every(isEmbeddedPhotoNode) &&
    nodes[0].embeddedObjects.length === 1
  ) {
    // for single images, use a dayone/image block
    const embeddedObject = nodes[0].embeddedObjects[0];

    return createBlock(IMAGE_BLOCK_ID, {
      journalId: journalId,
      entryId: entryId,
      clientId: embeddedObject.identifier,
    });
  } else if (nodes.every(isQuoteNode)) {
    return convertNodesToQuoteBlock(nodes);
  } else if (nodes.every(isCodeBlockNode)) {
    const content = nodes
      .map((node) => node.text.replaceAll(UNICODE_LINE_SEPARATOR, "\n"))
      .join("")
      .replace(/\n$/, "");
    return createBlock(
      CODE_BLOCK_ID,
      getBlockAttributes(CODE_BLOCK_ID, `<pre><code>${content}</code></pre>`),
    );
  } else if (nodes.every(isEmbeddedHorizontalLineRuleNode)) {
    return createBlock(SEPARATOR_BLOCK_ID);
  } else if (
    nodes.every(isEmbeddedVideoNode) &&
    nodes[0].embeddedObjects.length === 1
  ) {
    // Individual video
    return createBlock(VIDEO_BLOCK_ID, {
      journalId: journalId,
      entryId: entryId,
      clientId: nodes[0].embeddedObjects[0].identifier,
    });
  } else if (nodes.every(isEmbeddedAudioNode)) {
    // Currently there doesn't appear to be any support for grouping audio in Day One,
    // so there should be just one embeddedObject.
    return createBlock(AUDIO_BLOCK_ID, {
      journalId: journalId,
      entryId: entryId,
      clientId: nodes[0].embeddedObjects[0].identifier,
    });
  } else if (nodes.every(isEmbeddedPdfNode)) {
    return createBlock(PDF_BLOCK_ID, {
      journalId: journalId,
      entryId: entryId,
      clientId: nodes[0].embeddedObjects[0].identifier,
    });
  } else if (nodes.every(isEmbeddedPreviewNode)) {
    return createBlock(PREVIEW_BLOCK_ID, {
      url: nodes[0].embeddedObjects[0].url,
    });
  } else if (nodes.every(isEmbeddedMarkdownNode)) {
    return createBlock(CODE_BLOCK_ID, {
      content: nodes[0].embeddedObjects[0].contents,
    });
  } else if (nodes.every(isEmbeddedVisualMediaNode)) {
    const innerBlocks = nodes[0].embeddedObjects.map((embeddedObject) => {
      return createBlock(
        embeddedObject.type === "photo" ? IMAGE_BLOCK_ID : VIDEO_BLOCK_ID,
        {
          journalId: journalId,
          entryId: entryId,
          clientId: embeddedObject.identifier,
        },
      );
    });
    // Galleries of Images and/or videos
    return createBlock(
      GALLERY_BLOCK_ID,
      {
        journalId,
        entryId,
      },
      innerBlocks,
    );
  } else if (nodes.every(isEmbeddedContactNode)) {
    const contactInfo = nodes[0].embeddedObjects[0];
    const block = createBlock(CONTACT_BLOCK_ID, {
      type: "contact",
      identifier: contactInfo.identifier,
      name: contactInfo.name,
      photoIdentifier: contactInfo.photoIdentifier,
      source: contactInfo.source,
      journalId: journalId,
      entryId: entryId,
    });
    return block;
  } else if (nodes.every(isEmbeddedLocationNode)) {
    const locationInfo = nodes[0].embeddedObjects[0];
    return createBlock(LOCATION_BLOCK_ID, {
      type: "location",
      identifier: locationInfo.identifier,
      city: locationInfo.city,
      placeName: locationInfo.placeName,
      latitude: locationInfo.latitude,
      longitude: locationInfo.longitude,
      date: locationInfo.date,
      source: locationInfo.source,
    });
  } else if (nodes.every(isEmbeddedPodcastNode)) {
    const podcastInfo = nodes[0].embeddedObjects[0];
    return createBlock(PODCAST_BLOCK_ID, {
      type: "podcast",
      identifier: podcastInfo.identifier,
      show: podcastInfo.show,
      episode: podcastInfo.episode,
      artworkIdentifier: podcastInfo.artworkIdentifier,
      date: podcastInfo.date,
      source: podcastInfo.source,
      journalId: journalId,
      entryId: entryId,
    });
  } else if (nodes.every(isEmbeddedMotionActivityNode)) {
    const activityInfo = nodes[0].embeddedObjects[0];
    return createBlock(ACTIVITY_BLOCK_ID, {
      type: "motionActivity",
      identifier: activityInfo.identifier,
      startDate: activityInfo.startDate,
      endDate: activityInfo.endDate,
      iconIdentifier: activityInfo.iconIdentifier,
      steps: activityInfo.steps,
      source: activityInfo.source,
      movementType: activityInfo.movementType || "",
      movementTypeName: activityInfo.movementTypeName || "",
    });
  } else if (nodes.every(isEmbeddedSongNode)) {
    const songInfo = nodes[0].embeddedObjects[0];
    return createBlock(SONG_BLOCK_ID, {
      type: "song",
      identifier: songInfo.identifier,
      song: songInfo.song,
      artist: songInfo.artist,
      album: songInfo.album,
      artworkIdentifier: songInfo.artworkIdentifier,
      date: songInfo.date,
      source: songInfo.source,
      journalId: journalId,
      entryId: entryId,
    });
  } else if (nodes.every(isEmbeddedWorkoutNode)) {
    const workoutInfo = nodes[0].embeddedObjects[0];
    return createBlock(WORKOUT_BLOCK_ID, {
      type: "workout",
      identifier: workoutInfo.identifier,
      route: workoutInfo.route,
      workoutMetrics: workoutInfo.workoutMetrics,
      activityType: workoutInfo.activityType,
      displayName: workoutInfo.displayName,
      startDate: workoutInfo.startDate,
      endDate: workoutInfo.endDate,
      distance: workoutInfo.distance,
      source: workoutInfo.source,
    });
  } else if (nodes.every(isEmbeddedGenericMediaNode)) {
    const genericMediaInfo = nodes[0].embeddedObjects[0];
    return createBlock(GENERIC_MEDIA_BLOCK_ID, {
      type: "genericMedia",
      identifier: genericMediaInfo.identifier,
      title: genericMediaInfo.title,
      artist: genericMediaInfo.artist,
      album: genericMediaInfo.album,
      iconIdentifier: genericMediaInfo.iconIdentifier,
      date: genericMediaInfo.date,
      source: genericMediaInfo.source,
      journalId: journalId,
      entryId: entryId,
    });
  } else if (nodes.every(isEmbeddedStateOfMindNode)) {
    const stateOfMindInfo = nodes[0].embeddedObjects[0];
    return createBlock(STATE_OF_MIND_BLOCK_ID, {
      type: "stateOfMind",
      identifier: stateOfMindInfo.identifier,
      kind: stateOfMindInfo.kind,
      kindDisplayName: stateOfMindInfo.kindDisplayName,
      valence: stateOfMindInfo.valence,
      valenceClassification: stateOfMindInfo.valenceClassification,
      valenceClassificationDisplayName:
        stateOfMindInfo.valenceClassificationDisplayName,
      associations: stateOfMindInfo.associations,
      associationsDisplayNames: stateOfMindInfo.associationsDisplayNames,
      labels: stateOfMindInfo.labels,
      labelsDisplayNames: stateOfMindInfo.labelsDisplayNames,
      lightColor: stateOfMindInfo.lightColor,
      darkColor: stateOfMindInfo.darkColor,
      iconIdentifier: stateOfMindInfo.iconIdentifier,
      source: stateOfMindInfo.source,
      journalId: journalId,
      entryId: entryId,
    });
  }

  // If we can't process the nodes we throw an error to avoid
  // loosing data. AKA: better to not let them edit the entry
  // than to let them edit it and loose data.
  const strippedNode = nodes.map((node) => {
    if ("text" in node) {
      return { ...node, text: "" };
    }
    if ("embeddedObjects" in node) {
      return {
        ...node,
        embeddedObjects: node.embeddedObjects.map((obj) => {
          return { type: obj.type };
        }),
      };
    }
    return node;
  });
  Sentry.captureException(
    new Error(
      `Can't process RTJ Node: ${JSON.stringify(
        strippedNode,
      )}. JournalID: ${journalId}. EntryID: ${entryId}`,
    ),
  );

  return createBlock(PARAGRAPH_BLOCK_ID, {
    content: i18n.__("Unsupported content"),
    className: "invalidBlockMessage",
  });
};
