import { ThemeUICSSObject } from "@theme-ui/core";
import { get } from "@theme-ui/css";
import { z } from "zod";

import dayOneIconsJson from "@/components/icons/iconFont/DayOneIcons.json";
import { theme } from "@/styles/theme";

const IconsFontSchema = z.custom<keyof typeof dayOneIconsJson>(
  (icon) => typeof icon === "string" && icon in dayOneIconsJson,
);

export type IconFont = z.infer<typeof IconsFontSchema>;

interface Props {
  icon: IconFont;
  iconStyles?: ThemeUICSSObject;
  className?: string;
  /**
   *
   * Determines the size of whole icon, it use theme to lookup fontSize first, and fallback to normal pixel value
   * @defaultValue 5
   * @example
   * Passing fontSize value
   * ```
   * size={2} // leads to 16px based on theme.fontSizes
   * ```
   * @example
   * Passing normal size value, this used to be using for iconSize on WP Icon component
   * ```
   * size={16}
   * ```
   */
  size?: number;
}

export const Icon = ({ icon, iconStyles, className, size = 5 }: Props) => {
  const getSize = (t: typeof theme) => {
    const iconSize = get(t, `fontSizes.${size}`);
    if (iconSize) {
      return `${iconSize}px`;
    }
    return `${size}px`;
  };

  return (
    <i
      className={`icon-${icon} ${className}`}
      sx={{
        ...(size && { height: (t) => getSize(t) }),
        // this is for overriding the font weight when we need to
        // the global icon font css DayOneIcons.css declares it as normal !important
        // since it was generated by 3rd-party library and we can not control it
        // and we do not want to update the css file whenever we rebuild the icon font library
        // we encapsulate the override logic here
        ...(!!iconStyles?.fontWeight && {
          "&&::before": {
            fontWeight: (theme) =>
              `${get(theme, `fontWeights.${iconStyles.fontWeight}`)} !important`,
          },
        }),
        "&::before": {
          fontSize: (t) => getSize(t),
          ...iconStyles,
        },
      }}
    />
  );
};
