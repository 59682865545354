import { BlockInstance } from "@wordpress/blocks";

import {
  HEADING_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
} from "@/components/Editor/blocks/constants";

export const allBlocksCanBeTransformed = (blocks: BlockInstance[]) => {
  return !blocks.some(
    (block) => ![PARAGRAPH_BLOCK_ID, HEADING_BLOCK_ID].includes(block.name),
  );
};
