import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { D1Modal } from "@/components/D1Modal";
import { useDevice } from "@/data/hooks/LayoutProvider";

type ConfirmProps = {
  handleClose: () => void;
  handleAction?: () => void;
  message: string;
  title?: string;
  actionLabel?: string;
  isDestructive?: boolean;
  handleCancel?: () => void;
};
export const ConfirmDialog: React.FC<ConfirmProps> = ({
  handleClose,
  handleAction,
  message,
  title,
  actionLabel,
  isDestructive = false,
  handleCancel,
}) => {
  // Add additional styles for mobile
  const { isMobile } = useDevice();
  const { __ } = useI18n();
  title = title || __("Confirm");
  actionLabel = actionLabel || __("Confirm");

  const mobileStyles = isMobile
    ? {
        width: "95%",
        height: "fit-content",
        margin: "auto",
        borderRadius: "12px",
      }
    : {};

  return (
    <D1Modal
      title={title}
      onRequestClose={handleClose}
      sx={{ width: 420, ...mobileStyles }}
    >
      <div>
        <p>{message}</p>
        <div
          sx={{ display: "flex", justifyContent: "flex-end", mt: 4, gap: 3 }}
        >
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              handleCancel?.();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            isDestructive={isDestructive}
            onClick={handleAction}
          >
            {actionLabel}
          </Button>
        </div>
      </div>
    </D1Modal>
  );
};
