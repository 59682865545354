import { Button, TextControl, Spinner, Notice } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { Sentry } from "@/Sentry";
import { WPConnectAPIClient } from "@/components/Settings/WPConnect/WPConnectAPIClient";

type Props = {
  connectNewBlog: typeof WPConnectAPIClient.prototype.connectNewBlog;
};

export const WPConnectNewBlogForm: React.FC<Props> = ({ connectNewBlog }) => {
  const { __ } = useI18n();
  const [state, setState] = useState({
    blog_url: "",
    username: "",
    app_pass: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);
    connectNewBlog(state)
      .then((result) => {
        if (result.error) {
          if (result.error.code === "site_not_found") {
            setError(
              __(
                "The site you entered doesn't exist, or doesn't support the wordpress JSON api",
              ),
            );
          } else if (result.error.code === "invalid_blog_url") {
            setError(
              __(
                "Please enter a valid WordPress site URL that starts with https://",
              ),
            );
          } else if (result.error.code === "rest_forbidden") {
            setError(
              __(
                "We couldn't connect. Maybe you entered the username or password wrong, or maybe your user doesn't have access to the WordPress REST API. Make sure you used your actual username and NOT the name you assigned to your application password. Also, you can try using the email address assigned to your WordPress user instead of the username.",
              ),
            );
          } else {
            setError(result.error.message);
          }
        } else {
          setSuccess(true);
          setState({ blog_url: "", username: "", app_pass: "" }); // Clear form values
        }
        setIsLoading(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setError(
          "An unknown error occurred. Please try again later or contact support",
        );
        setIsLoading(false);
      });
  };

  return (
    <div sx={{ maxWidth: "500px", margin: "0 auto" }}>
      <h2 sx={{ fontSize: 4, mb: 3 }}>{__("Connect a New Blog")}</h2>
      <p sx={{ mb: 3, lineHeight: "1.5" }}>
        {__(
          "To connect a new blog, you'll need to generate an application-specific password from your WordPress website. Here's how:",
        )}
      </p>
      <ol sx={{ mb: 4, pl: 4, lineHeight: "1.5" }}>
        <li sx={{ mb: 2 }}>{__("Log in to your WordPress admin panel")}</li>
        <li sx={{ mb: 2 }}>
          {__("Go to Users → Profile → Application Passwords")}
        </li>
        <li sx={{ mb: 2 }}>
          {__("Enter a name for the password (e.g., 'Day One Publish')")}
        </li>
        <li sx={{ mb: 2 }}>{__("Click 'Add New Application Password'")}</li>
        <li sx={{ mb: 2 }}>
          {__(
            "Copy the generated password (you won't be able to see it again)",
          )}
        </li>
      </ol>
      <form onSubmit={handleSubmit}>
        <TextControl
          sx={{ mb: 3 }}
          __nextHasNoMarginBottom
          type="url"
          label={__("Blog URL")}
          value={state.blog_url}
          placeholder="https://mysupercoolblog.com"
          onChange={(value) => setState({ ...state, blog_url: value })}
          required
        />
        <TextControl
          sx={{ mb: 3 }}
          __nextHasNoMarginBottom
          type="text"
          label={__("Username")}
          value={state.username}
          placeholder="pizzamaker21"
          autoComplete="off"
          data-1p-ignore="true"
          onChange={(value) => setState({ ...state, username: value })}
          required
        />
        <TextControl
          sx={{ mb: 4 }}
          __nextHasNoMarginBottom
          type="password"
          label={__("Application Password")}
          value={state.app_pass}
          autoComplete="off"
          data-1p-ignore="true"
          onChange={(value) => setState({ ...state, app_pass: value })}
          required
        />
        {error && (
          <Notice
            status="error"
            isDismissible={false}
            sx={{
              mb: -1,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              pb: 3,
            }}
          >
            {error}
          </Notice>
        )}
        {success && (
          <Notice
            status="success"
            isDismissible={false}
            sx={{
              mb: -1,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              pb: 3,
            }}
          >
            {__("Blog successfully connected!")}
          </Notice>
        )}
        <Button
          variant="primary"
          type="submit"
          data-testid="submit-connect-blog"
          disabled={
            isLoading || !state.blog_url || !state.username || !state.app_pass
          }
          sx={{
            width: "100%",
            justifyContent: "center",
            py: 2,
          }}
        >
          {isLoading ? (
            <div aria-label="loading spinner">
              <Spinner />
            </div>
          ) : (
            __("Connect")
          )}
        </Button>
      </form>
    </div>
  );
};

WPConnectNewBlogForm.displayName = "WPConnectNewBlogForm";
