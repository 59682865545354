import { Z_INDEX_SCREEN_READER_ONLY } from "@/styles/theme";

export const ScreenReaderOnly = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}) => (
  <span
    {...props}
    sx={{
      border: 0,
      clipPath: "inset(50%)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: "0",
      position: "absolute",
      width: "1px",
      wordWrap: "normal",
      zIndex: Z_INDEX_SCREEN_READER_ONLY,
    }}
  >
    {children}
  </span>
);
