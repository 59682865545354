import { useI18n } from "@wordpress/react-i18n";
import { useEffect } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { ButtonItem, ItemGroup } from "@/components/ItemGroup";
import { ParticipantInfo } from "@/components/SharedJournals/ParticipantInfo";
import {
  PartialReaction,
  Reaction,
} from "@/components/SharedJournals/Reaction";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  reactions: PartialReaction[];
  handleClose: () => void;
  journalColor?: string;
  journalId: string;
};

export const ReactionsModal: React.FC<Props> = ({
  reactions,
  handleClose,
  journalColor,
  journalId,
}) => {
  const { __ } = useI18n();

  useEffect(() => {
    analytics.tracks.recordEvent(EVENT.screenView, { screen: "userReactions" });
  }, []);

  return (
    <D1Modal
      title={__("Reactions")}
      shouldCloseOnClickOutside={true}
      onRequestClose={handleClose}
      sx={{
        width: "420px",
        "& .components-modal__content": {
          pl: 4,
        },
      }}
    >
      <ItemGroup
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "med",
          mb: 3,
          margin: "0 auto",
        }}
      >
        {reactions.map((r) => {
          return (
            <ButtonItem
              key={r.user_id}
              onClick={() => {
                viewStates.modalRouter.showSharedJournalMember(
                  journalId,
                  r.user_id,
                );
              }}
              sx={{
                "& div": {
                  mb: 0,
                },
              }}
            >
              <ParticipantInfo
                userId={r.user_id}
                sx={{ textAlign: "left", minWidth: 0 }}
              />
              <span
                sx={{
                  "& svg": { height: "24px" },
                }}
              >
                <Reaction reaction={r.reaction} color={journalColor} />
              </span>
            </ButtonItem>
          );
        })}
      </ItemGroup>
    </D1Modal>
  );
};
