import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { DODexie } from "@/data/db/dexie_db";
import {
  ExportDBRow,
  ExportDetails,
  ImportDBRow,
  ImportDetails,
} from "@/data/db/migrations/import_export";

export class ImportExportRepository {
  constructor(private db: DODexie) {}

  async getExport(id: string): Promise<ExportDBRow | undefined> {
    const result = await this.db.import_export.get(["EXPORT", id]);
    if (result) {
      return result as ExportDBRow;
    }
  }

  async setExport(id: string, value: ExportDetails) {
    const existing = await this.getExport(id);
    const started = existing ? existing.started : new Date().toISOString();
    this.db.import_export.put({ id, type: "EXPORT", started, details: value });
  }

  async deleteExport(id: string) {
    this.db.import_export.delete(["EXPORT", id]);
  }

  subscribeToExport(
    id: string,
    callback: (value: ExportDBRow | undefined) => void,
  ) {
    const sub = liveQuery(() => {
      return this.getExport(id);
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => {
      sub.unsubscribe();
    };
  }

  async getImport(id: string): Promise<ImportDBRow | undefined> {
    const result = await this.db.import_export.get(["IMPORT", id]);
    if (result) {
      return result as ImportDBRow;
    }
  }

  async setImport(id: string, value: ImportDetails) {
    const existing = await this.getImport(id);
    const started = existing ? existing.started : new Date().toISOString();
    this.db.import_export.put({ id, type: "IMPORT", started, details: value });
  }

  async deleteImport(id: string) {
    this.db.import_export.delete(["IMPORT", id]);
  }

  subscribeToImport(
    id: string,
    callback: (value: ImportDBRow | undefined) => void,
  ) {
    const sub = liveQuery(() => {
      return this.getImport(id);
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => {
      sub.unsubscribe();
    };
  }
}
