import {
  IconColors,
  NotificationIcon,
} from "@/components/Notifications/NotificationIcon";

export const JoinedIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon="plus"
      color={IconColors.green.color}
      bgColor={IconColors.green.bgColor}
      size={1}
    />
  );
};
