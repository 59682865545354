import { Flex, FlexItem } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useLocation } from "wouter";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import {
  GroupHeading,
  Item,
  ItemButton,
  ItemGroup,
} from "@/components/ItemGroup";
import { ExportButton } from "@/components/JournalSettings/ExportButton";
import { HideJournalFromSync } from "@/components/JournalSettings/HideJournalFromSync";
import { trackJournalToggle } from "@/components/JournalSettings/JournalSettingsAdvancedView";
import { ViewName } from "@/components/JournalSettings/JournalSettingsTypes";
import { JournalSharingInfoButton } from "@/components/JournalSettings/JournalSharingInfoButton";
import { ToggleConceal } from "@/components/JournalSettings/ToggleConceal";
import { ToggleShowInAllEntries } from "@/components/JournalSettings/ToggleShowInAllEntries";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { getAllEntriesURL } from "@/data/URLFunctions";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { journalColorLabelFromJournal } from "@/data/models/JournalFns";
import { JournalStore } from "@/data/stores/JournalStore";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

type JournalFormProps = {
  journal: JournalDBRow;
  setJournal: (journal: JournalDBRow) => void;
  setView: (view: ViewName) => void;
  participantCount: number;
  onJournalSyncToggle?: (value: boolean) => void;
  newUnsyncableValue?: boolean;
  primaryViewState: PrimaryViewState;
  journalStore: JournalStore;
  handleClose: () => void;
  setError: (error: string | null) => void;
};

export const SimplifiedJournalForm: React.FC<JournalFormProps> = observer(
  ({
    journal,
    setJournal,
    setView,
    participantCount,
    onJournalSyncToggle,
    newUnsyncableValue,
    primaryViewState,
    journalStore,
    handleClose,
    setError,
  }) => {
    const { __, _x } = useI18n();
    const [confirmLeave, setConfirmLeave] = useState(false);
    const user = primaryViewState.user;
    const [location, setLocation] = useLocation();

    const handleLeaveJournal = async () => {
      if (!user) {
        return;
      }
      handleClose();
      const result = await journalStore.leaveSharedJournal(journal, user.id);
      if (result) {
        if (journal.id && location.includes(journal.id)) {
          setLocation(getAllEntriesURL(primaryViewState.selectedEntryView));
        }
      } else {
        setError(__("Could not leave journal. Please try again"));
      }
    };

    return (
      <>
        <GroupHeading>{__("Journal name")}</GroupHeading>
        <ItemGroup>
          <Item>
            <p>{journal.name}</p>
          </Item>
        </ItemGroup>
        <GroupHeading>{__("Color")}</GroupHeading>
        <ItemGroup>
          <Item>
            <FlexItem>
              <Flex>
                <span
                  sx={{
                    borderRadius: "rounded",
                    display: "block",
                    width: "24px",
                    height: "24px",
                    backgroundColor: journal.color,
                    border: "1px solid",
                    borderColor: "borderPrimary",
                  }}
                ></span>
                <p>{journalColorLabelFromJournal(journal)}</p>
              </Flex>
            </FlexItem>
          </Item>
        </ItemGroup>
        {journal.description !== "" && (
          <>
            <GroupHeading>{__("Description")}</GroupHeading>
            <ItemGroup>
              <Item>
                <p>{journal.description}</p>
              </Item>
            </ItemGroup>
          </>
        )}
        <JournalSharingInfoButton
          participantCount={participantCount}
          setView={setView}
        />
        <ExportButton journalId={journal.id} />
        <GroupHeading>{__("Advanced")}</GroupHeading>
        <ItemGroup>
          <Item>
            <p>{__("Sort method:")}</p>
            <p>
              {journal.sort_method === "entryDate"
                ? __("Entry Date")
                : __("Edit Date")}
            </p>
          </Item>
          <Item>
            <p>{__("End-to-end encrypted")}</p>
          </Item>

          <ToggleConceal
            journal={journal}
            setJournal={setJournal}
            trackOnChange={trackJournalToggle}
          />

          <ToggleShowInAllEntries
            journal={journal}
            setJournal={setJournal}
            trackOnChange={trackJournalToggle}
          />

          <ToggleSetting
            label={__("Enable Comments")}
            checked={!journal.comments_disabled}
            disabled={true}
            onChange={() => {}}
          />
          <HideJournalFromSync
            onToggle={onJournalSyncToggle!}
            value={newUnsyncableValue!}
          />
        </ItemGroup>
        <ItemGroup addMargin={true}>
          <ItemButton
            sx={{
              minHeight: "48px",
              "&&": { color: "journalRed" },
              ":hover": {
                opacity: "0.6",
              },
            }}
            onClick={() => {
              setConfirmLeave(true);
            }}
          >
            {__("Leave Journal")}
          </ItemButton>
        </ItemGroup>

        {confirmLeave && (
          <ConfirmDialog
            handleClose={() => setConfirmLeave(false)}
            title={sprintf(
              _x(
                "Leave %s",
                "Title to confirm journal deletion including journal name",
              ),
              journal.name,
            )}
            message={__(
              "By leaving this journal, you will no longer have access to it or its entries. Are you sure you want to continue?",
            )}
            actionLabel={__("Leave Journal")}
            handleAction={handleLeaveJournal}
            isDestructive={true}
          />
        )}
      </>
    );
  },
);
SimplifiedJournalForm.displayName = "SimplifiedJournalForm";
