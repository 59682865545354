import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { CheckListSummary } from "@/components/EntryPreview/CheckListItems";
import { getCheckListItems } from "@/components/EntryPreview/EntryFooter";
import { EntryTags } from "@/components/EntryPreview/EntryTags";
import { Icon } from "@/components/Icon";
import { Heart } from "@/components/icons/Heart";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { EntryModel } from "@/data/models/EntryModel";
import { journalColorName } from "@/data/models/JournalFns";
import { getRelativeTime } from "@/utils/date-helper";
import { displayTemperature } from "@/utils/temperature-helper";
import {
  primaryViewState,
  userSettingsViewState,
} from "@/view_state/ViewStates";

type EntryFooterProps = {
  entry: EntryModel;
  entryJournal: JournalDBRow;
};

export const EntryFooter: React.FC<EntryFooterProps> = observer(
  ({ entry, entryJournal }) => {
    const { temperatureUnit } = userSettingsViewState;
    const [tags, setTags] = useState<string[]>([]);
    const { tagRepository } = d1Classes;
    const user = primaryViewState.user;

    const journalName = entryJournal?.is_decrypted
      ? entryJournal?.name
      : entryJournal?.id;

    const date = new Date(entry.date);
    const timeField = getRelativeTime(date);

    const location = entry.location;
    const placeName = location?.placeName ? `${location.placeName}` : "";
    const is_starred = entry.isStarred && entry.creatorUserId === user?.id;
    const is_pinned = entry.isPinned;
    const weather = entry.weather;

    const checkListItems = getCheckListItems(entry.richTextJSON.contents);
    const hasCheckListItems = checkListItems.total > 0;

    const journalColor = entryJournal ? journalColorName(entryJournal) : null;

    const temperature = weather?.tempCelsius
      ? `${displayTemperature(weather.tempCelsius, temperatureUnit)} ${
          weather.description || ""
        }`
      : "";

    const showTags = !!tags && tags.length > 0;
    const tagColor = journalColor;

    useEffect(() => {
      const getTags = async () => {
        const _tags = await tagRepository.getTagsForEntry(
          entry.journalId,
          entry.id,
        );
        setTags(_tags);
      };
      getTags();
    }, [entry.journalId, entry.id]);

    return (
      <div
        className="entry-footer"
        sx={{
          mt: 2,
          color: "textSecondary",
          fontSize: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          pr: 0,
          "& span": {
            ":not(:first-of-type)::before": {
              content: "'•'",
              color: "textSecondary",
              px: 1,
            },
          },
        }}
      >
        {journalName && (
          <span
            sx={{
              color: journalColor,
              fontWeight: "medium",
            }}
          >
            {journalName}
          </span>
        )}
        {is_pinned && (
          <span
            sx={{
              display: "inline-flex",
            }}
          >
            <Icon icon="pin-filled" size={2} />
          </span>
        )}
        {is_starred && (
          <span
            sx={{
              display: "inline-flex",
              "&& svg": {
                fill: "red",
              },
            }}
          >
            <Heart />
          </span>
        )}
        <span>{timeField}</span>
        {hasCheckListItems && (
          <CheckListSummary checkListItems={checkListItems} />
        )}
        {temperature && <span>{temperature}</span>}
        {showTags && <EntryTags tags={tags} color={tagColor} />}
        {placeName && <span>{placeName}</span>}
      </div>
    );
  },
);

EntryFooter.displayName = "EntryFooter";
