import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { DynamicHeightContainer } from "./DynamicHeightContainer";

import { notificationsTitle } from "@/components/Notifications/NotificationsModal";
import { PendingApprovalView } from "@/components/Notifications/PendingApprovalView";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { ApprovalItemViewState } from "@/view_state/ApprovalItemViewState";
import { PendingApprovalViewState } from "@/view_state/PendingApprovalViewState";
import { viewStates } from "@/view_state/ViewStates";

export const PendingApprovalList = observer(
  ({
    pendingApprovals,
    inModal = false,
  }: {
    pendingApprovals: ApprovalItemViewState[];
    inModal: boolean;
  }) => {
    const { __ } = useI18n();
    const { pendingApprovals: pendingApprovalsViewState } = viewStates;

    if (pendingApprovals.length === 0 && inModal) {
      return __("No more pending requests 🎉");
    }

    return (
      <>
        {inModal && pendingApprovals.length > 0 && (
          <h2 sx={{ ...notificationsTitle, ml: 3 }}>
            {sprintf(
              __("%d Journal Requests"),
              pendingApprovalsViewState.count,
            )}
          </h2>
        )}
        {!inModal && pendingApprovals.length > 0 && (
          <PendingApprovalHeader
            pendingApprovalsViewState={pendingApprovalsViewState}
          ></PendingApprovalHeader>
        )}
        <ul
          sx={{
            borderBottom: "1px solid",
            borderColor:
              inModal || pendingApprovals.length === 0
                ? "transparent"
                : "borderPrimary",
            ml: inModal ? 0 : 3,
            pb: 2,
          }}
        >
          <DynamicHeightContainer>
            {pendingApprovals.map((item) => {
              return (
                <PendingApprovalView
                  key={`approval-${item.id}`}
                  item={item}
                ></PendingApprovalView>
              );
            })}
          </DynamicHeightContainer>
        </ul>
      </>
    );
  },
);

function PendingApprovalHeader({
  pendingApprovalsViewState,
}: {
  pendingApprovalsViewState: PendingApprovalViewState;
}) {
  const { __ } = useI18n();
  return (
    <li
      className="date-title"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&&&": {
          mr: 0,
          pr: 2,
        },
      }}
    >
      {__("Journal requests")}

      {pendingApprovalsViewState.hasMore && (
        <EditLinkButton
          onClick={() => {
            viewStates.modalRouter.showPendingApprovals();
          }}
          label={sprintf(__("See all (%d)"), pendingApprovalsViewState.count)}
          title={sprintf(__("See all (%d)"), pendingApprovalsViewState.count)}
        />
      )}
    </li>
  );
}
