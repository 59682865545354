import { registerBlockType, unregisterBlockType } from "@wordpress/blocks";
import { useDispatch } from "@wordpress/data";
import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useState } from "react";

import {
  VideoBlockEditProps,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { GalleryMediaDropZone } from "@/components/Editor/components/GalleryMediaDropZone";
import { useUpload } from "@/components/Editor/hooks/mediaUpload";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { SyncableVideo } from "@/components/SyncableVideo";
import { mediaTransforms } from "@/utils/block-helper";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const VideoBlock: React.FC<VideoBlockEditProps> = observer(
  ({ attributes, setAttributes, isSelected, clientId: blockClientId }) => {
    const globalEntryID = primaryViewState.selectedGlobalEntryID;
    const [fileNotRegistered, setFileNotRegistered] = useState(false);
    const { removeBlock } = useDispatch("core/block-editor");

    const { uploadFile } = useUpload(
      globalEntryID,
      viewStates,
      attributes,
      setAttributes,
      setFileNotRegistered,
    );

    // Upload a temporary file on mount.
    useEffect(() => {
      uploadFile();
    }, []);

    useLayoutEffect(() => {
      if (fileNotRegistered) {
        removeBlock(blockClientId);
      }
    }, [fileNotRegistered]);

    const VideoComponent = (
      <SyncableVideo
        journalId={attributes.journalId}
        entryId={attributes.entryId}
        clientId={attributes.clientId}
        aspectRatio={attributes.aspectRatio}
        isSelected={isSelected}
        galleryHeight={attributes.galleryHeight}
        blockClientId={blockClientId}
      />
    );

    return (
      <GalleryMediaDropZone
        blockId={blockClientId}
        globalEntryID={globalEntryID}
      >
        {VideoComponent}
      </GalleryMediaDropZone>
    );
  },
);

const { videoTransform: transforms } = mediaTransforms;

export const register = () => {
  if (!blockIsRegistered(VIDEO_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(VIDEO_BLOCK_ID, {
      edit: (props: VideoBlockEditProps) => <VideoBlock {...props} />,
      title: "Video",
      category: "media",
      textdomain: "default",
      description: "Day One Video Block",
      apiVersion: 2,
      attributes: {
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
        clientId: {
          type: "string",
        },
        src: {
          type: "string",
        },
        aspectRatio: {
          type: "number",
        },
        galleryHeight: {
          type: "number",
        },
      },
      supports: {
        //lock: false,
        html: false,
        inserter: false,
      },
      transforms,
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(VIDEO_BLOCK_ID)) {
    return unregisterBlockType(VIDEO_BLOCK_ID);
  }
  return false;
};
