import { makeAutoObservable } from "mobx";
import { makeLoggable } from "mobx-log";

import type { Notification } from "@/data/db/migrations/notification";

import analytics from "@/analytics";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { TagDBRow } from "@/data/db/migrations/tag";
import { TemplateDBRow } from "@/data/db/migrations/template";
import { UserKeysDBRow } from "@/data/db/migrations/user_keys";
import {
  EntryModel,
  globalEntryIDsAreEqual,
  globalEntryIDToString,
} from "@/data/models/EntryModel";
import {
  orderJournals,
  separateVisibleJournals,
} from "@/data/models/JournalFns";
import { UserModel } from "@/data/models/UserModel";
import { defaultDailyPromptSettings } from "@/data/repositories/DailyPromptRepository";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { JournalStatsRepository } from "@/data/repositories/JournalStatsRepository";
import {
  DailyPrompt,
  DailyPromptSettings,
} from "@/data/repositories/PromptsAPI";
import { JournalSyncStatus } from "@/data/repositories/SyncStateRepository";
import { HASHTAG_REGEX } from "@/data/repositories/TagParser";
import { Limited, Enabled } from "@/data/repositories/UserAPI";
import {
  JournalParticipant,
  OwnershipTransfer,
} from "@/data/repositories/V6API";
import { EntryStore } from "@/data/stores/EntryStore";
import { JournalStore } from "@/data/stores/JournalStore";
import { NotificationStore } from "@/data/stores/NotificationStore";
import { TagCount, TagStore } from "@/data/stores/TagStore";
import { TemplateStore } from "@/data/stores/TemplateStore";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { UserStore } from "@/data/stores/UserStore";
import { isDeepEqual } from "@/utils/is-equal";
import { isModalOpen } from "@/utils/is-modal-open";

export const HOME_VIEW = "home_view";
export const CALENDAR_VIEW = "calendar_view";
export const TIMELINE_VIEW = "timeline_view";
export const MEDIA_VIEW = "media_view";
export type ViewType =
  | typeof HOME_VIEW
  | typeof CALENDAR_VIEW
  | typeof TIMELINE_VIEW
  | typeof MEDIA_VIEW;
// The special string we use as a journal ID for the "all entries"
// view.
export const journalId_AllEntries = "All_Entries";

export const TRANSFER_OWNERSHIP_MESSAGE =
  "Are you sure you want to transfer ownership of %s to %s? By transferring ownership, you will lose ownership permissions but will remain as a member.";
export const TRANSFER_OWNERSHIP_TRANSFEREE_NON_PREMIUM_MESSAGE =
  "Are you sure you want to transfer ownership of %s to %s? By transferring ownership you will lose ownership permissions but will remain as a member.The journal will also enter a temporary restricted state until the new owner upgrades to a Premium plan.";

// a map that'll keep track of any pending approvals for shared journals
// this will have extra state to track if an approval has been ignored or approved
export type JournalSyncLookup = Record<string, JournalSyncStatus>;
export class PrimaryViewState {
  masterKeyString: string | null = null;

  // This is true if the user has encryption keys downloaded from the server,
  // it does NOT indicate whether the user has a master key string which is required
  // to decrypt the user encryption keys
  userEncryptionKeyExists = false;

  user: UserModel | null = null;
  // So that the auth provider can differentiate between a
  // loading user and a missing user.
  userLoading = true;
  isLoggingOut = false;

  // Entry Stuff
  // Remember that to fully identify an entry, we need
  // both journal and entry id.
  selectedGlobalEntryID: GlobalEntryID | null = null;

  // FOCUSED ENTRY STUFF
  // The entry that's focused in the entry view.
  // This is different from the selected entry, which is the entry
  // that's visible in the editor. This just allows only one entry in
  // the entry list to be focused at a time.
  focusedEntryInfo: GlobalEntryID | null = null;

  // Remember that the selected journal ID is not necessarily the same as the
  // selected entry's journal ID. We may be viewing "all entries", which
  // would have no selected journal, but still have a journal ID with the entry.
  selectedJournalId: string | null = null;

  // The journal ID selected in Settings
  settingsJournalId: string | null = null;

  selectedPromptId: string | null = null;

  // Unsyncable journals are ones that have been intentionally hidden from the
  // sidebar by the user. They can't be clicked on, and they don't have any
  // data locally. They should only be visible in the settings page.
  unsyncableJournalIds: string[] = [];

  journals: JournalDBRow[] = [];
  journalsInAllEntries: string[] = [];
  unifiedJournals: JournalDBRow[] = [];
  journalsLoaded = false;

  hiddenJournalCount = 0;
  entryCounts: Record<string, number> = {};

  private _journalSyncLookup: JournalSyncLookup = {};

  revealedEntries: string[] = [];
  isJournalRevealed = false;

  hasSharedJournals = false;
  forceLogout = "";
  pendingLogout = false;

  newVersionAvailable = "";

  dailyPrompt: DailyPrompt | undefined;
  recentDailyPrompts: DailyPrompt[] = [];
  dailyPromptSettings: DailyPromptSettings = defaultDailyPromptSettings;
  currentDailyPromptDismissed = false;

  selectedEntryView: ViewType = TIMELINE_VIEW;

  templates: TemplateDBRow[] = [];

  tags: TagDBRow[] = [];

  // search
  filteredEntryModels: EntryModel[] = [];
  showSearch = false;

  // Notifications
  notifications: Notification[] = [];

  totalOwnJournalCount = 0;
  hasInstagramJournal = false;

  constructor(
    private keyValueStore: KeyValueStore,
    private userStore: UserStore,
    private userKeysStore: UserKeysStore,
    private journalStore: JournalStore,
    private entryStore: EntryStore,
    private templateStore: TemplateStore,
    private tagStore: TagStore,
    private journalRepository: JournalRepository,
    private journalStatsRepository: JournalStatsRepository,
    private notificationStore: NotificationStore,
  ) {
    makeAutoObservable(
      this,
      {
        // Use this for functions that are not actions, but just
        // compute some view over state. Setting 'false' here
        // doesn't mean an observer component won't re-render,
        // but that the function is not wrapped in an action,
        // and not cached as a view.
        getEnabledFeatureValues: false,
        getSyncStateForJournal: false,
        isEntryRevealed: false,
        getEnabledFeature: false,
        getTagCounts: false,
        getJournalById: false,
      },
      { autoBind: true },
    );
    // Turn this on if you need to log actions
    const debugMode = false;
    if (debugMode) {
      makeLoggable(this);
    }

    // The jest tests fail when subscripts are set up, so we don't start them
    // for legacy jest tests.
    if (!process.env.NODE_IS_JEST) {
      this.listenToStores();
    }
  }

  // this count includes journal that user participates but not a owner (shared journal)
  // use totalOwnJournalCount instead when you need to exclude that
  get totalJournalCount() {
    return this.journals.length + this.hiddenJournalCount;
  }

  private listenToStores() {
    // This UI state reacts to changes in users, journals, and entries
    // so we need to set up a number of subscriptions here to watch
    // for those things.
    this.journalStore.subscribeToAll(this.gotJournalUpdate);
    this.templateStore.subscribeToAll(this.gotTemplateUpdate);
    this.tagStore.subscribeToAll(this.gotTagsUpdate);
    this.userStore.subscribe(this.gotUserUpdate);
    this.userKeysStore.subscribeToMasterKeyString(
      this.gotMasterKeyStringUpdate,
    );
    this.userKeysStore.subscribeToActiveKey(this.gotActiveKeyUpdate);

    this.keyValueStore.subscribe("force-logout", this.gotForceLogoutUpdate);
    this.keyValueStore.subscribe("pending-logout", this.gotPendingLogoutUpdate);
    this.keyValueStore.subscribe(
      "new-version-available",
      this.gotNewVersionAvailableUpdate,
    );
    this.keyValueStore.subscribe("daily-prompt", this.gotDailyPromptUpdate);
    this.keyValueStore.subscribe(
      "recent-daily-prompts",
      this.gotRecentDailyPromptsUpdate,
    );
    this.keyValueStore.subscribe(
      "daily-prompt-settings",
      this.gotDailyPromptSettingsUpdate,
    );
    this.keyValueStore.subscribe(
      "daily-prompt-dismissed",
      this.gotCurrentDailyPromptDismissedUpdate,
    );
    this.journalStatsRepository.subscribeToCounts(this.gotEntryCountsUpdate);
    this.entryStore.subToSync(this.gotJournalSyncUpdate);
    this.journalRepository.subToUnsyncableJournalIds(
      this.gotUnsyncableJournalIds,
    );
    this.journalRepository.subscribeToJournalCursor(
      this.gotJournalCursorUpdate,
    );

    this.notificationStore.subscribeToNotifications(
      this.gotNotificationsUpdate,
    );
  }

  private gotJournalCursorUpdate(cursor: string) {
    if (cursor !== "" || this.journals.length > 0) {
      this.journalsLoaded = true;
    }
  }

  gotActiveKeyUpdate(keys: UserKeysDBRow | undefined) {
    this.userEncryptionKeyExists = !!keys;
  }

  //
  // Views, DO NOT change the state inside of views
  //
  isEntryRevealed = (globalEntryID: GlobalEntryID) => {
    return this.revealedEntriesInclude(globalEntryID) || this.isJournalRevealed;
  };

  revealedEntriesInclude = (globalEntryID: GlobalEntryID) => {
    return this.revealedEntries.includes(globalEntryIDToString(globalEntryID));
  };

  getEnabledFeature = (feature: string) => {
    return this.user?.features?.find((f) => f.name === feature) as Enabled;
  };

  getEnabledFeatureValues = (features: string[]) => {
    const enabledFeatures = this.user?.features?.filter((f) =>
      features.includes(f.name),
    ) as Enabled[];
    // In draft loged out mode there's no user
    if (!enabledFeatures) {
      return {};
    }
    return enabledFeatures.reduce((acc: { [key: string]: boolean }, f) => {
      acc[f.name] = f.enabled;
      return acc;
    }, {});
  };

  getUniqueTags = () => {
    const uniqueTags = this.tags.reduce((tags, tag) => {
      const tagName = tag.tag.trim();
      if (!tags.includes(tagName) && tagName !== "") {
        tags.push(tagName);
      }
      return tags;
    }, [] as string[]);
    return uniqueTags;
  };

  getTagCounts = (journalId?: string, onlyHashtags = false) => {
    const searchableTags = this.tags.reduce((tagsWithCounts, tag) => {
      if (onlyHashtags) {
        const hashtag = "#" + tag.tag.trim();
        HASHTAG_REGEX.lastIndex = 0;
        const match = HASHTAG_REGEX.exec(hashtag);

        if (match && match[0] === hashtag) {
          const currentCount = tagsWithCounts.get(hashtag) || 0;
          tagsWithCounts.set(hashtag, currentCount + 1);
        }
      } else {
        const tagName = tag.tag.trim();
        if (journalId) {
          if (tag.journal_id === journalId) {
            const currentCount = tagsWithCounts.get(tagName) || 0;
            tagsWithCounts.set(tagName, currentCount + 1);
          }
        } else {
          const currentCount = tagsWithCounts.get(tagName) || 0;
          tagsWithCounts.set(tagName, currentCount + 1);
        }
      }

      return tagsWithCounts;
    }, new Map<string, number>());

    const sortTags = (a: TagCount, b: TagCount) => {
      const [tagA, countA] = a;
      const [tagB, countB] = b;

      if (countA === countB) {
        return tagA.localeCompare(tagB);
      }

      return countB - countA;
    };

    const tagsWithcounts = Array.from(searchableTags.entries()).sort(
      sortTags,
    ) as TagCount[];

    return tagsWithcounts;
  };

  getJournalById = (journalId: string | undefined | null) => {
    return this.journals.find((j) => j.id === journalId);
  };

  journalIsBeingTransferred = (journalId: string | undefined | null) => {
    const journal = this.getJournalById(journalId);
    return journal?.state === "being_transferred" || false;
  };

  get isEncryptionReady() {
    return !!this.masterKeyString;
  }

  get journalLimit() {
    return this.user?.journalLimit;
  }

  get attachmentsPerEntryLimit() {
    const journal = this.selectedEntryJournal;
    const limit = journal?.is_shared
      ? "attachmentsPerSharedEntry"
      : "attachmentsPerEntry";
    return this.user?.features?.find((f) => f.name === limit) as Limited;
  }

  get selectedJournal() {
    const selectedJournalId = this.selectedJournalId;
    return this.getJournalById(selectedJournalId) || null;
  }

  // this is the real journal of the selected entry. It is never journalId_AllEntries
  get selectedEntryJournal() {
    const journalId = this.selectedGlobalEntryID?.journal_id;
    if (!journalId) {
      return null;
    }
    const selectedJournal = this.getJournalById(journalId!);
    if (!selectedJournal) {
      console.warn("Could not retrieve journal for ID:", journalId);
      return null;
    }
    return selectedJournal;
  }

  get visibleSyncableJournals() {
    return this.journals.filter((j) => !this.isJournalUnsyncable(j.id));
  }

  getSyncStateForJournal = (journalId: string) => {
    return this._journalSyncLookup[journalId] || "NOT_SELECTED";
  };

  get journalSyncLookup() {
    return this._journalSyncLookup;
  }

  // Actions. These functions are automatically bound to `this` and treated as
  // actions by MobX makeAutoObservable, autoBind: true.
  focusEntry = (globalId: GlobalEntryID) => {
    this.focusedEntryInfo = globalId;
    // Avoid focusing on the entry if there is a modal open.
    if (!isModalOpen()) {
      const element = document.getElementById(
        `entry-list-item-${globalId.id}`,
      ) as HTMLAnchorElement;
      element?.removeAttribute("tabindex");
      element?.focus();
    }
  };

  selectEntryView = (view: ViewType) => {
    this.selectedEntryView = view;
  };

  setFilteredEntryModels = (entryModels: EntryModel[]) => {
    this.filteredEntryModels = entryModels;
  };

  setShowSearch = (show: boolean) => {
    this.showSearch = show;
  };

  reconcealAllEntries = () => {
    this.revealedEntries = [];
    this.isJournalRevealed = false;
  };

  selectEntry = (globalId: GlobalEntryID, isAllEntries: boolean) => {
    const journalId = isAllEntries ? journalId_AllEntries : globalId.journal_id;
    const selectedJournalId = this.selectedJournalId;
    if (
      globalEntryIDsAreEqual(this.selectedGlobalEntryID, globalId) &&
      journalId === selectedJournalId
    ) {
      return;
    }
    const shouldReconceal = journalId !== this.selectedJournalId;
    if (shouldReconceal) {
      this.reconcealAllEntries();
    }
    this.revealEntry(globalId);
    this.focusEntry(globalId);
    this.selectedGlobalEntryID = globalId;
    this.selectedJournalId = journalId;
    this.selectedPromptId = null;
  };

  selectJournal(journalId: string) {
    const shouldReconceal = journalId !== this.selectedJournalId;
    if (shouldReconceal) {
      this.reconcealAllEntries();
    }
    this.selectedJournalId = journalId;
    this.selectedGlobalEntryID = null;
    this.focusedEntryInfo = null;
    this.selectedPromptId = null;
  }

  selectPrompt(journalId: string, promptId: string) {
    this.selectedPromptId = promptId;
    this.selectedJournalId = journalId;
    this.selectedGlobalEntryID = null;
    this.focusedEntryInfo = null;
  }

  selectSettingsJournal(journalId: string) {
    this.settingsJournalId = journalId;
  }

  revealEntry = (globalEntryID: GlobalEntryID) => {
    if (!this.revealedEntriesInclude(globalEntryID)) {
      this.revealedEntries = [
        ...this.revealedEntries,
        globalEntryIDToString(globalEntryID),
      ];
    }
  };

  setRevealedEntries = (revealedEntries: string[]) => {
    this.revealedEntries = revealedEntries;
  };

  get shouldConcealAll() {
    return this.revealedEntries.length !== 0 || this.isJournalRevealed;
  }

  onRevealButtonPress = () => {
    // If any entries are revealed, then hide all entries again
    // and deselect the current entry.
    // Otherwise, reveal all entries.
    if (this.shouldConcealAll) {
      this.revealedEntries = [];
      this.isJournalRevealed = false;
      this.selectedGlobalEntryID = null;
    } else {
      this.isJournalRevealed = true;
    }
  };

  setIsLoggingOut = async (isLoggingOut: boolean) => {
    await this.keyValueStore.set("is-logging-out", isLoggingOut);
    this.isLoggingOut = isLoggingOut;
  };

  isJournalUnsyncable = (journalId: string) => {
    return this.unsyncableJournalIds.includes(journalId);
  };

  // These ones that start with `got` are called by the subscriptions
  // we set up to listen to repository updates.
  private gotJournalUpdate(journals: JournalDBRow[]) {
    // Re-sort the journals according to the order on the user before updating state.
    const order = this.user?.full_journal_order;
    const unifiedOrder = this.user?.unified_journal_order;

    const { visible: visibleJournals, hiddenCount: hiddenJournalCount } =
      separateVisibleJournals(journals);
    const sorted = orderJournals(order || [], visibleJournals);
    const unifiedSorted = orderJournals(unifiedOrder || [], visibleJournals);

    // If the revealed entries are from a journal that became unconcealed
    // we need to clean them out
    let revealedEntries = this.revealedEntries;

    if (revealedEntries.length > 0) {
      const journalsWithRevealedEntries = new Set<string>(
        revealedEntries.map((e) => e.split(":")[0]),
      );
      const previousJournals = this.journals;

      journalsWithRevealedEntries.forEach((jId) => {
        const previousValues = previousJournals.find((j) => j.id === jId);
        const currentValues = journals.find((j) => j.id === jId);
        if (
          previousValues &&
          currentValues &&
          previousValues?.conceal &&
          !currentValues?.conceal
        ) {
          revealedEntries = revealedEntries.filter((rE) => !rE.startsWith(jId));
        }
      });
    }

    this.journals = sorted;
    this.journalsInAllEntries = this.journals
      .filter((j) => j.hide_all_entries === 0)
      .map((j) => j.id);

    this.unifiedJournals = unifiedSorted;
    this.hasSharedJournals = journals.some((j) => j.is_shared);
    this.hiddenJournalCount = hiddenJournalCount;
    this.revealedEntries = revealedEntries;
    this.hasInstagramJournal = journals.some((j) =>
      j.connected_services?.includes("instagram"),
    );
    this.totalOwnJournalCount = journals.reduce(
      (count, journal) =>
        journal.owner_id === this.user?.id ? count + 1 : count,
      0,
    );
    // If the currently selected journal is unsyncable, go back to all entries
    if (
      this.selectedJournal &&
      this.isJournalUnsyncable(this.selectedJournal.id)
    ) {
      this.selectJournal(journalId_AllEntries);
    }
  }

  private gotTemplateUpdate(templates: TemplateDBRow[]) {
    if (isDeepEqual(this.templates, templates)) return;
    this.templates = templates;
  }

  private gotTagsUpdate(tags: TagDBRow[]) {
    if (isDeepEqual(this.tags, tags)) return;
    this.tags = tags;
  }

  private gotUserUpdate(user: UserModel | null) {
    // If the journal order has changed on the user, re-sort the journals on state
    const oldJournalOrder = this.user?.full_journal_order;
    const newJournalOrder = user?.full_journal_order;
    let sortedJournals = this.journals;

    const oldUnifiedJournalOrder = this.user?.unified_journal_order;
    const newUnifiedJournalOrder = user?.unified_journal_order;
    let unifiedSortedJournals = this.unifiedJournals;

    if (newJournalOrder && !isDeepEqual(oldJournalOrder, newJournalOrder)) {
      sortedJournals = orderJournals(newJournalOrder, sortedJournals);
    }

    if (
      newUnifiedJournalOrder &&
      !isDeepEqual(oldUnifiedJournalOrder, newUnifiedJournalOrder)
    ) {
      unifiedSortedJournals = orderJournals(
        newUnifiedJournalOrder,
        unifiedSortedJournals,
      );
    }

    this.user = user;
    analytics.initialize(user);
    this.userLoading = false;
    this.journals = sortedJournals;
    this.journalsInAllEntries = this.journals
      .filter((j) => j.hide_all_entries === 0)
      .map((j) => j.id);
    this.unifiedJournals = unifiedSortedJournals;
  }

  private gotMasterKeyStringUpdate(masterKeyString: string | undefined) {
    this.masterKeyString = masterKeyString || null;
  }

  private gotForceLogoutUpdate(forceLogout?: string) {
    this.forceLogout = forceLogout || "no";
  }

  private gotPendingLogoutUpdate(pendingLogout?: boolean) {
    this.pendingLogout = !!pendingLogout;
  }

  private gotNewVersionAvailableUpdate(newVersionAvailable?: string) {
    this.newVersionAvailable = newVersionAvailable || "no";
  }

  private gotDailyPromptUpdate(dailyPrompt?: DailyPrompt) {
    this.dailyPrompt = dailyPrompt || undefined;
  }

  private gotRecentDailyPromptsUpdate(recentDailyPrompts?: DailyPrompt[]) {
    this.recentDailyPrompts = recentDailyPrompts || [];
  }

  private gotDailyPromptSettingsUpdate(
    dailyPromptSettings?: DailyPromptSettings,
  ) {
    this.dailyPromptSettings =
      dailyPromptSettings || defaultDailyPromptSettings;
  }

  private gotCurrentDailyPromptDismissedUpdate(promptDismissed?: string) {
    this.currentDailyPromptDismissed = promptDismissed ? true : false;
  }

  private gotEntryCountsUpdate(entryCounts: Record<string, number>) {
    this.entryCounts = entryCounts;
  }

  private gotJournalSyncUpdate(
    journalSyncState: Record<string, JournalSyncStatus>,
  ) {
    this._journalSyncLookup = journalSyncState;
  }

  private gotNotificationsUpdate(notifications: Notification[]) {
    this.notifications = notifications;
  }

  private gotUnsyncableJournalIds(unsyncableJournalIds: string[] | undefined) {
    this.unsyncableJournalIds = unsyncableJournalIds || [];
  }

  userCanInviteParticipants(journal: JournalDBRow) {
    const isShared = journal.is_shared;
    const isReadOnly = journal.is_read_only;
    const userIsJournalOwner = journal.owner_id === this.user?.id;

    if (isShared && userIsJournalOwner) {
      return !isReadOnly;
    }
    return false;
  }

  isElectronApp() {
    return !!window.IS_ELECTRON_APP;
  }

  getSharedJournalSettings(journal: JournalDBRow) {
    const isShared = journal.is_shared;
    const isReadOnly = journal.is_read_only;
    const userIsOwner = journal.owner_id === this.user?.id;

    const { ownership_transfers } = journal;
    const ownerId = journal.owner_id;
    const pendingTransfers: (OwnershipTransfer | undefined)[] =
      ownership_transfers.filter((t) => t.status === "pending");
    const isPendingTransfer = pendingTransfers.length > 0;
    const transfersInProgress: (OwnershipTransfer | undefined)[] =
      ownership_transfers.filter((t) => t.status === "in_progress");
    const isTransferInProgress = transfersInProgress.length > 0;

    const isBeingTransferred =
      journal.state === "being_transferred" || isTransferInProgress;
    const canBeTransferred =
      isShared && userIsOwner && !isBeingTransferred && !isPendingTransfer;
    const participantIsTransferee = (participant: JournalParticipant) => {
      const pendingTransfer = pendingTransfers?.[0];
      return pendingTransfer?.new_owner_id === participant.id;
    };
    const userIsParticipant = (participant: JournalParticipant) => {
      return participant.id === this.user?.id;
    };
    const findParticipant = (participantId: JournalParticipant["id"]) => {
      return journal.participants.find((p) => p.id === participantId);
    };

    const participantIsPremium = async (
      participantId: JournalParticipant["id"],
    ) => {
      return await this.journalStore.participantIsPremium(
        journal,
        participantId,
      );
    };

    const getTransferConfirmationMessage = async (
      participantId: JournalParticipant["id"],
    ) => {
      return (await participantIsPremium(participantId))
        ? TRANSFER_OWNERSHIP_MESSAGE
        : TRANSFER_OWNERSHIP_TRANSFEREE_NON_PREMIUM_MESSAGE;
    };

    return {
      journal,
      isShared,
      isReadOnly,
      ownerId,
      userIsOwner,
      canBeTransferred,
      isBeingTransferred,
      isPendingTransfer,
      participantIsPremium,
      getTransferConfirmationMessage,
      participantIsTransferee,
      userIsParticipant,
      findParticipant,
    };
  }

  // This is a helper function to check if the user can navigate to a journal
  userCanNavigateToJournal(journalId: string) {
    if (!this?.user) {
      return false;
    }
    const journal = this.getJournalById(journalId);
    if (this.user.subscription_status === "premium" || journal?.is_shared) {
      return true;
    }
    return (
      this.journals
        .slice(0, this.user.journalLimit?.limit)
        .findIndex((j) => j.id === journalId) !== -1
    );
  }
}

export type SharedJournalSettings = ReturnType<
  PrimaryViewState["getSharedJournalSettings"]
>;
