import { Button, DropZone } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import { GroupHeading, Item } from "@/components/ItemGroup";
import { ItemGroup } from "@/components/ItemGroup/ItemGroup";
import { LoadingModal } from "@/components/LoadingModal";
import { ResultModal } from "@/components/Settings/Import/ResultModal";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { ScreenReaderOnly } from "@/components/shared/A11yComponents";
import { getSingleJournalURL } from "@/data/URLFunctions";
import { ImportDetails } from "@/data/db/migrations/import_export";
import { ImportJSONResult, importJSONZip } from "@/utils/import";
import {
  modalRouterViewState,
  primaryViewState,
} from "@/view_state/ViewStates";

export const Import: React.FC = () => {
  const [importResult, setImportResult] = useState<
    ImportJSONResult | null | { status: "importing" }
  >(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [importDetails, setImportDetails] = useState<ImportDetails | null>(
    null,
  );
  const { __ } = useI18n();
  const [, setLocation] = useLocation();

  const ref = useRef<HTMLInputElement>(null);
  const shouldCancelExport = useRef(false);

  const handleImport = async () => {
    if (!ref.current) {
      return;
    }
    const file = ref.current.files?.[0];
    if (!file) {
      return;
    }
    const importID = file.name.replace(".zip", "");
    await d1Classes.importExportRepository.deleteImport(importID);
    setImportDetails(null);
    setImportResult({ status: "importing" });
    shouldCancelExport.current = false;
    const result = await importJSONZip(
      file,
      primaryViewState.isEncryptionReady,
      primaryViewState.journals,
      shouldCancelExport,
    );
    setImportResult(result);
    if (result.status === "success") {
      ref.current.value = "";
      ref.current.files = null;
      setFileName(null);
    }
  };

  const handleFileChange = () => {
    if (ref.current?.files?.length) {
      setFileName(ref.current.files[0].name);
    }
  };

  useEffect(() => {
    if (!fileName) {
      return;
    }
    const importID = fileName.replace(".zip", "");
    const unsub = d1Classes.importExportRepository.subscribeToImport(
      importID,
      (result) => {
        if (result) {
          setImportDetails(result.details);
        }
      },
    );
    return () => unsub();
  }, [fileName]);

  const getImportMessage = () => {
    if (!importDetails) {
      return __("Calculating...");
    }
    if (importDetails.mediaProcessed < importDetails.totalMedia) {
      return sprintf(
        __("Importing media %d of %d"),
        importDetails.mediaProcessed || 1,
        importDetails.totalMedia,
      );
    }
    return sprintf(
      __("Importing entry %d of %d"),
      importDetails.entriesProcessed || 1,
      importDetails.totalEntries,
    );
  };

  return (
    <>
      <SettingsPanel header={<span>{__("Import")}</span>}>
        <GroupHeading id="import_json">
          {__("Import JSON Zip File")}
        </GroupHeading>
        <ItemGroup>
          <Item
            sx={{
              flexDirection: "column",
              "& p, & div": { width: "100%", mb: 2, lineHeight: "body" },
              "& input": { width: "100%" },
            }}
          >
            <div>
              <ScreenReaderOnly>{__("Browse for a file")}</ScreenReaderOnly>
              <label
                htmlFor="import_json_file"
                sx={{
                  fontSize: 2,
                  fontWeight: "medium",
                  lineHeight: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  color: "dayOneBlue",
                  mt: 3,
                  cursor: "pointer",
                  border: "1px solid",
                  borderColor: "borderPrimary",
                  borderRadius: "md",
                  p: 3,
                  position: "relative",
                }}
              >
                <DropZone
                  onFilesDrop={(files) => {
                    if (ref.current && files.length > 0) {
                      const file = files[0];
                      if (file.type === "application/zip") {
                        const dataTransfer = new DataTransfer();
                        dataTransfer.items.add(file);
                        ref.current.files = dataTransfer.files;
                        handleFileChange();
                      }
                    }
                  }}
                />
                <p sx={{ "&&": { mb: 0 } }}>
                  {__("Browse for a file")}
                  <span
                    sx={{
                      fontSize: 1,
                      display: "block",
                      fontStyle: "italic",
                      color: "textPrimary",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {__("File: ")}
                    {ref.current?.files?.length
                      ? fileName
                      : __("No file selected")}
                  </span>
                </p>
                <input
                  ref={ref}
                  type="file"
                  id="import_json_file"
                  name="fileInput"
                  accept="application/zip"
                  onChange={handleFileChange}
                  sx={{ display: "none" }}
                />
              </label>

              <Button
                sx={{ mt: 3 }}
                variant="primary"
                onClick={handleImport}
                disabled={!fileName || importResult?.status === "importing"}
              >
                {__("Import")}
              </Button>
            </div>
          </Item>
        </ItemGroup>
      </SettingsPanel>
      {importResult && importResult.status === "importing" && (
        <LoadingModal
          title={__("Importing")}
          handleClose={() => {}}
          message={getImportMessage()}
          progressType="bar"
          action={() => {
            shouldCancelExport.current = true;
          }}
          actionLabel={__("Cancel Import")}
        />
      )}
      {importResult && importResult.status === "cancelled" && (
        <ResultModal
          title={__("Import Cancelled")}
          handleClose={() => setImportResult(null)}
        >
          <p>{__("Import was cancelled")}</p>
        </ResultModal>
      )}
      {importResult && importResult.status === "success" && (
        <ResultModal
          title={__("Import Success")}
          handleClose={() => setImportResult(null)}
          successButton={
            importResult.journalId && (
              <Button
                variant="primary"
                onClick={() => {
                  const url = importResult.journalId
                    ? getSingleJournalURL(
                        importResult.journalId,
                        primaryViewState.selectedEntryView,
                      )
                    : null;
                  if (url) {
                    setImportResult(null);
                    setFileName(null);
                    modalRouterViewState.close();
                    setLocation(url);
                  }
                }}
              >
                {__("Open Journal")}
              </Button>
            )
          }
        >
          <p>{__("Imported successfully")}</p>
        </ResultModal>
      )}
      {importResult && importResult.status === "warning" && (
        <ResultModal
          title={__("Some entries did not import")}
          handleClose={() => setImportResult(null)}
        >
          {importResult.error?.details && (
            <>
              <p sx={{ lineHeight: "body" }}>
                {__(
                  "The following entries in the export file failed to import:",
                )}
              </p>
              <ul sx={{ lineHeight: "body", listStyle: "disc", ml: 3, mt: 3 }}>
                {importResult.error.details.map((detail) => (
                  <li key={detail}>{detail}</li>
                ))}
              </ul>
            </>
          )}
        </ResultModal>
      )}
      {importResult && importResult.status === "error" && (
        <ResultModal
          title={__("An error occured while importing")}
          handleClose={() => setImportResult(null)}
        >
          {importResult.error?.type === "JOURNAL_SAVE_FAILED" && (
            <p>{__("Failed to create a new journal")}</p>
          )}
          {importResult.error?.type === "INVALID_ZIP_FILE" && (
            <p>{__("Unable to process the provided file.")}</p>
          )}
          {importResult.error?.type === "JSON_FILE_NOT_FOUND" && (
            <p>{__("Unable to find a JSON file.")}</p>
          )}
          {importResult.error?.type === "INVALID_JSON" && (
            <p>{__("Unable to read the contents of the file.")}</p>
          )}
        </ResultModal>
      )}
    </>
  );
};
