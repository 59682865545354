import {
  Button,
  CheckboxControl,
  Flex,
  FlexBlock,
  FlexItem,
  TextControl,
} from "@wordpress/components";
import {
  calendar,
  media,
  audio,
  comment,
  addTemplate,
  desktop,
  shadow,
  mapMarker,
  search,
} from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { Icon } from "@/components/Icon";
import { DateFilter } from "@/components/Search/DateFilter";
import { JournalFilter } from "@/components/Search/JournalFilter";
import { MultipleOptionsFilter } from "@/components/Search/MultipleOptionsFilter";
import { TagFilter } from "@/components/Search/TagFilter";
import { Book } from "@/components/Search/icons/Book";
import { Tag } from "@/components/Search/icons/Tag";
import { Label } from "@/components/Settings/Label";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { getActivityIconName } from "@/utils/icons";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  onClose: () => void;
};

export const AdvancedFilterModal: React.FC<Props> = observer(({ onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { __ } = useI18n();
  const { isMobile } = useDevice();

  const { search: searchViewState } = viewStates;
  const { journalStore } = d1Classes;

  const [keyword, setKeyword] = useState(searchViewState.searchString);

  const handleSearchSubmit = () => {
    searchViewState.setSearchString(keyword);
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "searchFilter_search_submit",
    });
    onClose();
  };

  return (
    <D1Modal
      ref={modalRef}
      sx={{
        bg: "surface_light1_dark3",
        marginTop: isMobile && 0,
        borderRadius: isMobile && 0,
        width: ["100dvw", "100dvw", "420px"],
        height: ["fill-available", "fill-available", "600px"],
        overflow: "hidden",
        "& .components-modal__content": {
          p: 0,
        },
        "& .components-modal__content > div:last-of-type": {
          overflow: "auto",
          height: "100%",
        },
      }}
      onRequestClose={onClose}
      title="Advanced Search"
      shouldCloseOnEsc
    >
      <div
        sx={{
          height: `calc(100% - 84px)`, // ~ the height of the content minus the bottom bar
          overflow: searchViewState.filterDropdownOpen ? "hidden" : "scroll",
          p: 4,
          gap: 4,
          "& .components-dropdown": {
            width: "100%",
          },
          "& .components-text-control__input, .components-dropdown button": {
            border: "1px solid",
            borderColor: "borderPrimary",
            height: "36px",
            px: 3,

            "& svg  ": {
              m: 0,
            },
          },

          "& .advanced-filter-label": {
            display: "flex",
            gap: 2,
            mb: 2,
            alignItems: "center",
            "& label": {
              pb: 0,
            },
            "& svg": {
              fill: "currentColor",
              width: "16px",
              height: "16px",
            },
          },

          "& .filter-pill": {
            color: "textPrimary",

            "&:hover": {
              color: "textPrimary",
            },

            "&.active": {
              color: "textOnColor",

              "&:hover": {
                color: "textPrimaryInverse",
              },
            },
          },
        }}
      >
        <AdvancedFilterItem icon={search} label={__("Keyword")}>
          <TextControl
            id="keyword"
            name="keyword"
            onChange={(value: string) => {
              setKeyword(value);
            }}
            value={keyword}
            autoFocus
            __nextHasNoMarginBottom
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={Book} label={__("Journal")}>
          <JournalFilter
            searchViewState={searchViewState}
            journalStore={journalStore}
            journals={viewStates.primary.visibleSyncableJournals}
            isInModal
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={Tag} label={__("Tags")}>
          <TagFilter searchViewState={searchViewState} isInModal />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={calendar} label={__("Date")}>
          <DateFilter searchViewState={searchViewState} isInModal />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={mapMarker} label={__("Place")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "place",
              options: searchViewState.placeNames,
            }}
            selectedOptions={searchViewState.filter.placeNames}
            noOptionsText={__("No places found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={media} label={__("Media")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "media",
              options: searchViewState.media,
            }}
            selectedOptions={searchViewState.filter.media}
            noOptionsText={__("No Media found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={comment} label={__("Daily Prompt")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "prompt",
              options: searchViewState.prompts,
            }}
            selectedOptions={searchViewState.filter.prompts}
            noOptionsText={__("No prompts found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={addTemplate} label={__("Template")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "template",
              options: searchViewState.templates,
            }}
            selectedOptions={searchViewState.filter.templates}
            noOptionsText={__("No templates found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={shadow} label={__("Weather")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "weather",
              options: searchViewState.weather,
            }}
            selectedOptions={searchViewState.filter.weather}
            noOptionsText={__("No weather information found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem icon={desktop} label={__("Entry Creation Device")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "creationDevice",
              options: searchViewState.creationDevices,
            }}
            selectedOptions={searchViewState.filter.creationDevices}
            noOptionsText={__("No creation devices found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem
          icon={
            <Icon
              icon={getActivityIconName("Walking")}
              sx={{ height: 3 }}
              iconStyles={{
                fontSize: 2,
              }}
            />
          }
          label={__("Activity")}
        >
          <MultipleOptionsFilter
            filterOptions={{
              type: "activity",
              options: searchViewState.activities,
            }}
            selectedOptions={searchViewState.filter.activities}
            noOptionsText={__("No activities found")}
          />
        </AdvancedFilterItem>

        <AdvancedFilterItem icon={audio} label={__("Music")}>
          <MultipleOptionsFilter
            filterOptions={{
              type: "music",
              options: searchViewState.music,
            }}
            selectedOptions={searchViewState.filter.music}
            noOptionsText={__("No music found")}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem>
          <CheckboxControl
            __nextHasNoMarginBottom
            label={__("Contains checklist")}
            checked={searchViewState.filter.hasCheckbox || false}
            onChange={(value) => {
              searchViewState.setFilter({
                hasCheckbox: value,
              });
              analytics.tracks.recordEvent(
                value ? EVENT.searchFilterApplied : EVENT.searchFilterRemoved,
                {
                  filter_type: "checklist",
                },
              );
            }}
          />
        </AdvancedFilterItem>
        <AdvancedFilterItem>
          <CheckboxControl
            __nextHasNoMarginBottom
            label={__("Favorites")}
            checked={searchViewState.filter.isStarred || false}
            onChange={(value) => {
              searchViewState.setFilter({
                isStarred: value,
              });
              analytics.tracks.recordEvent(
                value ? EVENT.searchFilterApplied : EVENT.searchFilterRemoved,
                {
                  filter_type: "favorites",
                },
              );
            }}
          />
        </AdvancedFilterItem>

        <Flex
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            justifyContent: "flex-end",
            p: 4,
            borderTop: "1px solid",
            borderColor: "borderPrimary",
            backgroundColor: "surface_light1_dark3",
            height: "84px",
          }}
        >
          <Button
            variant="secondary"
            onClick={() => {
              analytics.tracks.recordEvent(EVENT.buttonTap, {
                button_identifier: "searchFilter_search_cancel",
              });
              onClose();
            }}
          >
            {__("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleSearchSubmit}>
            {__("Search")}
          </Button>
        </Flex>
      </div>
    </D1Modal>
  );
});

interface AdvancedFilterItemProps {
  icon?: JSX.Element | (() => JSX.Element);
  label?: string;
  children: React.ReactNode;
}

export const AdvancedFilterItem: React.FC<AdvancedFilterItemProps> = ({
  icon,
  label,
  children,
}) => {
  return (
    <FlexBlock sx={{ mb: 3 }}>
      {(label || icon) && (
        <FlexItem className="advanced-filter-label">
          {icon && (typeof icon === "function" ? icon() : icon)}
          {label && <Label>{label}</Label>}
        </FlexItem>
      )}
      <FlexItem>{children}</FlexItem>
    </FlexBlock>
  );
};
