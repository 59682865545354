import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Dropdown } from "@/components/D1Dropdown";
import {
  HEADING_BLOCK_ID,
  QUOTE_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { headingBase as HeadingBase } from "@/components/Editor/icons/headingBase";
import {
  HeadingTransform,
  getHeadingIconByLevel,
} from "@/components/Editor/transforms/HeadingTransform";
import { useListTransform } from "@/components/Editor/transforms/useListTransform";
import { allBlocksCanBeTransformed } from "@/components/Editor/transforms/utils";
import { useReplaceSelectedBlocks } from "@/components/Editor/utils/replace-selected-blocks";
import { FilledArrow } from "@/components/icons/FilledArrow";
import { toolbarViewState } from "@/view_state/ViewStates";

export const HeadingDropdown: React.FC = observer(() => {
  const { __ } = useI18n();
  const { blocks } = useReplaceSelectedBlocks();
  const { parentBlock } = useListTransform();
  const isDisabled =
    !allBlocksCanBeTransformed(blocks) ||
    (parentBlock && parentBlock.name === QUOTE_BLOCK_ID);

  const getToggleButtonIcon = () => {
    // Use basic heading icon for zero or multiple block selection
    if (blocks.length === 0 || blocks.length > 1) {
      return <HeadingBase />;
    }

    const block = blocks[0];

    if (block.name !== HEADING_BLOCK_ID) {
      return <HeadingBase />;
    }

    const HeadingIconByLevel = getHeadingIconByLevel(block.attributes.level);

    return <HeadingIconByLevel />;
  };

  return (
    <D1Dropdown
      focusOnMount
      popoverProps={{ placement: "bottom-start", variant: "primary" }}
      onToggle={(willOpen) =>
        toolbarViewState.setHeadingTransformDropdownOpen(willOpen)
      }
      renderToggle={({ isOpen, onToggle }) => (
        <FixedToolbarButton
          children={<FilledArrow />}
          isActive={false}
          icon={getToggleButtonIcon()}
          disabled={isDisabled}
          onClick={onToggle}
          title={__("Heading")}
          aria-expanded={isOpen}
        />
      )}
      renderContent={() => (
        <div sx={{ "&& button": { mr: "-2" } }}>
          <HeadingTransform isContextMenu={false} headingLevel={1} />
          <HeadingTransform isContextMenu={false} headingLevel={2} />
          <HeadingTransform isContextMenu={false} headingLevel={3} />
          <HeadingTransform isContextMenu={false} headingLevel={4} />
          <HeadingTransform isContextMenu={false} headingLevel={5} />
          <HeadingTransform isContextMenu={false} headingLevel={6} />
        </div>
      )}
    />
  );
});

HeadingDropdown.displayName = "HeadingDropdown";
