import { CreateNewEntryOpts } from "@/data/repositories/EntryRepository";

export const hasPrefilledContent = (opts?: CreateNewEntryOpts) => {
  if (
    !opts ||
    ((opts?.prefill?.richTextJson?.length ?? 0) === 0 &&
      (opts?.prefill?.tags?.length ?? 0) === 0)
  ) {
    return true;
  }
  return false;
};

export const moonPhases = [
  "first-quarter",
  "full",
  "last-quarter",
  "new",
  "waning-crescent",
  "waning-gibbous",
  "waxing-crescent",
  "waxing-gibbous",
] as const;

export const weatherCodes = [
  "clear",
  "clear-night",
  "cloudy",
  "cloudy-night",
  "dust",
  "fair",
  "drizzle",
  "flurries",
  "fog",
  "fog-night",
  "freezing-rain",
  "frigid",
  "hail",
  "hazy",
  "hazy-night",
  "heavy-rain",
  "hurricane",
  "lightning",
  "mostly-cloudy",
  "mostly-cloudy-night",
  "partly-cloudy",
  "rain",
  "rain-snow",
  "rain-snow-night",
  "rain-night",
  "rain-wind",
  "showers",
  "sleet",
  "sleet-night",
  "sleet-snow",
  "smoke",
  "snow",
  "snow-night",
  "snow-showers",
  "snow-wind",
  "sunny",
  "tornado",
  "thunderstorm",
  "thunderstorm-night",
  "tropical-storm",
  "wind",
  "undefined",
] as const;
