import {
  NotificationIcon,
  IconColors,
} from "@/components/Notifications/NotificationIcon";

export const CommentIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon="comment-filled"
      color={IconColors.blue.color}
      bgColor={IconColors.blue.bgColor}
    />
  );
};
