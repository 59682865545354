import { MenuButton } from "@/components/Editor/components/MenuButton";
import { Icon, IconFont } from "@/components/Icon";
import { CategoryWithTemplates } from "@/components/Templates";
import { PBCTemplate, SelectedTemplate } from "@/data/db/migrations/template";

type CategoryProps = {
  handleClick: (selected: SelectedTemplate) => void;
  activeTemplate: PBCTemplate;
  search: string | null;
  category: CategoryWithTemplates;
};

export const TemplateGalleryCategoryList: React.FC<CategoryProps> = ({
  category,
  handleClick,
  activeTemplate,
  search,
}) => {
  const filtered =
    search === null
      ? category.templates
      : category.templates.filter((t) =>
          t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        );
  if (search && filtered.length === 0) {
    return null;
  }

  return (
    <div
      sx={{
        "& .is-active": {
          bg: "surfaceActive",
          color: "textPrimary",
        },
      }}
    >
      <h2
        sx={{
          textTransform: "uppercase",
          fontSize: 1,
          color: "textPrimary",
          mt: 2,
          mb: 3,
          px: 2,
        }}
      >
        {category.name}
      </h2>
      <p
        sx={{
          color: "textSecondary",
          lineHeight: 2,
          fontSize: 0,
          mb: 2,
          px: 2,
        }}
      >
        {category.description}
      </p>
      {filtered.map((template) => {
        const bgImage = template.icon_background_image ?? "";

        return (
          <MenuButton
            key={template.id}
            onClick={() => {
              handleClick({ ...template, type: "gallery" });
            }}
            isActive={template.id === activeTemplate?.id}
          >
            <span
              sx={{
                flexShrink: 0,
                display: "inline-block",
                height: "24px",
                width: "24px",
                backgroundColor: template.color,
                backgroundImage: `url("${bgImage}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "sm",
                mr: 2,
                "& img": {
                  mt: "5px",
                  width: "14px",
                  height: "14px",
                  filter: "invert(1)",
                },
              }}
            >
              {!template.symbol ? (
                <img src={template.icon_url ?? ""} />
              ) : (
                <Icon
                  icon={template.symbol as IconFont}
                  iconStyles={{
                    display: "inline-block",
                    color: "textPrimaryInverse",
                    mt: "5px",
                    fontSize: 1,
                  }}
                />
              )}
            </span>
            <span sx={{ textAlign: "left", fontSize: 1 }}>{template.name}</span>
          </MenuButton>
        );
      })}
    </div>
  );
};
