import {
  store as blockEditorStore,
  RichText,
  useBlockProps,
} from "@wordpress/block-editor";
import {
  BlockEditProps,
  createBlock,
  registerBlockType,
} from "@wordpress/blocks";
import { CheckboxControl } from "@wordpress/components";
import { useMergeRefs } from "@wordpress/compose";
import { useDispatch } from "@wordpress/data";
import { nanoid } from "nanoid";
import { useRef } from "react";

import useArrowNav from "@/components/Editor/blocks/checklist/useArrowNav";
import useEnter from "@/components/Editor/blocks/checklist/useEnter";
import useMerge from "@/components/Editor/blocks/checklist/useMerge";
import useSpace from "@/components/Editor/blocks/checklist/useSpace";
import {
  CHECKLIST_ITEM_BLOCK_ID,
  HEADING_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";

export type CheckListItem = {
  checked: boolean;
  content: string;
  identifier: string;
  indent: number;
};

const MATCH_OUTER_BR = /^(<br\s*\/?>)*|(<br\s*\/?>)*$/i;

export const CheckListItemBlock: React.FC<BlockEditProps<CheckListItem>> = ({
  attributes,
  clientId,
  setAttributes,
}) => {
  const updateCheckboxState = (itemState: Partial<CheckListItem>) => {
    setAttributes({ ...attributes, ...itemState });
  };

  const { selectionChange } = useDispatch(blockEditorStore);

  const blockProps = useBlockProps();
  const textRef = useRef<HTMLDivElement>(null);
  const useEnterRef = useEnter({
    clientId,
    content: attributes.content,
    indent: attributes.indent,
  });
  const useSpaceRef = useSpace(clientId);
  const useArrowNavRef = useArrowNav(clientId);
  const onMerge = useMerge(clientId);

  const handleFocus = () => {
    const sel = window.getSelection();
    const selOffset = sel?.focusOffset ?? 0;
    selectionChange(clientId, "content", selOffset, selOffset);
  };

  return (
    <div
      {...blockProps}
      className={`${blockProps.className} ${attributes.checked ? "is-checked" : ""}`}
      onFocus={handleFocus}
    >
      <CheckboxControl
        // Indent levels start at 1 and the first one shouldn't have any margin
        sx={{ marginLeft: `${1.5 * (attributes.indent - 1)}rem` }}
        checked={attributes.checked}
        onChange={(value) => updateCheckboxState({ checked: value })}
        onFocus={handleFocus}
      />
      <RichText
        ref={useMergeRefs([textRef, useEnterRef, useSpaceRef, useArrowNavRef])}
        identifier="content"
        value={attributes.content}
        onMerge={onMerge}
        onChange={(value) => {
          updateCheckboxState({
            content: value,
          });
        }}
        tagName="span"
      />
    </div>
  );
};

export const emptyChecklistItem = {
  indent: 1,
  content: "",
  checked: false,
  identifier: nanoid(),
};

const transform = (content: string, checked = false) => {
  return createBlock(CHECKLIST_ITEM_BLOCK_ID, {
    ...emptyChecklistItem,
    checked: checked,
    // the content passed to the transform begins with a "start of selection" unicode char
    content: content.charCodeAt(0) === 134 ? content.slice(1) : content,
  });
};

export const register = () => {
  if (!blockIsRegistered(CHECKLIST_ITEM_BLOCK_ID)) {
    // @ts-ignore - the types for this are not robust, but it will be tough to make them better.
    registerBlockType(CHECKLIST_ITEM_BLOCK_ID, {
      apiVersion: 2,
      title: "ChecklistItem",
      name: CHECKLIST_ITEM_BLOCK_ID,
      edit: CheckListItemBlock,
      merge: (attributes, attributesToMerge) => {
        return {
          content:
            (attributes.content || "") + (attributesToMerge.content || ""),
        };
      },
      category: "media",
      textdomain: "default",
      description: "Day One Checklist Block",
      style: "checklist-item",
      attributes: {
        indent: {
          type: "number",
          default: 1,
        },
        content: {
          type: "string",
        },
        checked: {
          type: "boolean",
        },
        identifier: {
          type: "string",
        },
      },
      transforms: {
        to: [
          {
            type: "block",
            isMultiBlock: true,
            blocks: [PARAGRAPH_BLOCK_ID],
            // For some reason the type system thinks we should get a record in here,
            // but _attributes is actually an array at runtime.
            transform: (_attributes: [CheckListItem]) => {
              return _attributes.map((attributes) => {
                const { content } = attributes;
                const newBlock = createBlock(PARAGRAPH_BLOCK_ID, { content });
                return newBlock;
              });
            },
          },
        ],
        from: [
          {
            type: "block",
            isMultiBlock: true,
            blocks: [PARAGRAPH_BLOCK_ID, HEADING_BLOCK_ID],
            // For some reason the type system thinks we should get a record in here,
            // but _attributes is actually an array at runtime.
            transform: (_attributes: [CheckListItem]) => {
              return _attributes.map((attributes) =>
                createBlock(CHECKLIST_ITEM_BLOCK_ID, {
                  ...emptyChecklistItem,
                  content: attributes.content.replace(MATCH_OUTER_BR, ""),
                }),
              );
            },
          },
          {
            type: "prefix",
            prefix: "[ ]",
            transform: transform,
          },
          {
            type: "prefix",
            prefix: "[]",
            transform: transform,
          },
          {
            type: "prefix",
            prefix: "-[]",
            transform: transform,
          },
          {
            type: "prefix",
            prefix: "[x]",
            transform: (content) => transform(content, true),
          },
          {
            type: "prefix",
            prefix: "-[x]",
            transform: (content) => transform(content, true),
          },
        ],
      },
    });
  }
};
