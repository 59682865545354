import {
  NotificationIcon,
  IconColors,
} from "@/components/Notifications/NotificationIcon";

export const DeleteIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon="trash"
      color={IconColors.red.color}
      bgColor={IconColors.red.bgColor}
    />
  );
};
