import {
  NotificationIcon,
  IconColors,
} from "@/components/Notifications/NotificationIcon";

export const EntryIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon="pen"
      color={IconColors.blue.color}
      bgColor={IconColors.blue.bgColor}
    />
  );
};
