import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { Icon } from "@/components/Icon";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { WPConnectNewBlogForm } from "@/components/Settings/WPConnect/WPConnectNewBlogForm";
import { WPConnectedBlogList } from "@/components/Settings/WPConnect/WPConnectedBlogList";
import { WPConnectViewState } from "@/view_state/WPConnect_ViewState";

type Props = {
  viewState: WPConnectViewState;
};

export const WPConnectSettings: React.FC<Props> = observer(({ viewState }) => {
  const { __ } = useI18n();
  useEffect(() => {
    viewState.fetchConnectedBlogs();
  });

  return (
    <SettingsPanel
      header={
        <span
          sx={{
            "&&": { display: "inline-flex", alignItems: "normal", gap: 2 },
          }}
        >
          <Icon icon="experiments" iconStyles={{ fontSize: 18 }} />
          <div>{__("WordPress Connect Settings")}</div>
        </span>
      }
    >
      <h1 sx={{ mt: 4, mb: -2 }}>{__("Connected Blogs")}</h1>
      <WPConnectedBlogList showDisconnectButton={true} viewState={viewState} />

      <div sx={{ mt: 4 }}>
        <WPConnectNewBlogForm connectNewBlog={viewState.connectNewBlog} />
      </div>
    </SettingsPanel>
  );
});

WPConnectSettings.displayName = "WPConnectSettings";
