import { NotificationIcon } from "@/components/Notifications/NotificationIcon";
import { IconColors } from "@/components/Notifications/NotificationIcon";
type Props = {
  type: "offer" | "complete";
};

const color = {
  offer: IconColors.blue.color,
  complete: IconColors.green.color,
};
const backgroundColor = {
  offer: IconColors.blue.bgColor,
  complete: IconColors.green.bgColor,
};

export const TransferIcon: React.FC<Props> = ({ type }) => {
  return (
    <NotificationIcon
      icon="arrows-left-right"
      color={color[type]}
      bgColor={backgroundColor[type]}
    />
  );
};
