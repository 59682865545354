import { Button, Spinner } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useRef, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { LoadingModal } from "@/components/LoadingModal";
import { ExportDBRow } from "@/data/db/migrations/import_export";
import { MediaDownloadResults } from "@/data/db/migrations/moment";
import { formatBytes } from "@/data/utils/moments/media";
import { useExport } from "@/hooks/useExport";
import { useShowHideModal } from "@/hooks/useShowHideModal";
import { MediaDownloadStatus } from "@/utils/export";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  journalId: string;
  handleClose(): void;
  initialMediaDownloadStatus: MediaDownloadStatus;
  existingExport: ExportDBRow | null;
};

export const DownloadMedia: React.FC<Props> = ({
  journalId,
  handleClose,
  initialMediaDownloadStatus,
  existingExport,
}) => {
  const { __, _n } = useI18n();
  const [mediaDownloadStatus, setMediaDownloadStatus] =
    useState<MediaDownloadStatus>(initialMediaDownloadStatus);

  const { generateZip, cancel, setCancel } = useExport();

  const [failedMedia, setFailedMedia] = useState<
    MediaDownloadResults["details"][]
  >([]);

  const { showModal, hideModal, shouldShowModal } = useShowHideModal();
  const shouldCancelMedia = useRef(false);

  const journal = primaryViewState.getJournalById(journalId);

  const [clickedDownload, setClickedDownload] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState<{
    downloadedMediaSize: number;
    totalMediaSize: number;
  } | null>(null);
  const [loading, setLoading] = useState(true);

  const updateDownloadProgress = async (downloaded: number) => {
    const existing = await d1Classes.importExportRepository.getExport(
      `journal-${journalId}`,
    );
    if (!existing) {
      return;
    }
    existing.details.downloadedMediaSize += downloaded;
    if (!requestedCancelMediaDownload()) {
      d1Classes.importExportRepository.setExport(
        `journal-${journalId}`,
        existing.details,
      );
    }
  };

  useEffect(() => {
    const initialSetup = async () => {
      const initialMediaInfo =
        await d1Classes.journalStore.getMediaSizeInfoByJournal(journalId);
      setDownloadProgress({
        downloadedMediaSize: initialMediaInfo.downloadedMediaSize,
        totalMediaSize: initialMediaInfo.totalMediaSize,
      });
      setLoading(false);
    };

    initialSetup();
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }
    const unsub = d1Classes.importExportRepository.subscribeToExport(
      `journal-${journalId}`,
      (exportDetails) => {
        if (exportDetails) {
          setDownloadProgress({
            downloadedMediaSize: exportDetails.details.downloadedMediaSize,
            totalMediaSize: exportDetails.details.totalMediaSize,
          });
        }
      },
    );
    return () => unsub();
  }, [loading]);

  useEffect(() => {
    if (existingExport && existingExport.details.includeMedia) {
      handleDownloadClick();
    }
  }, []);

  const showDownloadButton =
    mediaDownloadStatus === "NOT_STARTED" ||
    mediaDownloadStatus === "DOWNLOADING" ||
    mediaDownloadStatus === "CANCELLED" ||
    mediaDownloadStatus === "SOME_MISSING";

  const isDownloading = mediaDownloadStatus === "DOWNLOADING";

  const showDownloadingModal = isDownloading || clickedDownload;

  const exportFinished = () => {
    handleClose();
    hideModal();
    viewStates.snackbar.newMessage(__("Journal exported successfully"));
    d1Classes.importExportRepository.deleteExport(`journal-${journalId}`);
  };

  const cancelExport = () => {
    handleClose();
    hideModal();
    viewStates.snackbar.newMessage(__("Export cancelled"));
    d1Classes.importExportRepository.deleteExport(`journal-${journalId}`);
  };

  const modalTitle = showDownloadButton
    ? __("Download Media?")
    : __("Continue Export");

  const requestedCancelMediaDownload = () => {
    return shouldCancelMedia.current;
  };

  const getDownloadButtonText = () => {
    if (isDownloading) {
      return (
        <>
          <Spinner /> {__("Downloading")}
        </>
      );
    } else if (mediaDownloadStatus === "CANCELLED") {
      return __("Continue downloading");
    } else if (
      mediaDownloadStatus === "SOME_MISSING" &&
      failedMedia.length > 0
    ) {
      return __("Try downloading again");
    } else {
      return __("Download now");
    }
  };

  const getDownloadModalMessage = () => {
    if (!isDownloading && mediaDownloadStatus !== "CANCELLED") {
      return __("Finished downloading");
    } else if (!isDownloading && mediaDownloadStatus === "CANCELLED") {
      return __("Download cancelled");
    } else if (loading) {
      return __("Calculating size of media");
    } else {
      return sprintf(
        __("Downloaded %s of %s"),
        formatBytes(downloadProgress?.downloadedMediaSize ?? 0),
        formatBytes(downloadProgress?.totalMediaSize ?? 0),
      );
    }
  };

  const handleDownloadClick = async () => {
    setMediaDownloadStatus("DOWNLOADING");
    setClickedDownload(true);
    shouldCancelMedia.current = false;
    const results = await d1Classes.journalStore.downloadAllJournalMedia(
      journalId,
      requestedCancelMediaDownload,
      updateDownloadProgress,
    );
    if (results.cancelled) {
      setMediaDownloadStatus("CANCELLED");
    } else if (results.allSuccess) {
      setMediaDownloadStatus("HAVE_ALL");
    } else if (results.failed) {
      setMediaDownloadStatus("SOME_MISSING");
      setFailedMedia(results.failed);
    }
  };

  const handleCancelMediaDownloadClick = async () => {
    shouldCancelMedia.current = true;
    setMediaDownloadStatus("CANCELLED");
    const existing = await d1Classes.importExportRepository.getExport(
      `journal-${journalId}`,
    );
    if (!existing) {
      return;
    }
    existing.details.includeMedia = undefined;
    d1Classes.importExportRepository.setExport(
      `journal-${journalId}`,
      existing.details,
    );
  };

  return (
    <>
      <D1Modal title={modalTitle} onRequestClose={handleClose}>
        <div
          sx={{
            display: "flex",
            maxWidth: "420px",
            flexDirection: "column",
            gap: 2,
            p: {
              lineHeight: "body",
            },
          }}
        >
          {showDownloadButton && (
            <>
              <p>
                {__(
                  "Some selected photos, videos, pdfs or audios are not currently stored on this device. Would you like to download them before continuing?",
                )}
              </p>
              <Button
                sx={{
                  "&& svg": {
                    mt: 0,
                  },
                }}
                variant="primary"
                disabled={isDownloading}
                onClick={() => {
                  handleDownloadClick();
                }}
              >
                {getDownloadButtonText()}
              </Button>
            </>
          )}
          <Button
            disabled={isDownloading}
            variant="primary"
            onClick={async () => {
              if (!journal) {
                return;
              }
              const cancelFn = await generateZip(
                journal,
                exportFinished,
                showModal,
                cancelExport,
                true,
                failedMedia,
              );
              setCancel({ fn: cancelFn });
            }}
          >
            {__("Continue export")}
          </Button>
          {failedMedia.length > 0 && (
            <p>
              {sprintf(
                _n(
                  "%d media file was not able to be included in the export. Details will be in the export file.",
                  "%d media files were not able to be included in the export. Details will be in the export file.",
                  failedMedia.length,
                ),
                failedMedia.length,
              )}
            </p>
          )}
        </div>
      </D1Modal>
      {shouldShowModal && (
        <LoadingModal
          title={__("Exporting Journal")}
          handleClose={hideModal}
          action={cancel?.fn}
          actionLabel={__("Cancel Export")}
        />
      )}
      {showDownloadingModal && (
        <LoadingModal
          title={__("Downloading Media")}
          handleClose={() => {}}
          action={() => {
            if (isDownloading) {
              handleCancelMediaDownloadClick();
            } else {
              setClickedDownload(false);
            }
          }}
          actionLabel={isDownloading ? __("Cancel Download") : __("Close")}
          showProgress={isDownloading}
          message={getDownloadModalMessage()}
          progressType="bar"
        />
      )}
    </>
  );
};
