import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";
import { useI18n } from "@wordpress/react-i18n";

import {
  ACTIVITY_BLOCK_ID,
  MotionActivityBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";

export const MotionActivityBlock: React.FC<MotionActivityBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const { __ } = useI18n();
  const title = `${attributes.steps.toLocaleString("en-US")} ${__("steps")}`;
  return (
    <SuggestionWrapper
      title={title}
      body={attributes.movementTypeName}
      fallBackIconName="embed-activity"
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(ACTIVITY_BLOCK_ID)) {
    registerBlockType(ACTIVITY_BLOCK_ID, {
      edit: (props: MotionActivityBlockEditProps) => {
        return <MotionActivityBlock {...props} />;
      },
      title: "Motion Activity",
      category: "media",
      textdomain: "default",
      description: "Day One Motion Activity Block",
      attributes: {
        type: {
          type: "string",
        },
        identifier: {
          type: "string",
        },
        startDate: {
          type: "string",
        },
        endDate: {
          type: "string",
        },
        iconIdentifier: {
          type: "string",
        },
        steps: {
          type: "number",
        },
        source: {
          type: "string",
        },
        movementType: {
          type: "string",
        },
        movementTypeName: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(ACTIVITY_BLOCK_ID)) {
    return unregisterBlockType(ACTIVITY_BLOCK_ID);
  }
  return false;
};
