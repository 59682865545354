import { store as blockEditorStore } from "@wordpress/block-editor";
import { DropZone } from "@wordpress/components";
import { useSelect } from "@wordpress/data";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  DRAGGABLE_MEDIA_TRANSFER_DATA_TYPE,
  DraggableMediaTransferDataType,
} from "@/components/Editor/components/DraggableMediaButton";
import { FileUploadMessagesModal } from "@/components/Editor/components/FileUploadMessagesModal";
import { useMediaUpload } from "@/components/Editor/hooks/mediaUpload";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { useGalleryMedia } from "@/hooks/useGalleryMedia";
import { Z_INDEX_GALLERY_MEDIA_DROP_ZONE } from "@/styles/theme";
import { viewStates } from "@/view_state/ViewStates";

export const GalleryMediaDropZone: React.FC<{
  blockId: string;
  children: React.ReactNode;
  globalEntryID: null | GlobalEntryID;
  isEntryShared?: boolean;
}> = observer(({ blockId, children, globalEntryID, isEntryShared }) => {
  const [isActive, setIsActive] = useState(false);
  const [hoverSide, setHoverSide] = useState<"left" | "right">("left");

  const { handleDroppedFiles, filesMessage, resetFilesMessage } =
    useMediaUpload(globalEntryID, viewStates, isEntryShared);

  const { getBlockIndex, getBlockRootClientId } = useSelect(
    blockEditorStore,
    [],
  );
  const _rootClientId = getBlockRootClientId(blockId);

  const { moveMediaToPosition, createGalleryFromBlocks } = useGalleryMedia();

  const getDropBlockIndex = (
    dropzoneBlockId: string,
    dragBlockId?: string,
    hoverSide?: "left" | "right",
  ) => {
    let blockIndex = getBlockIndex(dropzoneBlockId);
    const draggedBlockIndex = dragBlockId
      ? getBlockIndex(dragBlockId)
      : undefined;

    if (hoverSide === "right") {
      if (draggedBlockIndex === undefined) {
        return blockIndex + 1;
      }
      if (blockIndex + 1 === draggedBlockIndex) {
        return;
      }
      if (blockIndex < draggedBlockIndex) {
        blockIndex = blockIndex + 1;
      }
    }
    if (draggedBlockIndex !== undefined && hoverSide === "left") {
      if (blockIndex - 1 === draggedBlockIndex) {
        return;
      }
      if (blockIndex > draggedBlockIndex) {
        blockIndex = blockIndex - 1;
      }
    }
    return blockIndex;
  };

  return (
    <div
      className={`droppable-image-zone${isActive ? "-active" : ""}`}
      data-drop-block-id={blockId}
      sx={{
        position: "relative",
        zIndex: Z_INDEX_GALLERY_MEDIA_DROP_ZONE,
        // add a shadow to the side that the image is being dragged over
        "&.droppable-image-zone-active .wp-block::before": {
          content: '""',
          position: "absolute",
          left: hoverSide === "left" ? "-3px" : "unset",
          right: hoverSide === "right" ? "-3px" : "unset",
          width: "5px",
          top: "1px",
          height: "calc(100% - 2px)",
          zIndex: 1,
          boxShadow: "focusInnerLg",
        },

        "& .drop-zone-content": {
          display: "flex",
        },

        "& .components-drop-zone__content-inner": {
          display: "none",
        },

        "& .components-drop-zone.is-dragging-over-element": {
          opacity: 0,
          bg: "transparent",
        },
      }}
    >
      <div className="drop-zone-content">{children}</div>

      <DropZone
        onDragOver={(e) => {
          e.preventDefault();

          // We're calculating if it's hovering over the left or right side of the image
          // @ts-ignore
          const rect = e.target.getBoundingClientRect();
          const isOverLeft = e.clientX < rect.left + rect.width / 2;

          if (isOverLeft) {
            setHoverSide("left");
          } else {
            setHoverSide("right");
          }
          return false;
        }}
        onDragEnter={() => {
          setIsActive(true);
        }}
        onDragLeave={() => {
          setIsActive(false);
        }}
        onDragEnd={() => {
          setIsActive(false);
        }}
        onFilesDrop={(files) => {
          setIsActive(false);

          let blockIndex: number | undefined = hoverSide === "left" ? 0 : 1;
          if (_rootClientId) {
            blockIndex = getDropBlockIndex(blockId, undefined, hoverSide);
          }

          handleDroppedFiles(files, _rootClientId || blockId, blockIndex);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setIsActive(false);
          const data: DraggableMediaTransferDataType = JSON.parse(
            e.dataTransfer?.getData(DRAGGABLE_MEDIA_TRANSFER_DATA_TYPE) ?? "{}",
          );
          if (
            !globalEntryID ||
            !data?.srcClientIds ||
            data.srcClientIds.includes(blockId)
          ) {
            return;
          }
          const { srcRootClientId, srcClientIds } = data;
          // We should only be passing one clientId in the srcClientIds array
          // This should be changed once we have a way to drag multiple blocks
          const clientId = srcClientIds[0];
          const blockRootClientId = srcRootClientId;

          const blockIndex: number | undefined = getDropBlockIndex(
            blockId,
            clientId,
            hoverSide,
          );

          // If the block is in the same gallery, we move it to the correct position
          if (
            _rootClientId &&
            blockRootClientId &&
            _rootClientId === blockRootClientId
          ) {
            if (blockIndex !== undefined) {
              srcClientIds.forEach((clientId, idx) => {
                moveMediaToPosition(
                  clientId,
                  blockRootClientId,
                  _rootClientId,
                  blockIndex + idx,
                );
              });
            }

            return;
          }

          /**
           * Different scenarios:
           * 1. Solo media to existing gallery block
           * 2. Solo media to another solo media block
           * 3. Gallery media to existing gallery block
           * 4. Gallery media to solo media block
           */

          // This means we're moving into another gallery block
          if (_rootClientId) {
            let blockIndex: number | undefined = getDropBlockIndex(
              blockId,
              undefined,
              hoverSide,
            );
            if (blockIndex === undefined) {
              blockIndex = hoverSide === "left" ? 0 : 1;
            }

            srcClientIds.forEach((clientId, idx) => {
              moveMediaToPosition(
                clientId,
                blockRootClientId,
                _rootClientId,
                blockIndex + idx,
              );
            });
          } else {
            // This means we are moving into a solo Media block

            const blockIndex = hoverSide === "left" ? 0 : 1;
            createGalleryFromBlocks(
              clientId,
              blockId,
              globalEntryID.journal_id,
              globalEntryID.id,
              blockIndex,
            );
          }
        }}
      />
      {filesMessage?.length > 0 && (
        <FileUploadMessagesModal
          message={filesMessage}
          onClose={resetFilesMessage}
        />
      )}
    </div>
  );
});
