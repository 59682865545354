import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";

import {
  CONTACT_BLOCK_ID,
  ContactBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { useMomentThumb } from "@/data/hooks/moments";

export const ContactBlock: React.FC<ContactBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const { url } = useMomentThumb(
    attributes.journalId,
    attributes.entryId,
    attributes.photoIdentifier,
  );

  return (
    <SuggestionWrapper
      imageURL={url}
      title={attributes.name}
      fallBackIconName="embed_contact"
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(CONTACT_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(CONTACT_BLOCK_ID, {
      edit: (props: ContactBlockEditProps) => {
        return <ContactBlock {...props} />;
      },
      title: "Contact",
      category: "media",
      textdomain: "default",
      description: "Day One Contact Block",
      attributes: {
        type: {
          type: "string",
        },
        identifier: {
          type: "string",
        },
        name: {
          type: "string",
        },
        photoIdentifier: {
          type: "string",
        },
        source: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
        journalId: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(CONTACT_BLOCK_ID)) {
    return unregisterBlockType(CONTACT_BLOCK_ID);
  }
  return false;
};
