import { store as blockEditorStore } from "@wordpress/block-editor";
import { createBlock, cloneBlock } from "@wordpress/blocks";
import { useRefEffect } from "@wordpress/compose";
import { useSelect, useDispatch } from "@wordpress/data";
import { useRef } from "@wordpress/element";
import { ChecklistNode } from "types/rtj-format";

import {
  CHECKLIST_ITEM_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { convertChecklistItemToRTJNodes } from "@/components/Editor/gb2rtj/format-list";
import { convertChecklistNodesToBlockAttributes } from "@/components/Editor/rtj2gb/format-list";
import { ENTER } from "@/components/Editor/utils/keycodes";

const cutChecklistNodesWithOffset = (
  rtjNodes: ChecklistNode[],
  endOffset: number,
  // when this is given it means there is text selected
  // so we count this offset as well to cut the list
  startOffset?: number,
): { heads: ChecklistNode[]; tails: ChecklistNode[]; counter: number } => {
  let nodeHasCaretFound = false;
  const offset = startOffset ?? endOffset;

  return rtjNodes.reduce<{
    heads: ChecklistNode[];
    tails: ChecklistNode[];
    counter: number;
  }>(
    (acc, node) => {
      // when end of current block text is not exceed the caret position yet
      if (node.text.length + acc.counter < offset) {
        acc.heads.push(node);
      } else if (
        // when current block text will exceed the caret position
        // and we haven't found the node has caret yet
        !nodeHasCaretFound &&
        acc.counter + node.text.length >= offset
      ) {
        const headSlicePosition = !startOffset
          ? endOffset - acc.counter
          : startOffset - acc.counter;
        const tailSlicePosition = endOffset - acc.counter;
        acc.heads.push({
          // update the text of current node
          ...node,
          text: node.text.slice(0, headSlicePosition),
        });
        acc.tails.push({
          // copy the current node includes formatting
          // with the partial content slice from the previous one
          ...node,
          text: node.text.slice(tailSlicePosition),
        });
        nodeHasCaretFound = true;
      } else {
        // push the rest of nodes to tails
        acc.tails.push(node);
      }

      acc.counter += node.text.length;

      return acc;
    },
    {
      heads: [],
      tails: [],
      counter: 0,
    },
  );
};

export default function useEnter(props: any) {
  const { replaceBlocks, selectionChange, updateBlockAttributes } =
    useDispatch(blockEditorStore);
  const { getBlock, getSelectionEnd, getSelectionStart } = useSelect(
    blockEditorStore,
    [],
  );

  const propsRef = useRef(props);
  propsRef.current = props;

  return useRefEffect((element) => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.defaultPrevented || event.key !== ENTER) {
        return;
      }
      event.preventDefault();

      const { content, clientId, indent } = propsRef.current;

      // If empty block, replace with paragraph.
      if (!content.trim().length) {
        // If it's an empty block with minimal indent, replace with paragraph
        if (indent === 1) {
          replaceBlocks(clientId, [createBlock(PARAGRAPH_BLOCK_ID)]);
          return;
        }
        // If it's an empty block with more than minimal indent, keep it and outdent
        updateBlockAttributes([clientId], { indent: indent - 1 });
        return;
      }

      const { offset: selStartOffset } = getSelectionStart();
      const { offset: selEndOffset } = getSelectionEnd();
      const currentBlock = getBlock(clientId);
      const checkListRtjNodes = convertChecklistItemToRTJNodes(
        currentBlock,
      ) as ChecklistNode[];

      const { heads, tails } = cutChecklistNodesWithOffset(
        checkListRtjNodes,
        selEndOffset,
        selStartOffset !== selEndOffset ? selStartOffset : undefined,
      );

      const { content: headContent } =
        convertChecklistNodesToBlockAttributes(heads)[0];

      // Split the current block at the selection offset
      const head = cloneBlock({
        ...currentBlock,
        attributes: {
          ...currentBlock.attributes,
          content: headContent,
        },
      });

      const newAttributes = convertChecklistNodesToBlockAttributes(tails)[0];
      // Create a new block with the remaining content
      const newBlock = createBlock(CHECKLIST_ITEM_BLOCK_ID, newAttributes);

      // Replace the current block with the split blocks
      replaceBlocks(clientId, [head, newBlock]);
      // Change selection to the new block
      selectionChange(newBlock.clientId, "content");
    };

    element.addEventListener("keydown", onKeyDown as EventListener);
    return () => {
      element.removeEventListener("keydown", onKeyDown as EventListener);
    };
  }, []);
}
