import Dexie, { liveQuery } from "dexie";
import { EmbeddableNode, RTJNode } from "types/rtj-format";

import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  isContactNode,
  isEmbeddableContentNode,
  isGenericMediaNode,
  isMomentNode,
  isPodcastNode,
  isSongNode,
  isStateOfMindNode,
} from "@/components/Editor/rtj2gb/rtj-type-checks";
import { DOCrypto } from "@/crypto/DOCrypto";
import { toHex } from "@/crypto/utils";
import { md5 } from "@/crypto/utils/md5";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import {
  MediaDownloadResults,
  MomentDBRow,
  MomentID,
  momentType,
} from "@/data/db/migrations/moment";
import { Outbox } from "@/data/models/Outbox";
import {
  OutboxResult,
  UploadOriginalMomentSendable,
} from "@/data/models/OutboxTypes";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { UserRepository } from "@/data/repositories/UserRepository";
import { VaultRepository } from "@/data/repositories/VaultRepository";
import { DecryptionService } from "@/data/services/DecryptionService";
import { getClientMeta } from "@/data/utils/clientMeta";
import { PartialMediaHandler } from "@/data/utils/moments/idbMediaDownloader";
import {
  GetMediaError,
  getMediaKey,
  isValidMedia,
} from "@/data/utils/moments/media";
import { canvasToBlob, createCanvas } from "@/utils/canvas-helpers";
import { makeDebugLogger } from "@/utils/debugLog";
import { isDevelopment } from "@/utils/environment";
import { Audios, PDFs, Photos, Videos } from "@/utils/export";
import {
  convertSVGToPNG,
  getImageDimensionsFromArrayBuffer,
  getThumbnailDimension,
  loadFileArrayBuffer,
} from "@/utils/file-helper";
import { uuid } from "@/utils/uuid";
import { journalId_AllEntries } from "@/view_state/PrimaryViewState";

const debugLog = makeDebugLogger("MomentRepository.ts", false);

// These values need to correlate with
// https://github.com/bloom/DayOne-Apple/blob/fab1715c915ab709a6875d8053b94e932f69752f/core/DOCore/DOCore/DOWebMoment.swift#L13
const SUPPORTED_MOMENT_TYPES = ["image", "video", "audio", "pdfAttachment"];

export class MomentRepository {
  // To keep track of on going fetch / decryption of media
  // so we don't try to download or decrypt an item
  // again if it is already in progress
  private mediaFetchPromises = new Map<
    string,
    Promise<Uint8Array | GetMediaError>
  >();

  constructor(
    protected db: DODexie,
    private decryptionService: DecryptionService,
    private userRepository: UserRepository,
    private vaultRepository: VaultRepository,
    private outbox: Outbox,
    private kv: KeyValueStore,
    private journalRepository: JournalRepository,
    private fetch: FetchWrapper,
  ) {
    // Every time the application starts up
    // we'll check to see if the developer setting to
    // make missing media files is enabled. If it is, we
    // clear it. We don't want that setting to persist.
    this.clearShouldMakeMissingMedia().catch((err) => {
      Sentry.captureException(err);
    });
  }

  // A moment is only unique by the combination of its journal, entry, and moment id
  async getMomentById(
    journalId: string,
    entryId: string,
    momentId: string,
  ): Promise<MomentDBRow | null> {
    return (await this.db.moments.get([journalId, entryId, momentId])) ?? null;
  }

  async fetchAndDecryptMediaById(
    id: string,
    journalId: string,
    isThumbnail: boolean,
    debugMessage: string,
  ): Promise<Uint8Array | GetMediaError> {
    let response: Response | null = null;
    let blob: Uint8Array | null = null;
    try {
      response = await this.fetchMediaById(id, journalId, isThumbnail);
    } catch (e: any) {
      Sentry.captureException(
        new Error(`Failed to fetch media for moment ${id}: ${e.message}`),
      );
    }

    if (!response || !response.ok) {
      return "COULD_NOT_DOWNLOAD";
    }

    let mediaBuffer: ArrayBuffer | null = null;
    try {
      mediaBuffer = await this.downloadMediaFromResponse(
        response,
        id,
        journalId,
        isThumbnail,
      );
    } catch (e: any) {
      Sentry.captureException(
        new Error(`Failed to stream blob for moment ${id}: ${e.message}`),
      );
    }
    if (!mediaBuffer) {
      return "COULD_NOT_COMPLETE_DOWNLOAD";
    }

    try {
      blob = await this.decryptMediaBuffer(mediaBuffer, debugMessage);
    } catch (e: any) {
      Sentry.captureException(
        new Error(`Failed to decrypt media for moment ${id}: ${e.message}`),
      );
      return "COULD_NOT_DECRYPT";
    }
    if (blob == null) {
      return "UNKNOWN";
    }
    return blob;
  }

  /**
   * This function is the main entry point to downloading media.
   * An explanation of the process can be found here https://wp.me/pdr0wL-2EO
   */
  async getMediaForMoment(
    id: string,
    localMd5: string,
    // If this value is null, we won't fallback to the server.
    remoteMd5: string | null,
    journalId: string,
    options: {
      throwIfNotFound?: boolean;
      entryId?: string;
    } = {},
    mediaType?: "thumbnail" | momentType,
  ): Promise<Uint8Array | GetMediaError> {
    // The type system should protect us here, but sometimes we get
    // values that don't reflect the types, because we don't validate
    // our data at the edges (pulling from the DB, for example).
    if (localMd5 == null) {
      return "NO_LOCAL_MD5";
    }
    // First see if we have it locally
    const result = await this.db.medias.get(localMd5);

    const data = result?.data;
    const isThumbnail = mediaType === "thumbnail";
    const mediaKey = getMediaKey(id, journalId, isThumbnail);

    if (data) {
      this.mediaFetchPromises.delete(mediaKey);
      return data;
    } else if (!remoteMd5) {
      this.mediaFetchPromises.delete(mediaKey);
      return "NO_REMOTE_MD5";
    } else {
      const existingPromise = this.mediaFetchPromises.get(mediaKey);
      const blobPromise =
        existingPromise ||
        this.fetchAndDecryptMediaById(
          id,
          journalId,
          isThumbnail,
          `getting media by id(${localMd5}, ${remoteMd5}, ${id})`,
        );
      if (!existingPromise) {
        this.mediaFetchPromises.set(mediaKey, blobPromise);
      }

      const blob = await blobPromise;
      this.mediaFetchPromises.delete(mediaKey);
      const isMediaValid = isValidMedia(blob);

      if (!isMediaValid && options.throwIfNotFound) {
        throw new Error(
          "Failed to load media from localDB for md5 " + localMd5,
        );
      }

      // Typescript doesn't seem to be smart enough to realize that if
      // isMediaValid === false then blob must be a Unit8Array
      // So if this condition passes we'll be returning an error message
      if (!isMediaValid) {
        return blob;
      }
      if (!(blob instanceof Uint8Array)) {
        return "UNKNOWN";
      }

      // If we have the decrypted media save it to the local database
      await this.db.medias.put({ md5: localMd5, data: blob });

      analytics.tracks.recordEvent(EVENT.attachmentDownload, {
        media_type: mediaType ?? "unknown",
      });
      return blob;
    }
  }

  async getThumbnailUrlForMoment(
    moment: MomentDBRow | undefined | null,
  ): Promise<null | string> {
    const blob = await this.getThumbnailBlobForMoment(moment);
    return blob ? URL.createObjectURL(blob) : null;
  }

  async getThumbnailBlobForMoment(
    moment: MomentDBRow | undefined | null,
  ): Promise<null | Blob> {
    if (!moment) {
      return null;
    }
    if (!moment?.thumbnail_md5_body) {
      // There's no thumb at all. Let's give up.
      return null;
    } else {
      const buffer = await this.getMediaForMoment(
        moment!.id,
        moment!.thumbnail_md5_body,
        moment!.thumbnail_md5_envelope,
        moment.journal_id,
        { entryId: moment.entry_id },
        "thumbnail",
      );
      return isValidMedia(buffer)
        ? new Blob([buffer], { type: moment.thumbnail_content_type || "" })
        : null;
    }
  }

  async getThumbnailUrl(
    journalId: string,
    entryId: string,
    momentId: string,
  ): Promise<null | string> {
    const moment = await this.getMomentById(journalId, entryId, momentId);
    return this.getThumbnailUrlForMoment(moment);
  }

  async getForEntry(journalId: string, entryId: string, excludeDeleted = true) {
    const moments = await this.db.moments
      .where(["journal_id", "entry_id"])
      .equals([journalId, entryId])
      .filter((m) => (excludeDeleted ? !m._local_deleted : true))
      .toArray();
    return moments;
  }

  async getFirstForEntry(journalId: string, entryId: string) {
    const moment = await this.db.moments
      .where(["journal_id", "entry_id"])
      .equals([journalId, entryId])
      .filter((m) => !m._local_deleted)
      .last(); // Seems these are in reverse order then in the editor
    return moment || null;
  }

  async isMediaDownloaded(md5: string) {
    const media = await this.db.medias.get(md5);
    return !!media;
  }

  async isAllEntryMediaDownloaded(journalId: string, entryId: string) {
    const moments = await this.getForEntry(journalId, entryId);
    const results = await Promise.all(
      moments.map(async (moment) => {
        return this.isMediaDownloaded(moment.md5_body);
      }),
    );
    return results.every((r) => r);
  }

  async allEntryMediaMissing(journalId: string, entryId: string) {
    const moments = await this.getForEntry(journalId, entryId);
    let count = 0;
    for (const momentDbRow of moments) {
      const media = await this.db.medias.get(momentDbRow.md5_body);
      if (typeof media === "undefined" || media === null) {
        count += 1;
      }
    }
    return count;
  }

  async entryMediaDownloadedCount(journalId: string, entryId: string) {
    const moments = await this.getForEntry(journalId, entryId);
    const results = await Promise.all(
      moments.map(async (moment) => {
        const media = await this.db.medias.get(moment.md5_body);
        return !!media;
      }),
    );
    return results.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
  }

  async bulkDownloadMediaForMoments(
    moments: MomentDBRow[],
    options: {
      maxConcurrent?: number;
      cancelRequested?: () => boolean;
      updateProgress?: (downloaded: number) => void;
    } = {},
  ) {
    const maxConcurrent = options.maxConcurrent || 5;
    const updateProgress = options.updateProgress;
    const NUMBER_OF_BATCHES = Math.ceil(moments.length / maxConcurrent);
    const downloaded: MediaDownloadResults[] = [];
    const debugLogging = await this.kv.get<boolean>("debug-logging");
    if (debugLogging) {
      console.log(`Start downloading ${moments.length} media files`);
    }
    let batchCount = 1;
    let cancelDownloadRequested = false;

    for (let i = 0; i < moments.length; i += maxConcurrent) {
      cancelDownloadRequested = options.cancelRequested?.() || false;
      if (cancelDownloadRequested) {
        break;
      }
      const batch = moments.slice(i, i + maxConcurrent);
      const batchPromises = await Promise.allSettled(
        batch.map(async (moment) => {
          const maybeMedia = await this.getMediaForMoment(
            moment.id,
            moment.md5_body,
            moment.md5_envelope,
            moment.journal_id,
            { entryId: moment.entry_id },
            moment.type,
          );
          const isMediaValid = isValidMedia(maybeMedia);
          if (isMediaValid) {
            updateProgress?.(moment.metadata?.fileSize || 0);
            return { result: "success" } as MediaDownloadResults;
          } else {
            return {
              result: "failed",
              details: {
                journalId: moment.journal_id,
                entryId: moment.entry_id,
                momentId: moment.id,
                reason: isMediaValid === null ? "UNKNOWN" : maybeMedia,
                bodyMd5: moment.md5_body,
                envelopeMd5: moment.md5_envelope,
              },
            } as MediaDownloadResults;
          }
        }),
      );
      const results = batchPromises.map((result) => {
        if (result.status === "fulfilled") {
          return result.value;
        } else {
          return {
            result: "failed",
            details: {
              journalId: "unknown",
              entryId: "unknown",
              momentId: "unknown",
              reason: result.reason,
            },
          } as MediaDownloadResults;
        }
      });
      if (debugLogging) {
        console.log(`Batch ${batchCount}/${NUMBER_OF_BATCHES} complete`);
      }
      batchCount++;
      downloaded.push(...results);
    }
    if (debugLogging) {
      if (cancelDownloadRequested) {
        console.log("Media download cancelled");
      } else {
        console.log("All media downloaded");
      }
    }

    const results = await Promise.all(downloaded);
    return results.reduce(
      (acc, value) => {
        if (value.result === "success") {
          acc.success++;
        }
        if (value.result === "failed") {
          acc.failed.push(value.details);
        }
        acc.allSuccess = acc.failed.length === 0;
        return acc;
      },
      {
        success: 0,
        failed: [],
        allSuccess: false,
        cancelled: cancelDownloadRequested,
      } as {
        success: number;
        failed: MediaDownloadResults["details"][];
        allSuccess: boolean;
        cancelled: boolean;
      },
    );
  }

  async getMomentCountsForEntry(
    journalId: string,
    entryId: string,
    excludeDeleted = true,
  ) {
    const moments = await this.getForEntry(journalId, entryId, excludeDeleted);
    const counts = moments.reduce(
      (acc, value) => {
        switch (value.type) {
          case "image":
          case "photo" as momentType: // In case we have the value "photo" in there by accident I guess?
            acc.photos++;
            break;
          case "video":
            acc.videos++;
            break;
          case "audio":
            acc.audio++;
            break;
        }
        return acc;
      },
      {
        photos: 0,
        videos: 0,
        audio: 0,
      },
    );
    return counts;
  }

  async fetchMediaById(id: string, journalId: string, isThumbnail: boolean) {
    const mediaHandler = new PartialMediaHandler(
      id,
      journalId,
      isThumbnail,
      this.db,
    );
    const resumeSize = (await mediaHandler.getExistingSize()) || undefined;

    const url = `/journals/${journalId}/attachments/${id}/download?thumbnail=${isThumbnail}`;
    const response = await this.fetch.fetchAPI(url, undefined, {
      requestManualRedirect: true,
      resumeSize,
    });

    return response;
  }

  async downloadMediaFromResponse(
    res: Response,
    id: string,
    journalId: string,
    isThumbnail: boolean,
  ): Promise<ArrayBuffer | null> {
    if (res.status === 404) {
      return null;
    }
    // 206 is partial content which is a valid response when resuming a download
    if (res.status !== 200 && res.status !== 206) {
      console.warn("Unexpected status code fetching media", res.status);
      return null;
    }

    const reader = res.body?.getReader();
    if (!reader) {
      return null;
    }

    const mediaHandler = new PartialMediaHandler(
      id,
      journalId,
      isThumbnail,
      this.db,
      reader,
    );

    return mediaHandler.write();
  }

  async decryptMediaBuffer(buffer: ArrayBuffer, debugContext: string) {
    // If the response has "D1" as the first two bytes, it's encrypted.
    // Hex version of UTF-8 "D1" is "4431"
    const shouldDecrypt = toHex(new Uint8Array(buffer.slice(0, 2))) == "4431";
    let finalBlob: Uint8Array | null = null;

    if (shouldDecrypt) {
      // if it's encrypted pass the raw blob text to decrypt, which will convert it to base64
      const decrypted = await this.decryptionService.decryptMedia(
        new Uint8Array(buffer),
        debugContext,
        this.vaultRepository,
      );

      // convert to blob
      finalBlob = decrypted;
    } else {
      finalBlob = new Uint8Array(buffer);
    }

    return finalBlob;
  }

  private async resizeImage({
    data: data,
    origWidth: origWidth,
    origHeight: origHeight,
    fileType: fileType,
  }: {
    data: ArrayBuffer | Uint8Array;
    origWidth: number;
    origHeight: number;
    fileType: string;
  }) {
    const { newWidth, newHeight } = getThumbnailDimension(
      origWidth,
      origHeight,
    );

    const { canvas, context } = createCanvas(newWidth, newHeight);

    // TODO! Safari doesn't support createImageBitmap with a Blob at the moment 🤦‍♂️
    // https://bugs.webkit.org/show_bug.cgi?id=182424
    // Looks like we gotta use a library

    const bmp = await createImageBitmap(new Blob([data]));
    context.drawImage(bmp, 0, 0, newWidth, newHeight);
    const jpgP = await canvasToBlob(canvas, fileType, 0.99);

    const newData = await jpgP.arrayBuffer();

    return {
      data: newData,
      width: newWidth,
      height: newHeight,
      contentType: fileType,
      md5: await md5(newData),
    };
  }

  async hasMedia(md5: string): Promise<boolean> {
    const count = await this.db.medias.where("md5").equals(md5).count();
    return count != 0;
  }

  async cleanOrphanedMedia() {
    const isMediaEmpty = (await this.db.medias.count()) === 0;
    const isMomentsEmpty = (await this.db.moments.count()) === 0;
    const allMediaMd5s = isMediaEmpty
      ? []
      : await this.db.medias.orderBy("md5").uniqueKeys();
    const momentMd5s = isMomentsEmpty
      ? []
      : await this.db.moments.orderBy("md5_body").uniqueKeys();
    const momentThumbnailMd5s = isMomentsEmpty
      ? []
      : await this.db.moments.orderBy("thumbnail_md5_body").uniqueKeys();
    const allMomentMd5s = momentMd5s.concat(momentThumbnailMd5s);

    const orphanedMd5s = allMediaMd5s.filter(
      (md5) => !allMomentMd5s.includes(md5),
    );
    await this.db.medias.bulkDelete(orphanedMd5s);
  }

  async getMediaAttributes(file: File, arrayBuffer: ArrayBuffer) {
    const fileType = file.type;
    // The media APIs we use for finding image sizes, etc, don't run in Node,
    const isTesting = process.env.NODE_ENV === "test";
    const isImage = !isTesting && fileType.includes("image");
    const isVideo = !isTesting && fileType.includes("video");
    const isAudio = !isTesting && fileType.includes("audio");

    if (isImage) {
      return await this.handleImage(fileType, arrayBuffer);
    } else if (isVideo) {
      return await this.handleVideo(fileType, arrayBuffer);
    } else if (isAudio) {
      return await this.handleAudio(fileType, arrayBuffer);
    } else {
      return { height: 0, width: 0, thumbnail: null, fileType, duration: null };
    }
  }

  async handleAudio(fileType: string, arrayBuffer: ArrayBuffer) {
    const audio = await this.createAudioElement(arrayBuffer, fileType);
    const { duration } = audio;
    audio.remove();
    return { height: 0, width: 0, thumbnail: null, fileType, duration };
  }

  async handleImage(fileType: string, arrayBuffer: ArrayBuffer) {
    const { height, width } = await getImageDimensionsFromArrayBuffer(
      fileType,
      arrayBuffer,
    );
    const thumbnail = await this.resizeImage({
      data: arrayBuffer,
      origWidth: width,
      origHeight: height,
      fileType,
    });

    return { height, width, thumbnail, fileType, duration: null };
  }

  async handleVideo(fileType: string, arrayBuffer: ArrayBuffer) {
    const video = await this.createVideoElement(arrayBuffer, fileType);
    const { videoHeight: height, videoWidth: width } = video;
    const { newWidth: thumbnailWidth, newHeight: thumbnailHeight } =
      getThumbnailDimension(width, height);
    const thumbnailBlob = await this.createThumbnailBlob(
      video,
      thumbnailWidth,
      thumbnailHeight,
    );
    const thumbnail = await this.createThumbnailData(
      thumbnailBlob,
      thumbnailWidth,
      thumbnailHeight,
    );
    const duration = video.duration;
    video.remove();
    return { height, width, thumbnail, fileType, duration };
  }

  async createAudioElement(arrayBuffer: ArrayBuffer, fileType: string) {
    const audio = document.createElement("audio");
    const blob = new Blob([arrayBuffer], { type: fileType });
    const audioUrl = URL.createObjectURL(blob);
    audio.src = audioUrl;
    try {
      audio.muted = true;
      // the objective here is to return an audio with complete metadata
      // by await audio play - we know that metadata has loaded
      await audio.play();
      // pause the audio so it doesn't play in the background
      audio.pause();
      return audio;
    } finally {
      URL.revokeObjectURL(audioUrl);
    }
  }

  async createVideoElement(arrayBuffer: ArrayBuffer, fileType: string) {
    const video = document.createElement("video");
    const blob = new Blob([arrayBuffer], { type: fileType });
    const videoUrl = URL.createObjectURL(blob);
    video.src = videoUrl;
    try {
      video.muted = true;
      // the objective here is to return a video with complete metadata
      // by await video play - we know that metadata has loaded
      await video.play();
      // pause the video so it doesn't play in the background
      video.pause();
      return video;
    } finally {
      URL.revokeObjectURL(videoUrl);
    }
  }

  async createThumbnailBlob(
    video: HTMLVideoElement,
    thumbnailWidth: number,
    thumbnailHeight: number,
  ) {
    const { canvas, context } = createCanvas(thumbnailWidth, thumbnailHeight);

    const videoThumbnailFileType = "image/jpeg";
    context.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);

    const thumbnailQuality = 0.5;
    return await canvasToBlob(canvas, videoThumbnailFileType, thumbnailQuality);
  }

  async createThumbnailData(
    thumbnailBlob: Blob,
    thumbnailWidth: number,
    thumbnailHeight: number,
  ) {
    const thumbnailArrayBuffer = await thumbnailBlob.arrayBuffer();
    return {
      data: thumbnailArrayBuffer,
      width: thumbnailWidth,
      height: thumbnailHeight,
      contentType: "image/jpeg",
      md5: await md5(thumbnailArrayBuffer),
    };
  }

  private isSupportedMomentType(type: string) {
    return SUPPORTED_MOMENT_TYPES.includes(type);
  }

  async createLocalMomentFromFile(
    file: File,
    entryId: string,
    journalId: string,
  ) {
    if (file.type.includes("image/svg")) {
      file = await convertSVGToPNG(file);
    }

    const arrayBuffer = await loadFileArrayBuffer(file);

    if (arrayBuffer) {
      const { height, width, thumbnail, fileType, duration } =
        await this.getMediaAttributes(file, arrayBuffer);

      const md5Hash = await md5(arrayBuffer);
      const deviceMeta = await getClientMeta();
      // Other clients use uppercase UUIDs with no dashes, so we do too.
      const id = uuid().split("-").join("").toUpperCase();

      await this.saveMedia(md5Hash, new Uint8Array(arrayBuffer));
      if (thumbnail) {
        await this.saveMedia(thumbnail.md5, new Uint8Array(thumbnail.data));
      }

      // According to https://www.iana.org/assignments/media-types/media-types.xhtml
      // The first half of the MIME-type is correct in all of the cases we support except
      // for PDF, which we special case in the check below. However, MIME types also
      // specify types that we don't support. So we'll do a check to make sure the
      // type the user is adding is one that we support.
      const momentType =
        file.type === "application/pdf"
          ? "pdfAttachment"
          : file.type.split("/")[0];
      if (!this.isSupportedMomentType(momentType)) {
        throw new Error(`Unsupported moment type: ${momentType}`);
      }

      await this.db.moments.put({
        id,
        entry_id: entryId,
        journal_id: journalId,
        md5_body: md5Hash,
        md5_envelope: null,
        content_type: fileType,
        // TODO - In future we will want to ask the user whether to use file info or date of entry
        date: file.lastModified,
        creation_device: deviceMeta.creationDevice,
        // TODO - we don't have a concept of generating a device identifier on web at the moment
        creation_device_identifier: "",
        favorite: 0,
        is_sketch: 0,
        height,
        width,
        // Thumbnail data empty for now on non-sync'd moments, we fill this in when persisting the entry.
        thumbnail_content_type: thumbnail?.contentType || null,
        thumbnail_md5_body: thumbnail?.md5 || null,
        thumbnail_md5_envelope: null,
        thumbnail_height: thumbnail?.height || null,
        thumbnail_width: thumbnail?.width || null,
        thumbnail_size_bytes: thumbnail?.data.byteLength || null,
        type: momentType as momentType,
        _local_thumbnail_uploaded: null,
        _local_original_uploaded: null,
        is_promise: 1,
        _local_created_locally: 1,
        metadata: {
          duration: duration || null,
          fileSize: arrayBuffer.byteLength,
        },
        ...(momentType === "pdfAttachment" && {
          pdfName: file.name.replace(/\.pdf$/i, ""),
        }),
      });

      const result = await this.db.moments.get([journalId, entryId, id]);
      debugLog("Created local moment", result);
      return result;
    }
  }

  async removeLocalMomentsForEntries(journalId: string, entryIds: string[]) {
    const keys = entryIds.map((entryId) => [journalId, entryId]);
    await this.db.moments
      .where(["journal_id", "entry_id"])
      .anyOf(keys)
      .delete();
  }

  async removeLocalMomentsForJournals(journalIds: string[]) {
    await this.db.moments.where("journal_id").anyOf(journalIds).delete();
  }

  // After editing an entry we need to go through the nodes and delete
  // moments which have been removed from the entry, or un-delete moments
  // which have been re-added to the entry (through undo, for example).
  async updateMomentDeletionFromRTJ(
    journalId: string,
    entryId: string,
    rtjNodes: RTJNode[],
  ) {
    const idsFromRtj = rtjNodes
      .reduce<EmbeddableNode[]>((acc, node) => {
        if (!isEmbeddableContentNode(node)) {
          return acc;
        } else {
          const momentNodes = node.embeddedObjects.filter((nn) =>
            isMomentNode(nn),
          );
          return [...acc, ...momentNodes];
        }
      }, [])
      .map((node) => {
        if (isContactNode(node)) {
          return node.photoIdentifier;
        }
        if (isPodcastNode(node)) {
          return node.artworkIdentifier;
        }
        if (isSongNode(node)) {
          return node.artworkIdentifier;
        }
        if (isGenericMediaNode(node)) {
          return node.iconIdentifier;
        }
        if (isStateOfMindNode(node)) {
          return node.iconIdentifier;
        }
        return node.identifier;
      });

    const momentsFromDB = await this.getForEntry(journalId, entryId, false);
    const idsToDelete: string[] = [];
    const idsToUndelete: string[] = [];
    Object.values(momentsFromDB).map((moment) => {
      const hasMomentId = idsFromRtj.includes(moment.id);

      // Any nodes that are in the database for this entry, but not in
      // the moment nodes array above should be marked as deleted.
      if (!hasMomentId && !moment._local_deleted) {
        analytics.tracks.recordEvent(EVENT.attachmentDelete, {
          media_type: moment.type,
          entry_id: entryId,
        });
        idsToDelete.push(moment.id);
      }
      // Any moments that are marked as deleted in the database, but are
      // present in the moment nodes array above should be unmarked as deleted.
      if (hasMomentId && moment._local_deleted) {
        idsToUndelete.push(moment.id);
      }
    });

    if (idsToDelete.length > 0) {
      const update: Partial<MomentDBRow> = {
        _local_deleted: Date.now(),
      };
      await this.db.moments
        .where(["journal_id", "entry_id", "id"])
        .anyOf(idsToDelete.map((id) => [journalId, entryId, id]))
        .modify(update);
    }

    if (idsToUndelete.length > 0) {
      const update: Partial<MomentDBRow> = {
        _local_deleted: null,
        _local_original_uploaded: null,
        _local_thumbnail_uploaded: null,
      };
      await this.db.moments
        .where(["journal_id", "entry_id", "id"])
        .anyOf(idsToUndelete.map((id) => [journalId, entryId, id]))
        .modify(update);
    }
  }

  private async markMomentAsUploaded(moment: MomentDBRow) {
    return this.db.moments.update(
      [moment.journal_id, moment.entry_id, moment.id],
      { _local_original_uploaded: Date.now() },
    );
  }

  async uploadToServer(moment: MomentDBRow): Promise<OutboxResult> {
    const journalIsE2EE = await this.journalRepository.isJournalE2EE(
      moment.journal_id,
    );
    const vault = journalIsE2EE
      ? await this.vaultRepository.getVaultByJournalId(moment.journal_id)
      : null;

    // If the entry has a moment that was not there in the first place
    // We won't have it locally and we won't be able to upload it
    const plainData = await this.getMediaForMoment(
      moment.id,
      moment.md5_body,
      null,
      moment.journal_id,
      { entryId: moment.entry_id },
    );
    const haveData = isValidMedia(plainData);
    if (!haveData) {
      const reason = haveData === null ? "UNKNOWN" : plainData;
      const message = `[MomentRepository]: Tried to upload original for moment, but could not find original media locally. Reason: ${reason}`;
      Sentry.captureException(new Error(message));
      console.error(`${message} - Moment: ${moment}`);
      return { result: "failed", message };
    }

    let data;
    if (vault) {
      data = await DOCrypto.JournalKey.encrypt(
        plainData as Uint8Array,
        vault.vault.keys[0],
        2,
      );
    } else {
      data = plainData as Uint8Array;
    }

    const md5Hash = await md5(data);
    const syncUploadBaseURL = (await this.userRepository.getActiveUser())
      ?.sync_upload_base_url;
    if (!syncUploadBaseURL) throw new Error("No sync upload base URL");
    const s3url = `${syncUploadBaseURL}/v2-${moment.journal_id}-${moment.entry_id}-${moment.id}`;
    const shouldSkipMediaUpload = await this.shouldMakeMissingMedia();
    if (shouldSkipMediaUpload) {
      return { result: "success", message: "Skipping upload" };
    }
    const resp = await fetch(s3url, {
      method: "put",
      headers: {
        "Content-Type": vault ? "vnd/day-one-encrypted" : moment.content_type,
        "x-amz-meta-entry-id": moment.entry_id,
        "x-amz-meta-journal-id": moment.journal_id,
        "x-amz-meta-moment-id": moment.id,
        "x-amz-meta-md5": md5Hash,
        "x-amz-acl": "bucket-owner-full-control",
        "x-amz-server-side-encryption": "AES256",
      },
      body: data,
    });
    if (resp.ok) {
      await this.markMomentAsUploaded(moment);
      return { result: "success" };
    } else {
      return {
        result: "failed",
        message: `Error uploading moment: ${resp.status}`,
      };
    }
  }

  async uploadToServerById({
    journalId,
    entryId,
    momentId,
  }: {
    journalId: string;
    entryId: string;
    momentId: string;
  }): Promise<OutboxResult> {
    const moment = await this.getMomentById(journalId, entryId, momentId);
    if (moment == null) {
      const error = `[MomentRepository]: Got a request to upload moment to the server, but I couldn't find it locally. JournalId: ${journalId}. EntryId: ${entryId}. MomentId ${momentId}`;
      Sentry.captureException(new Error(error));
      return { result: "failed", message: error };
    }
    if (moment._local_original_uploaded) {
      const message =
        "[MomentRepository] Skipping upload of already uploaded moment";
      console.info(message, moment);
      return { result: "success", message };
    }
    return await this.uploadToServer(moment);
  }

  async scheduleUploadOfOriginal(moment: MomentDBRow) {
    if (await this.shouldMakeMissingMedia()) {
      return;
    }

    if (moment._local_original_uploaded) {
      return;
    }
    const sendable: UploadOriginalMomentSendable = {
      type: "OriginalMedia",
      action: "CREATE",
      id: `${moment.journal_id}-${moment.entry_id}-${moment.id}`,
      momentId: moment.id,
      entryId: moment.entry_id,
      journalId: moment.journal_id,
    };

    this.outbox.add(sendable);
  }

  async saveMedia(md5: string, data: Uint8Array): Promise<void> {
    await this.db.medias.put({ md5, data });
  }

  async markMomentThumbsAfterUpload(moments: MomentDBRow[]) {
    // After successfully uploading thumbnails, mark them as uploaded in our local DB.
    return Promise.all(
      moments.map((moment) => {
        if (!moment._local_thumbnail_uploaded) {
          return this.db.moments.update(
            [moment.journal_id, moment.entry_id, moment.id],
            {
              _local_thumbnail_uploaded: Date.now(),
              thumbnail_md5_envelope: moment.thumbnail_md5_envelope,
            },
          );
        }
      }),
    );
  }

  async getMomentsForEntry(journalId: string, entryId: string) {
    return this.db.moments
      .where(["journal_id", "entry_id"])
      .equals([journalId, entryId])
      .filter((m) => !m._local_deleted)
      .toArray();
  }

  async markAsDeletedForEntry(journalId: string, entryId: string) {
    return this.db.moments
      .where(["journal_id", "entry_id"])
      .equals([journalId, entryId])
      .modify({ _local_deleted: Date.now() });
  }

  async getMomentsForEntryWithThumbnailData(
    journalId: string,
    entryId: string,
  ): Promise<MomentWithThumb[]> {
    // This query should fetch all moments for an entry,
    // and join the data from the media table for moments that
    // need to have their thumbnails uploaded
    const moments = await this.db.moments
      .where(["journal_id", "entry_id", "id"])
      .between(
        [journalId, entryId, Dexie.minKey],
        [journalId, entryId, Dexie.maxKey],
      )
      .filter((m) => !m._local_deleted)
      .toArray();

    const joined: MomentWithThumb[] = await Promise.all(
      moments.map(async (moment) => {
        if (moment.thumbnail_md5_body) {
          const maybeMedia = await this.getMediaForMoment(
            moment.id,
            moment.thumbnail_md5_body,
            null,
            moment.journal_id,
            { entryId: moment.entry_id },
          );
          const isMediaValid = isValidMedia(maybeMedia);
          if (isMediaValid) {
            return {
              ...moment,
              thumbnail_data: maybeMedia as Uint8Array,
            };
          } else {
            Sentry.captureException(
              new Error(
                `ERROR: Could not find media for moment ${moment.id} with md5. Reason ${isMediaValid === null ? "UNKNOWN" : maybeMedia}`,
              ),
            );
            return { ...moment, thumbnail_data: null };
          }
        } else {
          Sentry.captureException(
            new Error(
              `ERROR: No thumbnail_data because moment does not have thumbnail_md5_body`,
            ),
          );
          return { ...moment, thumbnail_data: null };
        }
      }),
    );
    return joined;
  }

  // For testing UX around missing media in development mode
  async toggleShouldMakeMissingMedia() {
    const current = await this.kv.get<boolean | undefined>(
      "should_make_missing_media",
    );
    await this.kv.set("should_make_missing_media", !current);
  }

  async shouldMakeMissingMedia() {
    if (!isDevelopment()) {
      return false;
    }
    return (
      (await this.kv.get<boolean | undefined>("should_make_missing_media")) ||
      false
    );
  }

  async clearShouldMakeMissingMedia() {
    await this.kv.set("should_make_missing_media", false);
  }

  subscribeToCountByEntry(
    callback: (count: number) => void,
    journalId: string,
    entryId: string,
  ) {
    const stream = liveQuery(async () => {
      return this.db.moments
        .where(["journal_id", "entry_id"])
        .equals([journalId, entryId])
        .filter((m) => !m._local_deleted)
        .count();
    }).subscribe((count) => callback(count));
    return () => stream.unsubscribe();
  }

  subscribeToMomentCountByJournal(
    callback: (moments: MomentCounts) => void,
    journalId: string,
  ) {
    const stream = liveQuery(async () => {
      const moments = await this.db.moments
        .where("journal_id")
        .equals(journalId)
        .filter((m) => !m._local_deleted)
        .toArray();

      const momentCounts = moments.reduce(
        (acc, moment) => {
          if (moment.type === "image") {
            acc.photo++;
          } else if (moment.type === "video") {
            acc.video++;
          } else if (moment.type === "audio") {
            acc.audio++;
          } else if (moment.type === "pdfAttachment") {
            acc.pdf++;
          }
          acc.total++;
          return acc;
        },
        {
          total: 0,
          photo: 0,
          video: 0,
          audio: 0,
          pdf: 0,
        },
      );

      return momentCounts;
    }).subscribe((moments) => callback(moments));
    return () => stream.unsubscribe();
  }

  subToAllIDs(
    callback: (ids: MomentID[]) => void,
    filteredType: string,
    journalId?: string,
    journalsInAllEntries?: string[],
  ) {
    if (journalId == journalId_AllEntries) {
      journalId = undefined;
    }

    const stream = liveQuery(async () => {
      const moments = journalId
        ? await this.db.moments
            .where("journal_id")
            .equals(journalId)
            .sortBy("date")
        : await this.db.moments
            .where("journal_id")
            .anyOf(journalsInAllEntries ?? [])
            .sortBy("date");
      const momentsWithEntryDate = await Promise.all(
        moments.map(async (moment) => {
          const entry = await this.db.entries
            .where(["journal_id", "id"])
            .equals([moment.journal_id, moment.entry_id])
            .first();
          return {
            ...moment,
            entry_date: entry?.date ? new Date(entry.date) : undefined,
          };
        }),
      );
      return momentsWithEntryDate.filter((moment) => {
        if (moment._local_deleted) {
          return false;
        }
        if (filteredType === "all") {
          return true;
        }
        return moment.type === filteredType;
      });
    }).subscribe(
      (momentsWithEntryDate) => {
        const ids: MomentID[] = momentsWithEntryDate
          .map((moment) => {
            return {
              id: moment.id,
              journal_id: moment.journal_id,
              entry_id: moment.entry_id,
              entry_date: moment.entry_date
                ? new Date(moment.entry_date)
                : undefined,
            };
          })
          .sort((a: MomentID, b: MomentID) => {
            if (a.entry_date && b.entry_date) {
              return b.entry_date.getTime() - a.entry_date.getTime();
            }
            return -1;
          });
        callback(ids);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  async addThumbnailToMoment(
    moment: MomentDBRow,
    thumbnail: {
      data: ArrayBuffer;
      width: number;
      height: number;
      contentType: string;
      md5: string;
    },
  ) {
    const newMoment = await this.getMomentById(
      moment.journal_id,
      moment.entry_id,
      moment.id,
    );
    if (!newMoment) return;

    await this.saveMedia(thumbnail.md5, new Uint8Array(thumbnail.data));

    newMoment.thumbnail_content_type = thumbnail.contentType;
    newMoment.thumbnail_md5_body = thumbnail.md5;
    newMoment.thumbnail_height = thumbnail.height;
    newMoment.thumbnail_width = thumbnail.width;
    newMoment.thumbnail_size_bytes = thumbnail.data.byteLength;

    await this.db.moments.put(newMoment);
  }

  async changeJournalIdForMomentsInEntry(
    entryId: string,
    oldJournalId: string,
    newJournalId: string,
  ) {
    return await this.db.moments
      .where(["journal_id", "entry_id"])
      .equals([oldJournalId, entryId])
      .modify({ journal_id: newJournalId });
  }

  async copyMomentsToNewJournalAndEntry(
    oldJournalId: string,
    oldEntryId: string,
    newJournalId: string,
    newEntryId: string,
  ): Promise<MomentWithThumb[]> {
    const moments = await this.getMomentsForEntryWithThumbnailData(
      oldJournalId,
      oldEntryId,
    );

    const newMoments = moments.map((moment) => ({
      ...moment,
      is_promise: 1,
      _local_original_uploaded: null,
      journal_id: newJournalId,
      entry_id: newEntryId,
    }));

    await this.db.moments.bulkPut(newMoments);

    return newMoments;
  }

  private getDefaultMomentFromImportData(
    importMoment: Photos | Videos | Audios | PDFs,
    entryId: string,
    journalId: string,
  ): Pick<
    MomentDBRow,
    | "id"
    | "entry_id"
    | "journal_id"
    | "md5_envelope"
    | "md5_body"
    | "creation_device"
    | "favorite"
    | "is_promise"
    | "_local_created_locally"
    | "creation_device_identifier"
  > {
    return {
      id: importMoment.identifier,
      entry_id: entryId,
      journal_id: journalId,
      md5_envelope: importMoment.md5,
      md5_body: importMoment.md5,
      creation_device: importMoment.creationDevice,
      creation_device_identifier: "",
      favorite: importMoment.favorite ? 1 : 0,
      is_promise: 1,
      _local_created_locally: 1,
    };
  }

  async createPhotoMomentFromImportData(
    importMoment: Photos,
    entryId: string,
    journalId: string,
  ) {
    const media = await this.db.medias.get(importMoment.md5);
    const type = `image/${importMoment.type}`;

    let thumbnail: {
      data: ArrayBuffer;
      width: number;
      height: number;
      contentType: string;
      md5: string;
    } | null = null;
    if (media && media.data) {
      try {
        if (media?.data) {
          const result = await this.handleImage(type, media?.data);
          if (result) {
            thumbnail = result.thumbnail;
          }
        }
      } catch (e) {
        thumbnail = null;
      }
    }
    let thumbnailMd5 = "";
    let thumbnailDimensions: {
      newHeight: number | null;
      newWidth: number | null;
    } = { newHeight: null, newWidth: null };
    if (thumbnail) {
      thumbnailMd5 = await md5(thumbnail.data);
      await this.saveMedia(thumbnailMd5, new Uint8Array(thumbnail.data));
      thumbnailDimensions = getThumbnailDimension(
        importMoment.width,
        importMoment.height,
      );
    }
    const commonMoment = this.getDefaultMomentFromImportData(
      importMoment,
      entryId,
      journalId,
    );
    const moment: MomentDBRow = {
      ...commonMoment,
      content_type: type,
      date: importMoment.date ? new Date(importMoment.date).getTime() : 0,
      is_sketch: importMoment.isSketch ? 1 : 0,
      height: importMoment.height,
      width: importMoment.width,
      thumbnail_content_type: thumbnail ? thumbnail.contentType : null,
      thumbnail_md5_envelope: thumbnailMd5,
      thumbnail_md5_body: thumbnailMd5,
      thumbnail_height: thumbnailDimensions.newHeight,
      thumbnail_width: thumbnailDimensions.newWidth,
      thumbnail_size_bytes: thumbnail?.data?.byteLength ?? null,
      type: "image",
      metadata: {
        duration: null,
        fileSize: media?.data?.byteLength ?? null,
        location: null,
        audioChannels: null,
        format: null,
        recordingDevice: null,
        sampleRate: null,
        timeZoneName: null,
        pdfName: null,
        title: null,
      },
      pdfName: null,
      // override if we don't have the media file so we don't try to upload it with the entry push
      _local_created_locally: media?.data ? 1 : undefined,
    };
    await this.db.moments.put(moment);
  }

  async createVideoMomentFromImportData(
    importMoment: Videos,
    entryId: string,
    journalId: string,
  ) {
    const media = await this.db.medias.get(importMoment.md5);

    const fileType =
      importMoment.type === "mov"
        ? "video/quicktime"
        : `video/${importMoment.type}`;
    let videoData: {
      height: number;
      width: number;
      thumbnail: {
        data: ArrayBuffer;
        width: number;
        height: number;
        contentType: string;
        md5: string;
      };
      fileType: string;
      duration: number;
    } | null = null;
    if (media && media.data) {
      videoData = await this.handleVideo(fileType, media.data);
    }
    if (videoData) {
      await this.saveMedia(
        videoData.thumbnail.md5,
        new Uint8Array(videoData.thumbnail.data),
      );
    }

    const commonMoment = this.getDefaultMomentFromImportData(
      importMoment,
      entryId,
      journalId,
    );

    const moment: MomentDBRow = {
      ...commonMoment,
      content_type: fileType,
      date: importMoment.date ? new Date(importMoment.date).getTime() : 0,
      is_sketch: 0,
      height: importMoment.height,
      width: importMoment.width,
      thumbnail_content_type: videoData ? "image/jpeg" : null,
      thumbnail_md5_envelope: videoData?.thumbnail.md5 ?? null,
      thumbnail_md5_body: videoData?.thumbnail.md5 ?? null,
      thumbnail_height: videoData?.thumbnail.height ?? null,
      thumbnail_width: videoData?.thumbnail.width ?? null,
      thumbnail_size_bytes: videoData?.thumbnail.data.byteLength ?? null,
      type: "video",
      metadata: {
        duration: videoData?.duration ?? null,
        fileSize: media?.data?.byteLength ?? null,
        location: importMoment.location,
        audioChannels: null,
        format: null,
        recordingDevice: null,
        sampleRate: null,
        timeZoneName: null,
        pdfName: null,
        title: null,
      },
      pdfName: null,
      // override if we don't have the media file so we don't try to upload it with the entry push
      _local_created_locally: videoData ? 1 : undefined,
    };
    await this.db.moments.put(moment);
  }

  async createAudioMomentFromImportData(
    importMoment: Audios,
    entryId: string,
    journalId: string,
  ) {
    const commonMoment = this.getDefaultMomentFromImportData(
      importMoment,
      entryId,
      journalId,
    );
    const moment: MomentDBRow = {
      ...commonMoment,
      content_type: importMoment.type === "m4a" ? "audio/x-m4a" : "audio/mp4",
      date: importMoment.date ? new Date(importMoment.date).getTime() : 0,
      is_sketch: 0,
      height: 0,
      width: 0,
      thumbnail_content_type: "",
      thumbnail_md5_envelope: null,
      thumbnail_md5_body: "",
      thumbnail_height: 0,
      thumbnail_width: 0,
      thumbnail_size_bytes: null,
      type: "audio",
      metadata: {
        duration: importMoment.duration,
        fileSize: importMoment.fileSize,
        location: null,
        audioChannels: importMoment.audioChannels,
        format: importMoment.format,
        recordingDevice: importMoment.creationDevice,
        sampleRate: importMoment.sampleRate,
        timeZoneName: importMoment.timeZoneName,
        pdfName: null,
        title: importMoment.title,
      },
      pdfName: null,
    };
    await this.db.moments.put(moment);
  }

  async createPDFMomentFromImportData(
    importMoment: PDFs,
    entryId: string,
    journalId: string,
    createPDFThumbnail: (
      f: File | Uint8Array,
      moment: MomentDBRow,
    ) => Promise<void>,
  ) {
    const commonMoment = this.getDefaultMomentFromImportData(
      importMoment,
      entryId,
      journalId,
    );
    const moment: MomentDBRow = {
      ...commonMoment,
      content_type: "application/pdf",
      date: 0,
      is_sketch: 0,
      height: importMoment.height,
      width: importMoment.width,
      thumbnail_content_type: "",
      thumbnail_md5_envelope: null,
      thumbnail_md5_body: "",
      thumbnail_height: 0,
      thumbnail_width: 0,
      thumbnail_size_bytes: null,
      type: "pdfAttachment",
      metadata: {
        duration: null,
        fileSize: importMoment.fileSize,
        location: null,
        audioChannels: null,
        format: null,
        recordingDevice: null,
        sampleRate: null,
        timeZoneName: null,
        pdfName: importMoment.pdfName,
        title: null,
      },
      pdfName: importMoment.pdfName,
    };
    await this.db.moments.put(moment);
    const media = await this.db.medias.get(importMoment.md5);
    if (!media || !media.data) {
      return;
    }
    await createPDFThumbnail(media.data, moment);
  }
}

export interface MomentWithThumb extends MomentDBRow {
  thumbnail_data: Uint8Array | null;
}

export function momentsAreEqual(m1: MomentDBRow, m2: MomentDBRow): boolean {
  return (
    m1.id == m2.id &&
    m1.entry_id == m2.entry_id &&
    m1.journal_id == m2.journal_id
  );
}

export type MomentCounts = {
  total: number;
  photo: number;
  video: number;
  audio: number;
  pdf: number;
};
